import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import * as moment from "moment";
import "moment/min/locales";
import { shareReplay, distinctUntilChanged, map, take } from "rxjs/operators";
import {
  CantidadAplicadaCodigos,
  Producto,
} from "src/app/models/producto.model";
import { Compra } from "src/app/models/compra.model";
import { Carrito } from "src/app/models/carrito.model";
import { CompraCarrito } from "src/app/models/compra-carrito.model";
import { HttpClient } from "@angular/common/http";
import { AppConfiguration } from "src/app/app.configuration";
import { Cliente } from "src/app/models/cliente.model";
import { UserService } from "src/app/user/user.service";
import { FormGroup } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class VentaService {
  total = 0;
  diaSeleccionado = false;
  datosForm;
  value = "part";
  horariosList = [];
  recintosList = [];
  sesionesReservadas = [];
  check = false;
  discount = true;

  private modificandoReservaSubject: BehaviorSubject<any>;
  public modificandoReserva$: Observable<any>;

  private cargadoReservaSubject: BehaviorSubject<any>;
  public cargadoReserva$: Observable<any>;

  private userReservaSubject: BehaviorSubject<any>;
  public userReserva$: Observable<any>;

  private enlaceMenuCarritoSubject: BehaviorSubject<any>;
  public enlaceMenuCarrito$: Observable<any>;

  private descuentoPersonalizadoSubject: BehaviorSubject<any>;
  public descuentoPersonalizado$: Observable<any>;

  private sesionesSeleccionadasSubject: BehaviorSubject<any>;
  public sesionesSeleccionadas$: Observable<any>;

  private clienteSubject: BehaviorSubject<any>;
  public cliente$: Observable<any>;

  private descuentoTotalesSubject: BehaviorSubject<any>;
  public descuentoTotales$: Observable<any>;

  private descuentoObjetoSubject: BehaviorSubject<any>;
  public descuentoObjeto$: Observable<any>;

  private descuentoSubject: BehaviorSubject<any>;
  public descuento$: Observable<any>;

  private descuentoCodSubject: BehaviorSubject<any>;
  public descuentoCod$: Observable<any>;

  //<< Yaribel 20210820 descuento cliente asociado mapfre  VSTS 8424
  private descuentoClienteAsociadoSubject: BehaviorSubject<any>;
  public descuentoClienteAsociado$: Observable<any>;

  private descuentoClienteAsociadoObjetoSubject: BehaviorSubject<any>;
  public descuentoClienteAsociadoObjeto$: Observable<any>;

  //>> VSTS 8424

  private descuentoClienteAsociadoCodSubject: BehaviorSubject<any>;
  public descuentoClienteAsociadoCod$: Observable<any>;

  private descuentoPerSubject: BehaviorSubject<any>;
  public descuentoPer$: Observable<any>;

  private cambiosSubject: BehaviorSubject<any>;
  public cambios$: Observable<any>;

  private compraSubject: BehaviorSubject<any>;
  public compra$: Observable<any>;

  private modificarSubject: BehaviorSubject<any>;
  public modificar$: Observable<any>;

  private comprarMasSubject: BehaviorSubject<any>;
  public comprarMas$: Observable<any>;

  private carritoSubject: BehaviorSubject<any>;
  public carrito$: Observable<any>;

  //mapas

  private espectaculoSubject: BehaviorSubject<any>;
  public espectaculo: Observable<any>;

  private fechaSeleccionadaSubject: BehaviorSubject<any>;
  public fechaSeleccionada$: Observable<any>;

  // fin mapas

  private fechaSeleccionadaSinSessionSubject: BehaviorSubject<any>;
  public fechaSeleccionadaSinSession$: Observable<any>;

  private horaSeleccionadaSubject: BehaviorSubject<any>;
  public horaSeleccionada$: Observable<any>;

  private gestionarComisionSubject: BehaviorSubject<any>;
  public gestionarComision$: Observable<any>;

  private incluirComisionSubject: BehaviorSubject<any>;
  public incluirComision$: Observable<any>;

  private numCarritoSubject: BehaviorSubject<number>;
  public numCarrito$: Observable<number>;


  private isReservaPagadaSubject: BehaviorSubject<boolean>;
  public isReservaPagada$: Observable<boolean>;

  private traerReservaLoadedSubject: BehaviorSubject<boolean>;
  public traerReservaLoaded$: Observable<boolean>;

  constructor(
    private http: HttpClient,
    private config: AppConfiguration,
    private userService: UserService
  ) {

    this.traerReservaLoadedSubject = new BehaviorSubject<boolean>(false);
    this.traerReservaLoaded$ = this.traerReservaLoadedSubject.asObservable();

    this.isReservaPagadaSubject = new BehaviorSubject<boolean>(false);
    this.isReservaPagada$ = this.isReservaPagadaSubject.asObservable();

    this.modificandoReservaSubject = new BehaviorSubject<any>(undefined);
    this.modificandoReserva$ = this.modificandoReservaSubject.asObservable();

    this.cargadoReservaSubject = new BehaviorSubject<any>(false);
    this.cargadoReserva$ = this.cargadoReservaSubject.asObservable();

    this.userReservaSubject = new BehaviorSubject<any>(undefined);
    this.userReserva$ = this.userReservaSubject.asObservable();

    this.enlaceMenuCarritoSubject = new BehaviorSubject(undefined);
    this.enlaceMenuCarrito$ = this.enlaceMenuCarritoSubject.asObservable();

    //mapas
    this.espectaculoSubject = new BehaviorSubject(undefined);
    this.espectaculo = this.espectaculoSubject.asObservable();

    this.descuentoPersonalizadoSubject = new BehaviorSubject(undefined);
    this.descuentoPersonalizado$ =
      this.descuentoPersonalizadoSubject.asObservable();

    this.sesionesSeleccionadasSubject = new BehaviorSubject([]);
    this.sesionesSeleccionadas$ =
      this.sesionesSeleccionadasSubject.asObservable();

    this.cambiosSubject = new BehaviorSubject(false);
    this.cambios$ = this.cambiosSubject.asObservable();

    this.clienteSubject = new BehaviorSubject(undefined);
    this.cliente$ = this.clienteSubject.asObservable();

    this.descuentoTotalesSubject = new BehaviorSubject(undefined);
    this.descuentoTotales$ = this.descuentoTotalesSubject.asObservable();

    this.descuentoPerSubject = new BehaviorSubject(undefined);
    this.descuentoPer$ = this.descuentoPerSubject.asObservable();

    this.descuentoSubject = new BehaviorSubject(undefined);
    this.descuento$ = this.descuentoSubject.asObservable();

    this.descuentoObjetoSubject = new BehaviorSubject(undefined);
    this.descuentoObjeto$ = this.descuentoObjetoSubject.asObservable();

    this.descuentoCodSubject = new BehaviorSubject(undefined);
    this.descuentoCod$ = this.descuentoCodSubject.asObservable();

    //<< Yaribel 20210820 descuento cliente asociado mapfre  VSTS 8424
    this.descuentoClienteAsociadoSubject = new BehaviorSubject(undefined);
    this.descuentoClienteAsociado$ =
      this.descuentoClienteAsociadoSubject.asObservable();

    this.descuentoClienteAsociadoObjetoSubject = new BehaviorSubject(undefined);
    this.descuentoClienteAsociadoObjeto$ =
      this.descuentoClienteAsociadoObjetoSubject.asObservable();

    //>> VSTS 8424

    this.descuentoClienteAsociadoCodSubject = new BehaviorSubject(undefined);
    this.descuentoClienteAsociadoCod$ =
      this.descuentoClienteAsociadoCodSubject.asObservable();

    this.fechaSeleccionadaSubject = new BehaviorSubject(moment());
    this.fechaSeleccionada$ = this.fechaSeleccionadaSubject
      .asObservable()
      .pipe(distinctUntilChanged())
      .pipe(shareReplay(1));

    this.fechaSeleccionadaSinSessionSubject = new BehaviorSubject([]);
    this.fechaSeleccionadaSinSession$ = this.fechaSeleccionadaSinSessionSubject
      .asObservable()
      .pipe(distinctUntilChanged())
      .pipe(shareReplay(1));

    this.horaSeleccionadaSubject = new BehaviorSubject(undefined);
    this.horaSeleccionada$ = this.horaSeleccionadaSubject
      .asObservable()
      .pipe(distinctUntilChanged())
      .pipe(shareReplay(1));

    this.compraSubject = new BehaviorSubject(new Compra());
    this.compra$ = this.compraSubject.asObservable();

    this.carritoSubject = new BehaviorSubject(new Carrito());
    this.carrito$ = this.carritoSubject.asObservable();

    this.modificarSubject = new BehaviorSubject(false);
    this.modificar$ = this.modificarSubject.asObservable();

    this.comprarMasSubject = new BehaviorSubject(false);
    this.comprarMas$ = this.comprarMasSubject.asObservable();

    this.gestionarComisionSubject = new BehaviorSubject(false);
    this.gestionarComision$ = this.gestionarComisionSubject.asObservable();

    this.incluirComisionSubject = new BehaviorSubject(true);
    this.incluirComision$ = this.incluirComisionSubject.asObservable();

    this.numCarritoSubject = new BehaviorSubject(0);
    this.numCarrito$ = this.numCarritoSubject.asObservable();
  }

  public get traerReservaLoadedValue() {
    return this.traerReservaLoadedSubject.value;
  }

  public setTraerReservaLoadedValue(traerReservaLoaded: boolean) {
    this.traerReservaLoadedSubject.next(traerReservaLoaded);
  }

  public get isReservaPagadaValue() {
    return this.isReservaPagadaSubject.value;
  }

  public setIsReservaPagadaValue(isReservaPagada: boolean) {
    this.isReservaPagadaSubject.next(isReservaPagada);
  }

  public get modificandoReservaValue() {
    return this.modificandoReservaSubject.value;
  }

  public setNumCarrito = () => {
    if (sessionStorage.getItem("cesta")) {
      let basket = JSON.parse(sessionStorage.getItem("cesta"));
      this.numCarritoSubject.next(basket.compras.length);
    } else {
      this.numCarritoSubject.next(0);
    }
  };

  setModificandoReserva(numPedido) {
    if (!numPedido) {
      sessionStorage.removeItem("numpedmod");
    } else {
      sessionStorage.setItem("numpedmod", numPedido);
    }
    this.modificandoReservaSubject.next(numPedido);
  }

  public get cargadoReservaValue() {
    return this.cargadoReservaSubject.value;
  }

  setCargadoReservaValue(cargado) {
    this.cargadoReservaSubject.next(cargado);
  }

  public get userReservaValue() {
    return this.userReservaSubject.value;
  }

  setUserReservaValue(user) {
    this.userReservaSubject.next(user);
  }

  public get enlaceMenuCarritoValue() {
    return this.enlaceMenuCarritoSubject.value;
  }

  public setEnlaceMenuCarrito(em) {
    this.enlaceMenuCarritoSubject.next(em);
  }

  public get descuentoPersonalizadoValue() {
    return this.descuentoPersonalizadoSubject.value;
  }

  public setDescuentoPersonalizado(descuento) {
    if (descuento) {
      let tiposDescuento;
      if (this.descuentoPerValue.tipo === "Porcentaje") {
        tiposDescuento = "DT";
      } else if (this.descuentoPerValue.tipo === "Importe") {
        tiposDescuento = "PR";
      }
      const descuentoPersonalizado = {
        tipoDescuento: tiposDescuento,
        cantidad: this.descuentoPerValue.cantidad,
        importe: descuento,
      };
      this.descuentoPersonalizadoSubject.next(descuentoPersonalizado);
    } else {
      this.descuentoPersonalizadoSubject.next(undefined);
    }
  }

  public get sesionesSeleccionadasValue() {
    return this.sesionesSeleccionadasSubject.value;
  }

  public setSesionesSeleccionadas(fecha) {
    this.sesionesSeleccionadasSubject.next(fecha);
  }

  public get modificarValue() {
    return this.modificarSubject.value;
  }

  public setModificarValue(modificar) {
    this.modificarSubject.next(modificar);
  }

  public get comprarMasValue() {
    return this.comprarMasSubject.value;
  }

  public setcomprarMasValue(comprarMas) {
    this.comprarMasSubject.next(comprarMas);
  }

  public get cambiosValue() {
    return this.cambiosSubject.value;
  }

  public setCambios(cambio) {
    this.cambiosSubject.next(cambio);
  }

  public get clienteValue() {
    return this.clienteSubject.value;
  }

  public setCliente(cliente: Cliente) {
    if (cliente) {
      sessionStorage.setItem("cliente", JSON.stringify(cliente));
    }
    this.clienteSubject.next(cliente);
  }

  public get descuentoPerValue() {
    return this.descuentoPerSubject.value;
  }

  public setDescuentoPer(descuento) {
    this.descuentoPerSubject.next(descuento);
  }

  public get descuentoCodValue() {
    return this.descuentoCodSubject.value;
  }

  public setDescuentoCod(descuento) {
    this.descuentoCodSubject.next(descuento);
  }

  public get descuentoClienteAsociadoCodValue() {
    return this.descuentoClienteAsociadoCodSubject.value;
  }

  public setDescuentoClienteAsociadoCod(descuento) {
    this.descuentoClienteAsociadoCodSubject.next(descuento);
  }

  //<< Yaribel 20210820 descuento cliente asociado mapfre  VSTS 8424
  public get descuentoClienteAsociadoValue() {
    return this.descuentoClienteAsociadoSubject.value;
  }

  public setDescuentoClienteAsociado(descuento) {
    this.descuentoClienteAsociadoSubject.next(descuento);
  }

  public get descuentoClienteAsociadoObjetoValue() {
    return this.descuentoClienteAsociadoObjetoSubject.value;
  }

  public setDescuentoClienteAsociadoObjeto(descuento) {
    this.descuentoClienteAsociadoObjetoSubject.next(descuento);
  }

  //>> VSTS 8424

  public get descuentoObjetoValue() {
    return this.descuentoObjetoSubject.value;
  }

  public setDescuentoObjeto(descuento) {
    this.descuentoObjetoSubject.next(descuento);
  }

  public get descuentoValue() {
    return this.descuentoSubject.value;
  }

  public setDescuento(descuento) {
    this.descuentoSubject.next(descuento);
  }

  public get descuentoTotalesValue() {
    return this.descuentoTotalesSubject.value;
  }

  public setDescuentoTotales(descuento) {
    this.descuentoTotalesSubject.next(descuento);
  }

  public get compraValue() {
    return this.compraSubject.value;
  }

  public setCompra(compra) {
    this.compraSubject.next(compra);
  }

  public get carritoValue() {
    return this.carritoSubject.value;
  }

  public setCarrito(compra) {
    this.carritoSubject.next(compra);
  }

  public get fechaSeleccionadaValue() {
    return this.fechaSeleccionadaSubject.value;
  }

  setFechaSeleccionadaValue(fecha = moment()) {
    this.fechaSeleccionadaSubject.next(fecha);
  }
  public get fechaSeleccionadaSinSessionValue() {
    return this.fechaSeleccionadaSinSessionSubject.value;
  }

  setFechaSeleccionadaSinSessionValue(array) {
    this.fechaSeleccionadaSinSessionSubject.next(array);
  }

  public get horaSeleccionadaValue() {
    return this.horaSeleccionadaSubject.value;
  }

  public setGestionarComision(value) {
    this.gestionarComisionSubject.next(value);
  }

  public get gestionarComisionValue() {
    return this.gestionarComisionSubject.value;
  }

  public setIncluirComision(value) {
    this.incluirComisionSubject.next(value);
    this.carritoValue.setIncluirComision(value);
  }

  public get incluirComisionValue() {
    return this.carritoValue.incluirComision;
  }


  getClientParsed (clientForm: FormGroup) {
      const clienteGuardado = this.clienteValue;
      const cliente = { ...clientForm.value };
      console.log({
        cliente,
        clienteGuardado,
      });
      let nombre;
      let apellidos;
      let apellido1;
      let apellido2;
      let user;
      let password;
      let afiliado;
      let tipopagonombre;
  
      if (clienteGuardado) {
        nombre = cliente.Nombrecontacto
          ? cliente.Nombrecontacto
          : clienteGuardado.NombreClienteAdicional
          ? clienteGuardado.NombreClienteAdicional
          : clienteGuardado.NombreCliente;
        apellidos = cliente.apellidos
          ? cliente.apellidos
          : clienteGuardado.Apellidos;
        apellido1 = cliente.apellidos
          ? cliente.apellidos
          : clienteGuardado.Apellido1;
        apellido2 = cliente.Apellido2Contacto
          ? cliente.Apellido2Contacto
          : clienteGuardado.Apellido2;
        user = clienteGuardado.User ? clienteGuardado.User : null;
        password = clienteGuardado.Pass ? clienteGuardado.Pass : null;
        afiliado = clienteGuardado.Afiliado ? clienteGuardado.Afiliado : "";
        tipopagonombre = clienteGuardado.TipoPagoNombre
          ? clienteGuardado.TipoPagoNombre
          : "";
      } else {
        nombre = cliente.Nombrecontacto ? cliente.Nombrecontacto : "";
        apellidos = cliente.apellidos ? cliente.apellidos : "";
        apellido1 = cliente.apellidos ? cliente.apellidos : "";
        apellido2 = cliente.Apellido2Contacto ? cliente.Apellido2Contacto : "";
        afiliado = cliente.Afiliado ? cliente.Afiliado : "";
        tipopagonombre = cliente.TipoPagoNombre ? cliente.TipoPagoNombre : "";
      }
  
      const newClient: Cliente = {
        User: user,
        Pass: password,
        Documento: cliente.CIFoDNI ? cliente.CIFoDNI : cliente.documento,
        Ciudad: cliente.ciudad,
        CodigoPostal: cliente.codigoPostal,
        Direccion: cliente.direccion,
        Email: cliente.email,
        EmailConfirmacion: cliente.emailconfirmacion,
        NombreCliente: cliente.nombre ? cliente.nombre : nombre,
        NombreContacto: cliente.nombrecentro,
        NombreResponsable: cliente.nombreresponsable,
        // manieva 11520
        // cuando el atributo de nombre no esta disponible usamos el de user
        // sino este valor se pierde y es necesario para otras flujos
        Nombre: cliente.nombre ? cliente.nombre : user,
        Notas: cliente.notas,
        NotasInternas: cliente.notasinternas ? cliente.notasinternas : "",
        Telefono: cliente.telefono,
        Apellidos: apellidos,
        Apellido1: apellido1,
        Apellido2: apellido2,
        Pais: cliente.pais,
        CodigoPais: cliente.codigoPais,
        TipoDocumento: cliente.tipoDocumento,
        Telefono2: cliente.telefonomovil,
        TipoCentro: cliente.tipocentro,
        Curso: cliente.curso,
        Provincia: cliente.provincia,
        Publicidad: undefined,
        Privacidad: undefined,
        Afiliado: afiliado,
        TipoPagoNombre: tipopagonombre,
      };
    return newClient;
  }

  setHoraSeleccionadaValue(fecha = moment()) {
    this.horaSeleccionadaSubject.next(fecha);
  }

  sendProducto(producto: Producto) {
    this.compraValue.agregarProducto(producto);
    this.setCompra(this.compraValue);
  }

  sendProductoModificar(producto: Producto) {
    this.compraValue.agregarProductoModificar(producto);
    this.setCompra(this.compraValue);
  }

  getCantidadPorProducto(producto: Producto) {
    return this.compraValue.getCantidadPorProducto(producto);
  }

  getPrecioPorProducto(producto: Producto) {
    return this.compraValue.getPrecioPorProducto(producto);
  }

  getCantidadMotivosPorProductoIdMotivo(producto: Producto, motivoId) {
    return this.compraValue.getCantidadMotivosPorProductoYId(
      producto,
      motivoId
    );
  }

  removeProducto(producto: Producto) {
    this.compraValue.eliminarProducto(producto);
    this.setCompra(this.compraValue);
  }

  removeProductoModal(producto: Producto) {
    this.compraValue.eliminarProductoModal(producto);
    this.setCompra(this.compraValue);
  }

  sendCompra(compra: CompraCarrito) {
    this.carritoValue.agregarCompra(compra);
    this.setCarrito(this.carritoValue);
  }

  getClienteReserva() {
    let usuario;
    let password;
    let usuarioAdmin;
    let passwordAdmin;
    let tokenOrigen;
    if (JSON.parse(sessionStorage.getItem("admin"))) {
      if (JSON.parse(sessionStorage.getItem("admin")).u) {
        usuario = JSON.parse(sessionStorage.getItem("admin")).u;
      }
      if (JSON.parse(sessionStorage.getItem("admin")).p) {
        password = JSON.parse(sessionStorage.getItem("admin")).p;
      }
      if (JSON.parse(sessionStorage.getItem("admin")).ua) {
        usuarioAdmin = JSON.parse(sessionStorage.getItem("admin")).ua;
      }
      if (JSON.parse(sessionStorage.getItem("admin")).pa) {
        passwordAdmin = JSON.parse(sessionStorage.getItem("admin")).pa;
      }
      if (JSON.parse(sessionStorage.getItem("admin")).tokenOrigen) {
        tokenOrigen = JSON.parse(sessionStorage.getItem("admin")).tokenOrigen;
      }
    }
    const body = {
      Usuario: usuario,
      Password: password,
    };

    if (usuarioAdmin && passwordAdmin) {
      this.checkUserFromAdmin(
        usuarioAdmin,
        passwordAdmin,
        tokenOrigen
      ).subscribe((res) => {
        if (res.DatosResult) {
          if (usuario && password) {
            let user = { nombre: usuario, contrasena: password };
            this.userService.login(user).subscribe((res) => {
              if (res && res.DatosResult) {
                this.userService.setDatosUserValue(res.DatosResult);
              }
            });
          } else {
            this.userService.setUserAdminValue(true);
          }
        }
      });
    } else {
      if (usuario && password) {
        let user = { nombre: usuario, contrasena: password };
        this.userService.login(user).subscribe((res) => {
          if (res && res.DatosResult) {
            this.userService.setDatosUserValue(res.DatosResult);
          }
        });
      }
    }
    return this.http
      .post(`${this.config.getConfig("API_URL")}/api/TraerCliente`, body)
      .pipe(
        map((res: any) => {
          if (!res.DatosResult) {
            this.setUserReservaValue(undefined);
            if (JSON.parse(sessionStorage.getItem("cliente"))) {
              this.setCliente(JSON.parse(sessionStorage.getItem("cliente")));
            } else {
              this.setCliente(undefined);
            }
            return null;
          }
          if (JSON.parse(sessionStorage.getItem("cliente"))) {
            this.setUserReservaValue(
              JSON.parse(sessionStorage.getItem("cliente"))
            );
            this.setCliente(JSON.parse(sessionStorage.getItem("cliente")));
            // this.mapCliente(JSON.parse(sessionStorage.getItem("cliente")));
          } else {
            this.setUserReservaValue(res.DatosResult);
            this.mapCliente(res.DatosResult);
          }
          return res;
        })
      );
  }

  checkUserFromAdmin(userName, passwordSHA, tokenOrigen) {
    const body = {
      NombreUsuario: userName,
      ClaveUsuario: "",
      ClaveUsuarioSHA: passwordSHA,
      TokenOrigen: tokenOrigen,
      AplicacionCod: "ANGULAR",
    };
    return this.http
      .post(`${this.config.getConfig("API_URL")}api/Usuarios`, body)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  guardarCesta() {
    sessionStorage.setItem("cesta", JSON.stringify(this.carritoValue));
  }

  eliminarCarritoPorPosicionDeVenta(posicionVenta) {
    this.carritoValue.eliminarProductosPorposicionVenta(posicionVenta);
  }

  guardarCestaMap() {
    console.log("llegando al guardar cesta map");
    console.log({
      carritoValue: this.carritoValue
    })
    const f = [];
    const g = [];
    let data;
    const currentBasket = JSON.parse(sessionStorage.getItem("cesta"));
    console.log("currentBasket", currentBasket);
    const newBasket = { ...currentBasket };


    /* this.carritoValue.compras.forEach((producto) => {
      if (
        producto.datos !== undefined &&
        currentBasket.compras.some(
          (p) => Number(p.posicionVenta) === Number(producto.posicionVenta)
        )
      ) {
        //console.log("llegando al if");
        if (
          currentBasket.compras.some(
            (p) =>
              Number(p.producto.ProductoId) ===
              Number(producto.producto.ProductoId)
          )
        ) {
          f.push(producto);
        }
      } else {
        g.push(producto);
      }
    }); */

    console.log({
      currentBasket,
      newBasket,
      carritoValue: this.carritoValue,
      g,
      f,
    })

    //console.log("g", g);
    //console.log("f", f);
    /* if (g.length > 1) {
      data = [
        ...newBasket.compras.reduce((acc, item) => {
          const matchingProds = g.filter(
            (prod) => +prod.posicionVenta === +item.posicionVenta
          );
          const matchingProds2 = g.filter(
            (prod) => +prod.posicionVenta !== +item.posicionVenta
          );
          if (matchingProds2.length > 0) {
            return [...matchingProds.concat(matchingProds2)];
          }
          return [...matchingProds];
        }, []),
        ...f,
      ];
    } */
    //console.log("data", data);
    /* if (g.length === 1) {
      data = [
        ...(newBasket.compras = newBasket.compras.map((item) => {
          return (
            g.find((i) => +i.posicionVenta === +item.posicionVenta) || item
          );
        })),
        ...f,
      ];
    } */

    /* this.carritoValue.compras.forEach((compra) => {
      this.carritoValue.eliminarProductosPorposicionVenta(compra.posicionVenta);
    }); */

    console.log({
      carritoValue: this.carritoValue,
      data,
    })

    /* data.forEach((item) => {
      this.sendCompra({
        cantidad: item.cantidad,
        posicionVenta: item.posicionVenta,
        producto: item.producto,
        datos: item.datos,
        sesionesSeleccionadas: item.sesionesSeleccionadas,
      });
    }); */

    console.log({
      lastCarritoValue: this.carritoValue,
    })

    this.carritoValue.compras.forEach((item) => {
      this.sendCompra({
        cantidad: item.cantidad,
        posicionVenta: item.posicionVenta,
        producto: item.producto,
        datos: item.datos,
        sesionesSeleccionadas: item.sesionesSeleccionadas,
        reservaPagada: item.reservaPagada,
      });
    });

    sessionStorage.setItem("cesta", JSON.stringify(this.carritoValue));
  }

  cargarCesta() {
    if (sessionStorage.getItem("cesta")) {
      const cesta = JSON.parse(sessionStorage.getItem("cesta"));
      cesta.compras.forEach((producto) => {
        this.sendCompra(producto);
      });
    }
  }

  validaCesta() {
    let basket = JSON.parse(sessionStorage.getItem("cesta"));

    console.log('Session Cesta::', basket);

    // condiciones
    if (!sessionStorage.getItem("cesta") || basket.compras.length === 0)
      return [];

    // excluir repetidos
    let mapBasquet = new Map();

    // la compra debe ser un subarray
    basket.compras.forEach((compra) =>
      mapBasquet.set(compra.posicionVenta, [
        ...(mapBasquet.get(compra.posicionVenta) ?? []),
        compra,
      ])
    );

    // mantener la posicion venta ordenada
    mapBasquet = new Map([...mapBasquet].sort());

    // recuperar el idespectaculoAsociado desde butacas
    mapBasquet.forEach((compra) => {
      // si no es visita (no tiene butacas)
      // sino es espectaculo retornar null
      if (compra[0].producto.butacas)
        compra[0].producto.idEspectaculoAsociado =
          compra[0].producto.idEspectaculoAsociado ??
          compra[0].producto.butacas[0]?.EspectaculoId;
    });

    return [...mapBasquet.values()];
  }

  guardarFechaSeleccionadasSinSesion() {
    let fechas = this.fechaSeleccionadaSinSessionValue;
    fechas.map((item) => {
      item.fechaSinFormato = item.fecha.format("DD/MM/YYYY");
    });
    sessionStorage.setItem(
      "fechaSeleccionadaSinSession",
      JSON.stringify(fechas)
    );
  }
  cargarFechaSeleccionadasSinSesion() {
    if (sessionStorage.getItem("fechaSeleccionadaSinSession")) {
      const fechasSinSesion = JSON.parse(
        sessionStorage.getItem("fechaSeleccionadaSinSession")
      );
      fechasSinSesion.map((item) => {
        item.fecha = moment(item.fechaSinFormato, "DD/MM/YYYY");
      });
      this.setFechaSeleccionadaSinSessionValue(fechasSinSesion || []);
    }
  }

  calcularposicionVenta() {
    let idMayor = 1;
    if (!this.carritoValue.compras.length) {
      return idMayor;
    } else {
      this.carritoValue.compras.forEach((compra) => {
        if (compra.posicionVenta >= idMayor) {
          idMayor = compra.posicionVenta;
        }
      });
      return idMayor + 1;
    }
  }

  ordenarCarrito() {
    let listaCarrito;
    this.carrito$.pipe(take(1)).subscribe((res) => {
      listaCarrito = res.compras
        .reduce((r, a) => {
          r[a.posicionVenta] = r[a.posicionVenta] || [];
          r[a.posicionVenta].push(a);
          return r;
        }, [])
        .filter((item) => item);
    });
    return listaCarrito;
  }

  getTwoDecimal(num) {
    /*let string = num.toString();
    return Number(string.substring(0, string.indexOf('.') + 3));*/
    if (!num) {
      return 0;
    }
    if (typeof num !== "number") {
      num = Number.parseFloat(num);
    }
    return Number.parseFloat(num.toFixed(2));
  }

  ordenacionTraerReserva(productos) {
    const prod = [];
    Object.keys(productos.ProductosCompraPorGrupo).map((p) => {
      let productoTemp;
      let datos;
      const posicionVenta = parseInt(p);
      productos.ProductosCompraPorGrupo[p].forEach((element) => {
        const datosArray = [];
        const cantidad = parseInt(element.Cantidad);
        let sesionesSeleccionadas;
        let butacas;
        const productosBonoSinDuplicados = [];
        let idEspectaculoAsociado = "";
        element.ProductosBonos.forEach((pb) => {
          if (
            !productosBonoSinDuplicados.find(
              (pdsd) => pb.ProductoId === pdsd.ProductoId
            )
          ) {
            productosBonoSinDuplicados.push(pb);
          }
        });
        element.ProductosBono = productosBonoSinDuplicados;
        element.tipoReseva = productos.TipoReserva;
        element.enlaceMenu = element.EnlaceMenu;

        element.PVPSinDescuento = (
          Number.parseFloat(element.PvpProductoUd.replace(/,/, ".")) +
          this.getTwoDecimal(
            Number.parseFloat(element.DescuentoUnidad.replace(/,/, ".")) || 0
          ) +
          (this.incluirComisionValue && element.DesglosarComision == "0"
            ? parseFloat(element.Comision.replace(/,/, "."))
            : 0)
        ).toFixed(4);
        //
        //        element.PVPSinDescuento =   (Number.parseFloat(element.PvpProductoUd.replace(/,/, '.')
        //                                    ) +
        //                                    Number.parseFloat(element.DescuentoUnidad.replace(/,/, '.')||0
        //                                    )
        //                                    + (this.incluirComisionValue && element.DesglosarComision == '0'?
        //                                    parseFloat(element.Comision.replace(/,/, '.')): 0 )).toFixed(4) ;
        //
        //
        element.PVPInternet = element.PvpProductoUd;
        element.PrecioEnDivisaReferencia = element.PvpProductoUd;
        element.Descuento = element.DescuentoUnidad;
        element.ComisionInternet = element.Comision;
        element.ComisionBase = (
          element.Comision /
          (1 + element.IvaComision / 100)
        ).toFixed(4); //element.ComisionBase;
        element.IvaComision = element.IvaComision;
        element.DesglosarComision = element.DesglosarComision;
        element.PrecioBase = element.PrecioBrutoUd; //Yaribel 20210601  Modificamos PrecioBruto por PrecioBrutoUd para obtener el valor por entrada
        element.ClaveReserva = productos.ClaveReserva;
        element.TipoProducto = element.ProductosBonos.length ? "2" : "1";
        element.NombreGrupo = element.NombreGrupoInternet;
        element.NumeroTotalEntradasBono = element.TotalEntradas;
        element.Agrupar = element.bNoAgrupar;

        //Guardar si es reserva pagada
        element.ReservaPagada = productos.Pagada
          ? productos.Pagada === "1"
          : false;

        element.isModificarReserva = this.modificandoReservaValue ? this.modificandoReservaValue : "";

        if (element.MotivosGruposItems) {
          if (element.MotivosGruposItems) {
            const motivos = [];
            element.MotivosGruposItems.forEach((m) => {
              if (!motivos.find((mot) => mot.PkId === m.MotivoId)) {
                motivos.push({
                  PkId: m.MotivoId,
                  cantidad: m.Cantidad,
                  nombre: m.Nombre,
                });
              }
            });
            element.motivos = motivos;
          }
        }
        element.motivos = productoTemp = Object.assign({}, element);
        const {
          FechaVisita,
          PrecioBruto,
          Abonados,
          Anulada,
          //Comision,
          CondicionesGrupoInternet,
          Conjunta,
          ImprimirQRPromo,
          ImprimirTicket,
          Descuentofila,
          //DesglosarComision,
          LocalizadorNegativo,
          LocalizadorNegativoFecha,
          //IvaComision,
          NivelEntrada,
          OrdenPromo,
          DescripcionGrupoInternet,
          TarjetaMonederoCodigo,
          TicketBonos,
          TipoAbonadoId,
          bNoAgrupar,
          TipoControl,
          NombrePromo,
          CodigoPromocional,
          DescuentoUnidad,
          NombreCentro,
          PrecioBrutoUd,
          PromocionId,
          TipoListado,
          InformacionListasBlancas,
          ProductosBonos,
          PvpProductoUd,
          MotivosGruposItems,
          ...producto
        } = productoTemp;

        if (element.AforosConsumidos.length) {
          sesionesSeleccionadas = element.AforosConsumidos.map((aforo) => {
            aforo.HoraInicio = moment(
              aforo.HoraVisita,
              "DD/MM/YYYY HH:mm:ss"
            ).format("HH:mm");
            aforo.Fecha = moment(
              aforo.FechaVisita,
              "DD/MM/YYYY HH:mm:ss"
            ).format("DD/MM/YYYY");
            aforo.HoraFin = moment(
              aforo.HoraFinVisita,
              "DD/MM/YYYY HH:mm:ss"
            ).format("HH:mm");
            return aforo;
          });
        }
        if (element.butacas.length > 0) {
          butacas = element.butacas;

          //Set productoId a las butacas
          butacas.forEach((b) => {
            b.ProductoId = element.ProductoId || ''
          });

          idEspectaculoAsociado = element.butacas[0].EspectaculoId;
        }

        producto.idEspectaculoAsociado = idEspectaculoAsociado;

        //Establecer fecha pre seleccionada para productos sin selección de horario
        let ListTarifasfechasVigencia = [];
        if (!sesionesSeleccionadas && element.butacas.length === 0 && FechaVisita) {
          const tarifaFechaVigencia = {
            FechaInicioTarifa: moment(FechaVisita, "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY"),
            FechaFinTarifa: moment(FechaVisita, "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY"),
          } 
          ListTarifasfechasVigencia.push(tarifaFechaVigencia);

          producto.ListTarifasfechasVigencia = ListTarifasfechasVigencia;

          //Guardar fechas sin sesion
          let fechasSinSesion = this.fechaSeleccionadaSinSessionValue;
          let fechaSinSesionExist = null;
          if (fechasSinSesion) {
            fechaSinSesionExist = fechasSinSesion.find(
              (f) =>
                f.posicionVenta == posicionVenta &&
                f.productoId == element.ProductoId
            );
          }

          if (fechaSinSesionExist) {
            fechasSinSesion = fechasSinSesion.filter(
              (f) =>
                f.posicionVenta !== posicionVenta &&
                f.productoId !== element.ProductoId
            );
          }

          let fechaSinSesion = {
            fecha: moment(FechaVisita, "DD/MM/YYYY HH:mm:ss"),
            fechaSinFormato: FechaVisita,
            productoId: element.ProductoId,
            posicionVenta,
          };

          if (!fechasSinSesion) {
            fechasSinSesion = [];
          }

          fechasSinSesion.push(fechaSinSesion);

          this.setFechaSeleccionadaSinSessionValue(fechasSinSesion);
        }

        if (element.PersonasReserva.length) {
          element.PersonasReserva.map((p) => {
            const datosMap = {
              txtNombre: p.Nombre,
              txtApellidos: p.Apellido1,
              cboTipoDNI: p.Respuesta3,
              txtDNI: p.CifoDni,
              txtEdad: p.Edad,
              cboSexo: p.Sexo,
              txtEmail1: p.Email,
              txtCodigoPostal: p.CP,
              txtTelefono: p.Telefono,
              cboDia: p.Respuesta1,
              cboTrimestre: p.Respuesta2,
              cboNivel: p.Curso,
              cboTipoTabla: p.Respuesta4,
              cboRangoEdad: p.RangoEdad,
              cboIdiomaVisita: p.IdiomaGuia,
            };
            if (datosMap.txtNombre === "" && datosMap.txtDNI === "") {
              datos = datosMap;
            } else {
              datosArray.push(datosMap);
              datos = datosArray;
            }
          });
        }
        if (producto.ProductosBono.length) {
          producto.ProductosBono.forEach((prod) => {
            prod.NumeroEntradasBono = (
              element.ProductosBonos.filter(
                (pBono) => pBono.ProductoId === prod.ProductoId
              ).length / element.Cantidad
            ).toString();
            prod.CuentaNominada = prod.Nominada;
          });
        }
        producto.FechaVisita = moment(FechaVisita, "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY");
        producto.ProductosBono.map((element) => {
          delete element.Nominada;
          delete element.PersonasReserva;
          delete element.InformacionListasBlancas;
          delete element.CodigoPromocional;
          delete element.Comision;
          delete element.Conjunta;
          delete element.Descuentofila;
          delete element.DesglosarComision;
          delete element.IvaComision;
          delete element.NombreCentro;
          delete element.NombrePromo;
          delete element.OrdenFila;
          delete element.OrdenPromo;
          delete element.ProductosBonos;
          delete element.PromocionId;
          delete element.TicketBonos;
          delete element.TipoControl;
          delete element.TipoListado;
          delete element.bNoAgrupar;
          delete element.butacas;
          delete element.IVA;
          delete element.DescripcionProducto;
          delete element.DescuentoUnidad;
          delete element.EsBono;
          delete element.FechaVisita;
          delete element.GrupoInternet;
          delete element.NombreGrupoInternet;
          delete element.NombreProducto;
          delete element.PrecioBrutoUd;
          delete element.PvpProducto;
          delete element.TipoProducto;
          delete element.TotalEntradas;
        });
        for (const property in datos) {
          if (datos[property] === "" || datos[property] === 0) {
            delete datos[property];
          }
        }
        const productoFinal = {
          reservaPagada: productos.Pagada
            ? productos.Pagada === "1"
            : false,
          cantidad,
          posicionVenta,
          producto,
          datos,
          sesionesSeleccionadas,
          butacas,
          
        };
        prod.push(productoFinal);
      });
    });

    console.log({
      action: "ordenacionTraerReserva",
      productossinSesion: this.fechaSeleccionadaSinSessionValue,
    })

    let d = false;
    document.addEventListener("keydown", function (event) {
      if (event.key === "F5") {
        d = true;
      }
    });

    prod.forEach((compra) => {
      this.sendCompra(compra);
    });

    this.carritoValue.compras.forEach((producto) => {
      Object.keys(producto.producto).map(() => {
        producto.producto.datos = producto.datos;
      });
    });
  }

  mapCliente(cliente, productos?) {
    let apellido2;
    if (cliente.Apellido2) {
      apellido2 = cliente.Apellido2;
    } else if (cliente.SegundoApellidoCliente) {
      apellido2 = cliente.SegundoApellidoCliente;
    } else if (cliente.Apellido2Contacto) {
      apellido2 = cliente.Apellido2Contacto;
    }
    let apellido;
    if (cliente.Apellido1) {
      apellido = cliente.Apellido1;
    } else if (cliente.PrimerApellidoCliente) {
      apellido = cliente.PrimerApellidoCliente;
    } else if (cliente.Apellidos) {
      apellido = cliente.Apellidos;
    }
    let nombre;
    if (cliente.NombreClienteAdicional) {
      nombre = cliente.NombreClienteAdicional;
    } else if (cliente.PersonaPago) {
      nombre = cliente.PersonaPago;
    } else if (cliente.NombreCliente) {
      nombre = cliente.NombreCliente;
    }
    const newClient: Cliente = {
      User: cliente.NombreCliente ? cliente.NombreCliente : "",
      Pass: cliente.Contrasena ? cliente.Contrasena : "",
      Documento: cliente.CIFoDNI ? cliente.CIFoDNI : cliente.Documento,
      Ciudad: cliente.Ciudad ? cliente.Ciudad : cliente.Localidad,
      CodigoPostal: cliente.CodigoPostal ? cliente.CodigoPostal : cliente.CP,
      Direccion: cliente.Direccion ? cliente.Direccion : cliente.Domicilio,
      Email: cliente.Email ? cliente.Email : cliente.EMail,
      EmailConfirmacion: cliente.EmailConfirmacion
        ? cliente.EmailConfirmacion
        : cliente.emailconfirmacion,
      NombreCliente: cliente.NombreClienteAdicional
        ? cliente.NombreClienteAdicional
        : cliente.NombreCliente,
      NombreContacto: cliente.NombreContacto
        ? cliente.NombreContacto
        : cliente.NombreClienteOriginal,
      NombreResponsable: cliente.NombreResponsable
        ? cliente.NombreResponsable
        : nombre,
      Nombre: cliente.Nombre ? cliente.Nombre : cliente.NombreCliente,
      Notas: cliente.Notas
        ? cliente.Notas
        : cliente.Nota
        ? cliente.Nota.split("<br>").join("\n")
        : "",
      NotasInternas: cliente.NotasInternas
        ? cliente.NotasInternas
        : cliente.ObsAdvertencia
        ? cliente.ObsAdvertencia
        : productos
        ? productos.ReservasAdicionales.NotasInternas
        : "",
      Telefono: cliente.Telefono,
      Apellidos: cliente.Apellidos,
      Apellido1: apellido ? apellido : "",
      Apellido2: apellido2 ? apellido2 : "",
      Pais: cliente.Pais,
      CodigoPais: cliente.CodigoPais, //Yaribel 20210531 Añadimos codigo pais al objeto clente
      TipoDocumento: cliente.TipoDocumento,
      Telefono2: cliente.Telefono2
        ? cliente.Telefono2
        : cliente.TelefonoMovilContacto,
      TipoCentro: cliente.TipoCentro,
      Curso: cliente.Curso ? cliente.Curso : cliente.curso,
      Provincia: cliente.Provincia
        ? cliente.Provincia
        : cliente.ProvinciaContacto
          ? cliente.ProvinciaContacto
          : "",
      Publicidad: cliente.Publicidad,
      Privacidad: cliente.Privacidad,
      Afiliado: cliente.Afiliado,
      TipoPagoNombre: cliente.TipoPagoNombre,
    };
    this.setCliente(newClient);
  }

  // calculoDescuento(productosDescuento) {
  //   const arrayProdDescuento = Object.values(productosDescuento);
  //   const prodCarrito = this.carritoValue.compras;
  //   const idsDescuento = arrayProdDescuento.map((p: any) => p.ProductoId);
  //   const productos = [];
  //   const CantidadAplicadaXproducto = [];
  //   idsDescuento.forEach(id => {
  //     productos.push(prodCarrito.filter(p => p.producto.ProductoId === id));
  //     CantidadAplicadaXproducto.push({idTipoProducto:id, Cantidad:0});
  //   });
  //   const productosFlat = productos.reduce((acc, val) => acc.concat(val), []);
  //   let descuentoTotal = 0;
  //   let CantidadAplicada = 0;
  //   let CantidadAplicadaXproducto
  //   productosFlat.forEach(producto => {
  //     let descuentoGrupo = 0;
  //     const prodDescuento: any = arrayProdDescuento.find((p: any) => p.ProductoId === producto.producto.ProductoId);
  //     const descuentoPorEntrada = Number.parseFloat(producto.producto.PrecioEnDivisaReferencia.replace(/,/, '.')) -
  //       Number.parseFloat(prodDescuento.PrecioEnDivisaReferencia.replace(/,/, '.'));
  //     //const CantidadAplicada = this.descuentoObjetoValue && this.descuentoObjetoValue.find(x=> x.TipoProducto === (prodDescuento.ProductosBono ? '2' : '1') && x.ProductoId == prodDescuento.ProductoId)? this.descuentoObjetoValue.find(x=> x.TipoProducto === (prodDescuento.ProductosBono ? '2' : '1') && x.ProductoId == prodDescuento.ProductoId).Cantidad : 0;
  //     if ((parseInt(prodDescuento.MaximoTarifa, 10) - CantidadAplicada.find(x=> x.id = producto.producto.ProductoId )) >= producto.cantidad) {
  //       descuentoGrupo = descuentoPorEntrada * producto.cantidad;
  //       descuentoTotal = descuentoTotal + descuentoGrupo;
  //       CantidadAplicada = producto.cantidad;
  //     } else if((parseInt(prodDescuento.MaximoTarifa, 10) - CantidadAplicada) > 0){
  //       descuentoGrupo = descuentoPorEntrada * (parseInt(prodDescuento.MaximoTarifa, 10)- CantidadAplicada);
  //       descuentoTotal = descuentoTotal + descuentoGrupo;
  //       CantidadAplicada = (parseInt(prodDescuento.MaximoTarifa, 10)- CantidadAplicada);
  //     }
  //   });
  //   this.calcObjetoInsercion(arrayProdDescuento, productosFlat);
  //   return descuentoTotal;
  // }

  calculoDescuento(productosDescuento, tipoDescuento = "") {
    const arrayProdDescuento = Object.values(productosDescuento);
    const prodCarrito = this.carritoValue.compras;
    const idsDescuento = arrayProdDescuento.map((p: any) => p.ProductoId);
    const productos = [];
    idsDescuento.forEach((id) => {
      productos.push(prodCarrito.filter((p) => p.producto.ProductoId === id));
    });
    const productosFlat = productos.reduce((acc, val) => acc.concat(val), []);
    this.calcObjetoInsercion(arrayProdDescuento, productosFlat, tipoDescuento);
    let descuentoTotal = 0;
    let descuentoGrupo = 0;
    let ProductosAplicados;
    ProductosAplicados =
      tipoDescuento == "ClienteAsociado"
        ? this.descuentoClienteAsociadoObjetoValue
        : this.descuentoObjetoValue;
    ProductosAplicados.forEach((item) => {
      const prodDescuento: any = arrayProdDescuento.find(
        (p: any) => p.ProductoId === item.ProductoId
      );
      const precioInicial = Number.parseFloat(
        productosFlat
          .find(
            (x) =>
              x.producto.TipoProducto === item.TipoProducto &&
              x.producto.ProductoId === item.ProductoId
          )
          .producto.PVPSinDescuento.replace(/,/, ".")
      );
      const precioConDescuento = Number.parseFloat(
        prodDescuento.PVPInternet.replace(/,/, ".")
      );
      const descuentoPorEntrada = +(precioInicial - precioConDescuento).toFixed(
        2
      );
      descuentoGrupo = +(descuentoPorEntrada * item.Cantidad).toFixed(2);
      descuentoTotal = descuentoTotal + descuentoGrupo;
    });
    return descuentoTotal;
  }

  calcObjetoInsercion(arrayProdDescuento, productos, tipoDescuento) {
    const ProductosSeleccionados = [];
    productos.forEach((producto) => {
      const prodDescuento: any = arrayProdDescuento.find(
        (p: any) =>
          p.ProductoId === producto.producto.ProductoId &&
          p.TipoProducto === producto.producto.TipoProducto
      );
      const TipoProducto =
        prodDescuento.ProductosBono && prodDescuento.ProductosBono.length > 0
          ? "2"
          : "1";
      const ProductoId = prodDescuento.ProductoId;
      const IdCategoria_GI = producto.producto.GrupoInternet;
      let Cantidad;
      const CantidadAplicada = ProductosSeleccionados.find(
        (x) => x.TipoProducto === TipoProducto && x.ProductoId == ProductoId
      )
        ? ProductosSeleccionados.find(
            (x) => x.TipoProducto === TipoProducto && x.ProductoId == ProductoId
          ).Cantidad
        : 0;

      if (CantidadAplicada > 0) {
        const indice = ProductosSeleccionados.findIndex(
          (x) => x.TipoProducto === TipoProducto && x.ProductoId == ProductoId
        );

        if (
          parseInt(prodDescuento.MaximoTarifa, 10) - CantidadAplicada >=
          producto.cantidad
        ) {
          ProductosSeleccionados[indice].Cantidad += producto.cantidad;
        } else if (
          parseInt(prodDescuento.MaximoTarifa, 10) - CantidadAplicada >
          0
        ) {
          ProductosSeleccionados[indice].Cantidad +=
            parseInt(prodDescuento.MaximoTarifa, 10) - CantidadAplicada;
        }
      } else {
        if (parseInt(prodDescuento.MaximoTarifa, 10) >= producto.cantidad) {
          Cantidad = producto.cantidad;
        } else {
          Cantidad = parseInt(prodDescuento.MaximoTarifa, 10);
        }
        const prodFinal =
          tipoDescuento == "ClienteAsociado"
            ? { TipoProducto, ProductoId, Cantidad, IdCategoria_GI }
            : { TipoProducto, ProductoId, Cantidad };
        ProductosSeleccionados.push(prodFinal);
      }
    });
    if (tipoDescuento == "ClienteAsociado") {
      this.setDescuentoClienteAsociadoObjeto(ProductosSeleccionados);
    } else {
      this.setDescuentoObjeto(ProductosSeleccionados);
    }
  }

  fechaPorRecinto(recintoId) {
    if (this.compraValue.productos[0].sesionesSeleccionadas) {
      if (
        this.compraValue.productos[0].sesionesSeleccionadas.every(
          (sesion, i, sesiones) => sesion.Fecha === sesiones[0].Fecha
        )
      ) {
        return "";
      } else {
        const recintoSeleccionado =
          this.compraValue.productos[0].sesionesSeleccionadas.find(
            (sesion) => sesion.IdRecinto === recintoId
          );
        return recintoSeleccionado.Fecha;
      }
    }
  }

  actualizarProductosEnLaCesta(productList) {
    let compra = this.compraValue;
    compra.productos.forEach((prod) => {
      const product = productList.find(
        (p) =>
          p.ProductoId === prod.producto.ProductoId &&
          p.TipoProducto === prod.producto.TipoProducto
      );
      if (product) {
        this.compraValue.actualizarProducto(prod, product);
      }
    });
  }

  getIVAComision() {
    return this.carritoValue.calcularIVAComision();
  }
  getComision() {
    return this.carritoValue.calcularComision();
  }

  getDescuentoCliente() {
    return this.carritoValue.calcularDescuento();
  }

  //  getPreciofinal(){
  //   return this.carritoValue.calcularPrecioTotal() + this.carritoValue.calcularComision() + this.carritoValue.calcularIVAComision() - (this.descuentoValue?this.descuentoValue.Descuento || 0 : 0) - this.carritoValue.calcularDescuento();
  //  }

  /**
   * @description Get the final price of the cart.
   *
   * @return {number} Price final
   */
  getPreciofinal(): number {
    // Convert values
    const totalPrice = this.carritoValue.calcularPrecioTotal();
    const comission = this.carritoValue.calcularComision();
    const ivaComission = this.carritoValue.calcularIVAComision();
    const discountAppliedCart = this.carritoValue.calcularDescuento();
    const discountApplied = this.descuentoValue
      ? this.descuentoValue.Descuento || 0
      : 0;

    // Calculate total using numeric values:
    const commissions = parseFloat(comission) + parseFloat(ivaComission);
    const totalPricePlusCommisions = parseFloat(totalPrice) + commissions;
    const totalFinal =
      totalPricePlusCommisions -
      parseFloat(discountAppliedCart) -
      parseFloat(discountApplied);
    return totalFinal;
  }

  //mapas
  public get espectaculoValue() {
    return this.espectaculoSubject.value;
  }

  public setEspectaculoValue(espectaculo) {
    this.espectaculoSubject.next(espectaculo);
  }

  filtrarFechaSeleccionadaSinSesion(posicionVenta) {
    if (
      this.fechaSeleccionadaSinSessionValue &&
      this.fechaSeleccionadaSinSessionValue.length > 0
    ) {
      let nuevo = this.fechaSeleccionadaSinSessionValue;
      nuevo = nuevo.filter((f) => f.posicionVenta != posicionVenta);
      this.setFechaSeleccionadaSinSessionValue(nuevo);
      this.guardarFechaSeleccionadasSinSesion();
    }
  }
}

import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	ViewChild,
	ViewChildren,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { forkJoin } from "rxjs";
import { takeUntil } from "rxjs/internal/operators/takeUntil";
import { Subject } from "rxjs/internal/Subject";
import { debounceTime, exhaustMap, takeWhile } from "rxjs/operators";
import { documentoValidar } from "src/app/business/documento-validar.validator";
import { ProductosService } from "src/app/business/productos.service";
import { HttpBodyGetProvincias } from "src/app/business/productos.service.interface";
import { InitService } from "src/app/core/init/init.service";
import { VentaService } from "src/app/core/services/venta.service";
import { PagerService } from "src/app/shared/pager.service";
import { TranslateService } from "src/app/shared/translate.service";
import { UserService } from "src/app/user/user.service";
import { FormularioClienteComponent } from "../formulario-cliente/formulario-cliente.component";

@Component({
  selector: "app-factura",
  templateUrl: "./factura.component.html",
  styleUrls: ["./factura.component.scss"],
})
export class FacturaComponent implements OnInit, OnDestroy {
  @ViewChild(FormularioClienteComponent)
  formularioClienteComponent!: FormularioClienteComponent;
  modelChanged: Subject<string> = new Subject<string>();
  private ngUnsubscribe = new Subject();
  abrir = [];
  clientes = [];
  optionsDrop = ["DNI", "NIE", "CIF", "OTRO"];
  check = false;
  cargarDatos = false;
  campo;
  pager: any = {};
  pagedItems: any[];
  totalPages: number;
  datosFacturaForm: FormGroup;
  noEsAdmin;
  @Input() paises: any;
  @Output() facturaForm = new EventEmitter<FormGroup>();
  @Output() isCheck = new EventEmitter<boolean>();
  @Input() checkBlocked: boolean;
  @ViewChildren("inputForm") inputs!: QueryList<ElementRef>;
  isBlockedCargarDatosCliente: boolean = false;

  cargado: Boolean = false;

  user: any = null;
  paisesV2 = [];
  lastSelectedCodigoPais = "";
  provinciasV2 = [];
  ciudadesV2 = [];

  lastSelectedProvincia = "";

  clientByAdmin: any = null;

  formObjectConfig = {
    nombre: {
      disabled: false,
    },
    codigo: {
      disabled: false,
    },
    tipo: {
      disabled: false,
    },
    documento: {
      disabled: false,
    },
    direccion: {
      disabled: false,
    },
    ciudad: {
      disabled: false,
    },
    pais: {
      disabled: false,
    },
    codigoPais: {
      disabled: false,
    },
    provincia: {
      disabled: false,
    },
  };

  constructor(
    private formBuilder: FormBuilder,
    private ventaService: VentaService,
    private productosService: ProductosService,
    private pagerService: PagerService,
    private userService: UserService,
    protected translateService: TranslateService,
    private initService: InitService
  ) {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.initService.cargado$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        takeWhile(() => !this.cargado)
      )
      .subscribe((cargado) => {
        this.cargado = cargado;

        if (this.cargado) {
          this.clientByAdmin = null;
          this.initData();
        }
      });
  }

  initData() {
    forkJoin([this.productosService.GetPaisesV2()]).subscribe(([paises]) => {
      if (paises?.DatosPaises && paises?.DatosPaises.length > 0) {
        const paisesResponse = paises.DatosPaises.filter(
          (p) => parseInt(p.Seleccionable) === 1
        );
        this.paisesV2 = paisesResponse.sort(
          (a, b) =>
            parseInt(a.OrdenVisualizacion) - parseInt(b.OrdenVisualizacion)
        );
      }

      this.isBlockedCargarDatosCliente = false;
      this.datosFacturaForm = this.formBuilder.group(
        {
          nombre: [
            "",
            [Validators.required, Validators.pattern("^[A-zÀ-ÿ\\s,\\/]*$")],
          ],
          codigo: ["", [Validators.required]],
          tipo: ["", [Validators.required]],
          documento: ["", [Validators.required]],
          direccion: ["", [Validators.required]],
          ciudad: ["", [Validators.required]],
          pais: ["", [Validators.required]],
          codigoPais: ["", [Validators.required]],
          provincia: ["", [Validators.required]],
        },
        {
          validator: [documentoValidar("tipo", "documento")],
        }
      );

      // Campo codigo postal se muestra dependiendo de la configuración de recursos
      if (
        this.translateService.data["Código Postal"] &&
        this.translateService.data["Código Postal"] !== ""
      ) {
        this.datosFacturaForm
          .get("codigo")
          .setValidators([Validators.required]);
      } else {
        this.datosFacturaForm.get("codigo").clearValidators();
        this.datosFacturaForm.get("codigo").setValue("");
        this.datosFacturaForm.get("codigo").updateValueAndValidity();
      }

      this.listenerPais();

      this.listenerProvincia();

      this.paisProvinciaDefault();

      this.validateFormByEnlaceMenu();

      this.facturaForm.emit(this.datosFacturaForm);
      this.datosFacturaForm.valueChanges
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => this.facturaForm.emit(this.datosFacturaForm));
      this.noEsAdmin = this.productosService.noEsAdministracion();
      if (!this.noEsAdmin) {
        this.buscador();
      }
      if(this.check) {
        this.loadClienteValues();
      }
    });
  }

  get g() {
    return this.datosFacturaForm.controls;
  }

  paisProvinciaDefault() {
    const paisValue = this.paisesV2.length > 0 ? this.paisesV2[0] : "";
    this.datosFacturaForm
      .get("pais")
      .setValue(paisValue ? paisValue.NombrePais : "");
    this.datosFacturaForm
      .get("codigoPais")
      .setValue(paisValue ? paisValue.idPais : "");
  }

  listenerPais() {
    this.datosFacturaForm
      .get("codigoPais")
      .valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((pais) => {
        const paisFind = this.paisesV2.find((p) => p.idPais == pais);

        this.provinciasV2 = [];
        this.ciudadesV2 = [];

        //this.datosFacturaForm.get("provincia").setValue("");

        this.datosFacturaForm.get("ciudad").setValue("");

        if (paisFind) {
          this.datosFacturaForm.get("pais").setValue(paisFind.NombrePais);
          this.loadProvinciasV2(paisFind.idPais);
        }
      });
  }

  listenerProvincia() {
    const cliente = this.userService.currentUserValue;

    this.datosFacturaForm
      .get("provincia")
      .valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((provincia) => {
        if (this.provinciasV2.length > 0) {
          const pais = this.datosFacturaForm.get("codigoPais").value;

          if (provincia && pais) {
            this.loadCiudadesV2(pais, provincia);
          }
        } else {
          let ciudadValue = "";

          if (!this.noEsAdmin && this.clientByAdmin) {
            const ciudadNombre = this.clientByAdmin.LocalidadFiscal
              ? this.clientByAdmin.LocalidadFiscal
              : "";

            ciudadValue = ciudadNombre;
          }

          if (
            this.noEsAdmin &&
            (this.needLoadAutomaticData() || this.cargarDatos === true)
          ) {
            if (cliente) {
              const ciudadNombre = cliente.LocalidadFiscal
                ? cliente.LocalidadFiscal
                : "";

              ciudadValue = ciudadNombre;
            }
          }
          this.datosFacturaForm.get("ciudad").setValue(ciudadValue);
        }
      });
  }

  loadProvinciasV2(paisId: string) {
    const idioma = this.translateService.getLocale();
    const body: HttpBodyGetProvincias = {
      Idioma: idioma,
      idPais: paisId,
      Filtro: "provincia",
    };
    const clienteModel = this.ventaService.clienteValue;

    const samePais =
      this.provinciasV2.length > 0 && this.provinciasV2[0].idPais === paisId;
    const cliente = this.userService.currentUserValue;
    if (!samePais) {
      this.productosService.GetProvinciasV2(body).subscribe(
        (res) => {
          if (
            res?.DatosResult?.DatosCiudades &&
            res?.DatosResult?.DatosCiudades.length > 0
          ) {
            const provinciasResponse = res.DatosResult.DatosCiudades.filter(
              (p) => parseInt(p.Seleccionable) === 1
            );
            this.provinciasV2 = provinciasResponse.sort(
              (a, b) =>
                parseInt(a.OrdenVisualizacion) - parseInt(b.OrdenVisualizacion)
            );

            let provinciaValue =
              this.provinciasV2.length > 0 ? this.provinciasV2[0] : "";

            if (!this.noEsAdmin && this.clientByAdmin) {
              const provinciaNombre = this.clientByAdmin.ProvinciaFiscal
                ? this.clientByAdmin.ProvinciaFiscal
                : "";

              const provinciaFind = this.provinciasV2.find(
                (p) =>
                  String(p.NombreProvincia).toLowerCase() ==
                  String(provinciaNombre).toLowerCase()
              );

              provinciaValue = provinciaFind ? provinciaFind : provinciaValue;
            }

            if (
              this.noEsAdmin &&
              (this.needLoadAutomaticData() || this.cargarDatos === true)
            ) {
              this.clientByAdmin = null;

              if (cliente) {
                const provinciaNombre = cliente.ProvinciaFiscal
                  ? cliente.ProvinciaFiscal
                  : "";
                const provinciaFind = this.provinciasV2.find(
                  (p) =>
                    String(p.NombreProvincia).toLowerCase() ==
                    String(provinciaNombre).toLowerCase()
                );

                provinciaValue = provinciaFind ? provinciaFind : provinciaValue;
              }
            }
            this.lastSelectedProvincia = provinciaValue.CodProvincia;

            const clienteModel = this.ventaService.clienteValue;
            if (clienteModel.Provincia !== "") {
              const provinciaFind = this.provinciasV2.find(
                (p) =>
                  String(p.NombreProvincia).toLowerCase() ==
                  String(clienteModel.Provincia).toLowerCase()
              );
              this.datosFacturaForm
                .get("provincia")
                .setValue(provinciaFind.CodProvincia || clienteModel.Provincia);
            } else {
              this.datosFacturaForm
                .get("provincia")
                .setValue(provinciaValue.CodProvincia);
            }
            this.ciudadesV2 = [];
          } else {
            this.lastSelectedProvincia = "";
            this.provinciasV2 = [];
            this.ciudadesV2 = [];
            
            let valueProvincia = "";
            let valueCiudad = "";
            
            // Si no es admin y tiene cliente admin, asigna la ProvinciaFiscal
            if (!this.noEsAdmin && this.clientByAdmin) {
              valueProvincia = this.clientByAdmin.ProvinciaFiscal || "";
            }
          
            // Si es admin y debe cargar datos automáticos o la propiedad check es true
            if (this.noEsAdmin && (this.needLoadAutomaticData() || this.check)) {
              if (cliente) {
                valueProvincia = cliente.ProvinciaFiscal || "";
                valueCiudad = cliente.LocalidadFiscal || "";
              }
            }
            if(this.check && !this.cargarDatos) {
              if(cliente) {
                console.log("clienteActualEntra", cliente)
                valueProvincia = cliente.ProvinciaFiscal || "";
                valueCiudad = cliente.LocalidadFiscal || "";
              }
            }

          
            // Si se proporciona una Provincia en el clienteModel y cargarDatos es true
            if (clienteModel.Provincia && this.cargarDatos) {
              valueProvincia = clienteModel.Provincia;  // Sobreescribe el valor de la provincia
            }
          
            // Si se proporciona una Ciudad en el clienteModel y cargarDatos es true
            if (clienteModel.Ciudad && this.cargarDatos) {
              valueCiudad = clienteModel.Ciudad;  // Sobreescribe el valor de la ciudad
            }

            
          
            // Asigna los valores de provincia y ciudad almacenados en las variables
            this.datosFacturaForm.get("provincia").patchValue(valueProvincia);
            this.datosFacturaForm.get("ciudad").patchValue(valueCiudad);
          }
        },
        (error) => {
          this.lastSelectedProvincia = "";
          this.provinciasV2 = [];
          this.ciudadesV2 = [];
          let valueProvincia = "";

          if (!this.noEsAdmin && this.clientByAdmin) {
            valueProvincia = this.clientByAdmin.ProvinciaFiscal
              ? this.clientByAdmin.ProvinciaFiscal
              : "";
          }

          if (
            this.noEsAdmin &&
            (this.needLoadAutomaticData() || this.cargarDatos === true)
          ) {
            if (cliente) {
              valueProvincia = cliente.ProvinciaFiscal
                ? cliente.ProvinciaFiscal
                : "";
            }
          }

          this.datosFacturaForm.get("provincia").setValue(valueProvincia);
        }
      );
    }
    this.lastSelectedCodigoPais = paisId;
  }

  loadCiudadesV2(paisId: string, codProvincia: string) {
    console.log("entra acá", paisId, codProvincia)
    const idioma = this.translateService.getLocale();
    const body: HttpBodyGetProvincias = {
      Idioma: idioma,
      idPais: paisId,
      CodProvincia: codProvincia,
      Filtro: "ciudad",
    };
    const cliente = this.userService.currentUserValue;
    const clienteModel = this.ventaService.clienteValue;
    this.productosService.GetProvinciasV2(body).subscribe(
      (res) => {
        if (res?.DatosResult?.DatosCiudades && res?.DatosResult?.DatosCiudades.length > 0) {
          const ciudadesResponse = res.DatosResult.DatosCiudades.filter(
            (p) => parseInt(p.Seleccionable) === 1
          );
          
          this.ciudadesV2 = ciudadesResponse.sort(
            (a, b) => parseInt(a.OrdenVisualizacion) - parseInt(b.OrdenVisualizacion)
          );
        
          let ciudadValue = this.ciudadesV2.length > 0 ? this.ciudadesV2[0] : "";
        
          // Caso: No es admin y tiene cliente admin
          if (!this.noEsAdmin && this.clientByAdmin) {
            const ciudadNombre = this.clientByAdmin.LocalidadFiscal || "";
            const ciudadFind = this.ciudadesV2.find(
              (c) => String(c.NombreCiudad).toLowerCase() === String(ciudadNombre).toLowerCase()
            );
        
            ciudadValue = ciudadFind || ciudadValue;
          }
        
          // Caso: Es admin y debe cargar datos automáticos o cargarDatos es true
          if (this.noEsAdmin && (this.needLoadAutomaticData() || this.cargarDatos === true)) {
            this.clientByAdmin = null;
            if (cliente) {
              const ciudadNombre = cliente.LocalidadFiscal || "";
              const ciudadFind = this.ciudadesV2.find(
                (c) => String(c.NombreCiudad).toLowerCase() === String(ciudadNombre).toLowerCase()
              );
        
              ciudadValue = ciudadFind || ciudadValue;
            }
          }
        
          // Asignación de ciudad
          if (clienteModel.Ciudad !== "") {
            const ciudadFind = this.ciudadesV2.find(
              (c) => String(c.NombreCiudad).toLowerCase() === String(clienteModel.Ciudad).toLowerCase()
            );
            ciudadValue = ciudadFind ? ciudadFind : clienteModel.Ciudad;
          }
          console.log(ciudadValue)
          // Asigna el valor final de ciudad al formulario
          this.datosFacturaForm.get("ciudad").setValue(ciudadValue.CodCiudad || ciudadValue);
        } else {
          this.ciudadesV2 = [];

          let ciudadValue = ""; // Variable temporal para almacenar el valor de la ciudad

          // Si clienteModel tiene una ciudad
          if (clienteModel.Ciudad && this.cargarDatos) {
            ciudadValue = clienteModel.Ciudad;
          } 

          if(this.check && !this.cargarDatos) {
            if(cliente) {
              console.log("clienteActualEntraCiudad", cliente)
              ciudadValue = cliente.LocalidadFiscal || "";
            }
          }
          console.log(ciudadValue)
          // Asigna el valor de ciudad al formulario
          this.datosFacturaForm.get("ciudad").setValue(ciudadValue);

        }
      },
      (error) => {
        this.ciudadesV2 = [];
        this.datosFacturaForm
          .get("ciudad")
          .setValue(cliente ? cliente.LocalidadFiscal : "");
      }
    );
  }

  checkFactura() {
    this.check = !this.check;
    if(this.check) {
      this.loadClienteValues();
    }
    const em = this.ventaService.carritoValue.compras[0].producto.enlaceMenu;
    const enlaceMenu = this.productosService.enlacesMenuValue.find(
      (e) => e.pkid === em
    );
    if (enlaceMenu.iSolicitaFactura === "2") {
      this.isCheck.emit(this.check);
      return this.check;
    } else {
      this.isCheck.emit(this.check);
      return this.check;
    }
  }

  loadClienteValues() {
    const cliente = this.userService.currentUserValue;
    console.log(cliente)
    this.clientByAdmin = null;

    if (cliente) {
      let dni = "";
      if (cliente.TipoDocumentoFiscal === "DNI/NIF") {
        dni = "DNI";
      }

      this.datosFacturaForm.controls.nombre.patchValue(
        cliente.NombreClienteFiscal
          ? cliente.NombreClienteFiscal
          : cliente.NombreClienteOriginal
      );

      if (
        this.translateService.data["Código Postal"] &&
        this.translateService.data["Código Postal"] !== ""
      ) {
        this.datosFacturaForm.controls.codigo.patchValue(
          cliente.CPFiscal ? cliente.CPFiscal : cliente.CP
        );
      } else {
        this.datosFacturaForm.controls.codigo.patchValue("");
      }

      this.datosFacturaForm.controls.tipo.patchValue(
        dni ? dni : cliente.TipoDocumento
      );

      this.datosFacturaForm.controls.documento.patchValue(
        cliente.CIFoDNIFiscal ? cliente.CIFoDNIFiscal : cliente.CIFoDNI
      );

      this.datosFacturaForm.controls.direccion.patchValue(
        cliente.DomicilioFiscal ? cliente.DomicilioFiscal : cliente.Domicilio
      );

      const codPais = cliente.PaisFiscal ? cliente.PaisFiscal : "";

      const paisFind = this.paisesV2.find((p) => p.CodPais == codPais);
      this.datosFacturaForm.controls.codigoPais.patchValue(
        paisFind ? paisFind.idPais : ""
      );
      this.datosFacturaForm.controls.pais.patchValue(
        paisFind ? paisFind.NombrePais : ""
      );

      // this.datosFacturaForm.controls.provincia.patchValue(
      //   cliente.ProvinciaFiscal ? cliente.ProvinciaFiscal : ""
      // );

      // this.datosFacturaForm.controls.ciudad.patchValue(
      //   cliente.LocalidadFiscal ? cliente.LocalidadFiscal : ""
      // );
    }
  }

  validateFormByEnlaceMenu() {
    const em = this.ventaService.carritoValue.compras[0].producto.enlaceMenu;
    const enlaceMenu = this.productosService.enlacesMenuValue.find(
      (e) => e.pkid === em
    );

    if (parseInt(enlaceMenu.iSolicitaFactura) === 3) {
      this.check = true;
      this.isCheck.emit(this.check);
      this.isBlockedCargarDatosCliente = true;
      this.cargarDatos = false;
      this.cargarDatosClienteAutomatic();
      this.blockFacturaForm();
    } else if (parseInt(enlaceMenu.iSolicitaFactura) === 5) {
      this.check = true;
      this.isCheck.emit(this.check);
      this.isBlockedCargarDatosCliente = false;
      this.cargarDatos = false;
    } else {
      //TODO: Load default province and city
    }
  }

  needLoadAutomaticData() {
    const em = this.ventaService.carritoValue.compras[0].producto.enlaceMenu;
    const enlaceMenu = this.productosService.enlacesMenuValue.find(
      (e) => e.pkid === em
    );

    return parseInt(enlaceMenu.iSolicitaFactura) === 3;
  }

  blockFacturaForm() {
    this.datosFacturaForm.disable();
  }

  getFieldIsDisabled(fieldName: string) {
    return this.formObjectConfig[fieldName]?.disabled || false;
  }

  cargarDatosClienteAutomatic() {
    this.cargarDatos = !this.cargarDatos;
    if (this.cargarDatos) {
      this.clientByAdmin = null;
      //this.loadClienteValues();
        const clienteModel = this.ventaService.clienteValue;
        console.log(clienteModel)

        this.datosFacturaForm.controls.nombre.patchValue(
          (clienteModel.NombreCliente || '') + " " + (clienteModel.Apellidos || '')
        );
        this.datosFacturaForm.controls.codigo.patchValue(
          clienteModel.CodigoPostal
        );

        //TIPO DOCUMENTO
        const tipoDocumentoCliente = String(clienteModel.TipoDocumento)
          .trim()
          .toUpperCase();

        const tipoDocumentoValido = this.optionsDrop
          .map((option) => option.toUpperCase())
          .includes(tipoDocumentoCliente);

        if (tipoDocumentoValido) {
          this.datosFacturaForm.controls.tipo.patchValue(tipoDocumentoCliente);
        } else {
          this.datosFacturaForm.controls.tipo.patchValue("");
        }
        //TIPO DOCUMENTO

        this.datosFacturaForm.controls.documento.patchValue(
          clienteModel.Documento
        );
        this.datosFacturaForm.controls.direccion.patchValue(
          clienteModel.Direccion
        );
        //this.datosFacturaForm.controls.ciudad.patchValue(clienteModel.Ciudad);

        const paisFind = this.paisesV2.find(
          (p) => p.CodPais == clienteModel.CodigoPais
        );
        if (paisFind) {
          this.datosFacturaForm.controls.codigoPais.patchValue(paisFind.idPais);
        }
        const provinciaFind = this.provinciasV2.find(
          (x) => x.idProvincia == clienteModel.Provincia
        );
        // this.datosFacturaForm.controls.provincia.patchValue(
        //   provinciaFind ? provinciaFind.idProvincia : clienteModel.Provincia
        // );

        // Forzar que el listener del campo 'provincia' se dispare después de asignar el valor
        //this.datosFacturaForm.controls.provincia.updateValueAndValidity();
      
    } else if(this.userService.currentUserValue && this.check === true) {
      this.loadClienteValues();
    }
    else {
      this.datosFacturaForm.controls.nombre.patchValue("");
      this.datosFacturaForm.controls.codigo.patchValue("");
      this.datosFacturaForm.controls.tipo.patchValue("");
      this.datosFacturaForm.controls.documento.patchValue("");
      this.datosFacturaForm.controls.direccion.patchValue("");
      this.datosFacturaForm.controls.ciudad.patchValue("");
      this.datosFacturaForm.controls.pais.patchValue("");
      this.lastSelectedCodigoPais = "";
      this.lastSelectedProvincia = "";
      this.datosFacturaForm.controls.codigoPais.patchValue(
        this.paisesV2.length > 0 ? this.paisesV2[0].idPais : ""
      );
      this.datosFacturaForm.controls.provincia.patchValue("");
    }
  }

  /* Deprecated */
  cargarDatosCliente() {
    this.cargarDatos = !this.cargarDatos;
    if (this.cargarDatos) {
      const clienteModel = this.ventaService.clienteValue;
      if (clienteModel) {
        const nombreCliente =
          clienteModel.NombreCliente +
          " " +
          clienteModel.Apellido1 +
          " " +
          clienteModel.Apellido2;
        this.datosFacturaForm.controls.nombre.patchValue(nombreCliente);
        this.datosFacturaForm.controls.codigo.patchValue(
          clienteModel.CodigoPostal
        );
        if (clienteModel.TipoDocumento === "Otro") {
          this.datosFacturaForm.controls.tipo.patchValue("");
          this.datosFacturaForm.controls.documento.patchValue("");
        } else {
          this.datosFacturaForm.controls.tipo.patchValue(
            clienteModel.TipoDocumento
          );
          this.datosFacturaForm.controls.documento.patchValue(
            clienteModel.Documento
          );
        }
        this.datosFacturaForm.controls.direccion.patchValue(
          clienteModel.Direccion
        );
        this.datosFacturaForm.controls.ciudad.patchValue(clienteModel.Ciudad);
        this.datosFacturaForm.controls.pais.patchValue(clienteModel.Pais);
        this.datosFacturaForm.controls.codigoPais.patchValue(
          clienteModel.CodigoPais
        );
        this.datosFacturaForm.controls.provincia.patchValue(
          clienteModel.Provincia
        );
      }
    } else {
      this.datosFacturaForm.controls.nombre.patchValue("");
      this.datosFacturaForm.controls.codigo.patchValue("");
      this.datosFacturaForm.controls.tipo.patchValue("");
      this.datosFacturaForm.controls.documento.patchValue("");
      this.datosFacturaForm.controls.direccion.patchValue("");
      this.datosFacturaForm.controls.ciudad.patchValue("");
      this.datosFacturaForm.controls.pais.patchValue("");
      this.datosFacturaForm.controls.codigoPais.patchValue("");
      this.datosFacturaForm.controls.provincia.patchValue("");
    }
  }

  abrirBuscador(campo) {
    this.abrir = [];
    this.abrir.push({ campo, abierto: true });
    this.clientes = [];
    this.setPage(1);
  }

  estaAbierto() {
    let abierto = false;
    if (this.abrir.length) {
      abierto = true;
      return abierto;
    } else {
      return false;
    }
  }

  textoBuscar(texto, campo) {
    this.modelChanged.next(texto);
    this.campo = campo;
  }

  buscador() {
    this.modelChanged
      .pipe(debounceTime(500))
      .pipe(
        exhaustMap((input) => this.userService.getClientes(input, this.campo))
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res) {
          if (res.length) {
            this.clientes = res;
            this.totalPages = Math.ceil(this.clientes.length / 5);
            this.setPage(1);
          } else {
            this.clientes = [];
            this.setPage(1);
          }
        } else {
          this.clientes = [];
          this.setPage(1);
        }
      });
  }

  setPage(page: number) {
    this.pager = this.pagerService.getPager(this.clientes.length, page);
    this.pagedItems = this.clientes.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );
    this.pager.pages = this.pager.pages.filter(
      (num) => num <= this.pager.totalPages
    );
    if (page > 6) {
      while (this.pager.pages.length < 6) {
        this.pager.pages.unshift(this.pager.pages[0] - 1);
      }
    }
  }

  anterior() {
    this.setPage(this.pager.currentPage - 1);
  }

  siguiente() {
    this.setPage(this.pager.currentPage + 1);
  }

  seleccionarCliente(cliente) {
    let dni;
    if (cliente.TipoDocumentoFiscal === "DNI/NIF") {
      dni = "DNI";
    }

    this.clientByAdmin = cliente;

    this.datosFacturaForm.controls.nombre.patchValue(
      cliente.NombreClienteFiscal
        ? cliente.NombreClienteFiscal
        : cliente.NombreClienteOriginal
    );

    if (
      this.translateService.data["Código Postal"] &&
      this.translateService.data["Código Postal"] !== ""
    ) {
      this.datosFacturaForm.controls.codigo.patchValue(
        cliente.CPFiscal ? cliente.CPFiscal : cliente.CP
      );
    }

    this.datosFacturaForm.controls.tipo.patchValue(
      dni ? dni : cliente.TipoDocumento
    );
    this.datosFacturaForm.controls.documento.patchValue(
      cliente.CIFoDNIFiscal ? cliente.CIFoDNIFiscal : cliente.CIFoDNI
    );
    this.datosFacturaForm.controls.direccion.patchValue(
      cliente.DomicilioFiscal ? cliente.DomicilioFiscal : cliente.Domicilio
    );
    this.datosFacturaForm.controls.ciudad.patchValue(
      cliente.LocalidadFiscal ? cliente.LocalidadFiscal : cliente.Localidad
    );

    const codigoPais = cliente.PaisFiscal ? cliente.PaisFiscal : "";

    const paisFind = this.paisesV2.find((p) => p.CodPais == codigoPais);

    const paisValue = paisFind ? paisFind : this.paisesV2[0];

    this.datosFacturaForm.controls.pais.patchValue(
      paisValue ? paisValue.NombrePais : ""
    );

    this.datosFacturaForm.controls.codigoPais.patchValue(
      paisValue ? paisValue.idPais : ""
    );

    this.abrir = [];
  }
}

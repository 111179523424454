import { Pipe, PipeTransform } from "@angular/core";

const PADDING = "000000";

@Pipe({
	name: "myCurrency",
})
export class MyCurrencyPipe implements PipeTransform {
	private DECIMAL_SEPARATOR: string;
	private FRACTION_SIZE: number;
	private THOUSAND_SEPARATOR: string;

	constructor() {
		this.DECIMAL_SEPARATOR = ",";
		this.FRACTION_SIZE = 2;
		this.THOUSAND_SEPARATOR = "."; // Default to dot for thousands
	}

	transform(
    value: number | string,
    separadorDecimal: string,
    numeroDecimales: number,
    separadorMiles: string = "."
  ): string {
		this.DECIMAL_SEPARATOR = separadorDecimal;
		this.FRACTION_SIZE = numeroDecimales;
    this.THOUSAND_SEPARATOR = separadorMiles;

		if (value === undefined) {
			return "";
		}

		if (typeof value === "string") {
			value = Number.parseFloat(value.replace(",", "."));
		}
		// tslint:disable-next-line:prefer-const
		let [integer, fraction = ""] = (value || "0").toString().split(".");
    integer = this.formatIntegerPart(integer);

		fraction =
			this.FRACTION_SIZE > 0
				? this.DECIMAL_SEPARATOR +
				  (fraction + PADDING).substring(0, this.FRACTION_SIZE)
				: "";

		return integer + fraction;
	}

  private formatIntegerPart(integer: string): string {
    if (this.THOUSAND_SEPARATOR === " ") {
      return integer;
    }
    const reversedInteger = integer.split("").reverse().join("");
    const groupedInteger =
      reversedInteger.match(/.{1,3}/g)?.join(this.THOUSAND_SEPARATOR) || "";
    return groupedInteger.split("").reverse().join("");
  }
}

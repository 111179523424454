import { Component, OnInit, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { Router } from '@angular/router';
import { ProductosService } from './productos.service';
import { AppConfiguration } from '../app.configuration';

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.scss']
})
export class BusinessComponent implements OnInit, AfterViewChecked {

  enlaceMenu;
  loading;
  proyecto: string;
  constructor(
    private cdRef: ChangeDetectorRef, 
    private router: Router,
    private productosService: ProductosService,
    private config: AppConfiguration
    ) { }

  ngOnInit() {
    this.proyecto = this.config.getProyecto();
    this.productosService.enlaceMenu$.subscribe(enlaceMenu => {
      this.enlaceMenu = enlaceMenu;
    });
    this.productosService.cargando$.subscribe(carga => this.loading = carga);
  }
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
  public getRouterStartWith(ruta){
    return this.router.url.startsWith(ruta);
  }

  public getRoute(ruta){
    return this.router.url === ruta;
  }
}

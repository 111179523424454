import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  BehaviorSubject,
  Observable
} from 'rxjs';
import { Token } from './token.model';
import { map } from 'rxjs/operators';

import { AppConfiguration } from 'src/app/app.configuration';
import { TranslateService } from 'src/app/shared/translate.service';

interface RequestBody {
  nombre: string;
  // contrasena: string;
  id: string;
}

@Injectable({
  providedIn: 'root'
})
export class TokenManagerService {

  private currentTokenSubject: BehaviorSubject<Token>;
  currentToken$: Observable<Token>;

  private currentConexionIdSubject: BehaviorSubject<string>;
  currentConexionId$: Observable<string>;

  constructor(private http: HttpClient,
              private config: AppConfiguration
              ) {
    this.currentTokenSubject = new BehaviorSubject<Token>(JSON.parse(sessionStorage.getItem('currentToken')));
    this.currentToken$ = this.currentTokenSubject.asObservable();

    this.currentConexionIdSubject = new BehaviorSubject<string>(undefined);
    this.currentConexionId$ = this.currentConexionIdSubject.asObservable();
  }

  public get currentTokenValue(): Token {
    return this.currentTokenSubject.value;
  }

  public setCurrentTokenValue(token){
    this.currentTokenSubject.next(token);
  }

  public get currentStorageToken(): Token {
    return JSON.parse(sessionStorage.getItem('currentToken')) ;
  }

  public get currentStorageConexionValue(): Token {
    return JSON.parse(sessionStorage.getItem('ConexionId')) ;
  }
  
  public get currentConexionIdValue(): string {
    return this.currentConexionIdSubject.value;
  }

  public setCurrentConexionIdValue(id) {
    sessionStorage.setItem('ConexionId',id); 
    this.currentConexionIdSubject.next(id);
  }

  login(nombre: string): Observable<any> {
    const credenciales = this.generarCredencialesRegistro(nombre);
    return this.http.post(`${this.config.getConfig('API_URL')}api/IniciarLog`, credenciales)
      .pipe(
        map((res: any) => {
          const token = res.DatosResult;
          const currentSession: Token = {
            sessionId: credenciales.id,
            token
          };
          sessionStorage.setItem('currentToken', JSON.stringify(currentSession));
          this.currentTokenSubject.next(currentSession);
          return currentSession;
        })
      );
  }

  logout(): void {
    sessionStorage.removeItem('currentToken');
    sessionStorage.removeItem('descuento');
    sessionStorage.removeItem('cesta');
    sessionStorage.removeItem('fechaSeleccionadaSinSession');
    sessionStorage.removeItem('estaPagada');
    sessionStorage.removeItem('admin');
    this.currentTokenSubject.next(null);
  }

  private generarSessionId(): string {
    const valorFechaActual = new Date().valueOf();
    const valorAleatorio = Math.random().toString().slice(-5);
    return '' + valorAleatorio + valorFechaActual;
  }

  private generarCredencialesRegistro(nombre: string): RequestBody {
    const credencialesRegistro: RequestBody = {
      nombre,
      id: this.generarSessionId()
    };
    return credencialesRegistro;
  }

  funcionEncript(json) {
    const clavePrivada = '3ñdlfjdfksdffffs%i2343424kjfdsü&/@#$A·';
    let final = '';
    for (let index = 1; index <= json.length; index++) {
      const tokenMio = this.Mid(json, index, 1);
      const codigo = this.Mid(clavePrivada, ((index - 1) % clavePrivada.length) + 1, 1);
      // tslint:disable-next-line:no-bitwise
      const a = (this.Asc(codigo) ^ this.Asc(tokenMio)).toString(16);
      final = final + this.Right('0' + a, 2);
    }
    return final;
  }

  funcionDesencript(token) {
    const clavePrivada = '3ñdlfjdfksdffffs%i2343424kjfdsü&/@#$A·';
    let final = '';
    let j = 1;
    for (let index = 1; index <= token.length; index += 2) {
      const tokenMio = this.Mid(token, index, 2);
      const codigo = this.Mid(clavePrivada, ((j - 1) % clavePrivada.length) + 1, 1);
      // tslint:disable-next-line:no-bitwise
      final = final + this.Chr(this.Asc(codigo) ^ (parseInt('0x' + tokenMio, 16)));
      j = j + 1;
    }
    return final;
  }

  Right(str, n) {
    if (n <= 0) {
      return '';
    } else if (n > String(str).length) {
      return str;
    } else {
      const iLen = String(str).length;
      return String(str).substring(iLen, iLen - n);
    }
  }

  Mid(strMid, intBeg, intEnd) {
    if (strMid === null || strMid === '' || intBeg < 0) {
      return '';
    }
    intBeg -= 1;
    if (intEnd === null || intEnd === '') {
      return strMid.substr(intBeg);
    } else {
      return strMid.substr(intBeg, intEnd);
    }
  }

  Chr(num) {
    const res = String.fromCharCode(num);
    return res;
  }

  Asc(str) {
    return str.charCodeAt(0);
  }

}

import { Component, OnDestroy, OnInit, Input } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { TokenManagerService } from "src/app/core/token/token-manager.service";
import { VentaService } from "../../core/services/venta.service";
import { ProductosService } from "../productos.service";
import { InitService } from "src/app/core/init/init.service";
import { ActivatedRoute, Router } from "@angular/router";
import { CalendarService } from "../ticket-sales/pages/date-selector/calendar/calendar.service";
import { mergeMap, map, take } from "rxjs/operators";
import { Subscription } from "rxjs";
import { AppConfiguration } from "src/app/app.configuration";
import { Compra } from "src/app/models/compra.model";
import { GoogleAnalyticsEventsServiceService } from "src/app/shared/google-analytics-events-service.service";
import { UserService } from "src/app/user/user.service";
import { ToastrService } from "ngx-toastr";
import { ConfigurationService } from "src/app/core/config/configuration.service";
import { PaymentService } from "src/app/core/payment/payment.service";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import * as moment from "moment";
import { ButacasMultiplesService } from "../espectaculos-butacas/mapa/servicios/butacas.multiples.service";
import { ButacasService } from "../espectaculos-butacas/mapa/servicios/butacas.service";
import { ModalService } from "src/app/shared/modal.service";

@Component({
  selector: "app-products-catalog",
  templateUrl: "./products-catalog.component.html",
  styleUrls: ["./products-catalog.component.scss"],
})
export class ProductsCatalogComponent implements OnInit, OnDestroy {
  buscadorForm: FormGroup;
  productos$;
  todosProductos;
  totalProductos;
  mostrarFiltros = this.calendarService.filtrosValue;
  pantallasFiltros;
  backupProds;
  loading = true;
  proyecto = this.config.getProyecto(); //Yaribel 20210713 añadimos proyecto para obtener los assets genericamente
  verAreaCliente: number;
  configurationSub: Subscription;
  compradisabled = false;
  espectaculosConfig;
  iPadre;
  minDate = new Date();

  constructor(
    private tokenService: TokenManagerService,
    private ventaService: VentaService,
    private productosService: ProductosService,
    private butacasService: ButacasService,
    private initService: InitService,
    private modalService: ModalService,
    private router: Router,
    private calendarService: CalendarService,
    private formBuilder: FormBuilder,
    private config: AppConfiguration,
    private route: ActivatedRoute,
    private GAService: GoogleAnalyticsEventsServiceService,
    private userService: UserService,
    private configurationService: ConfigurationService,
    private toast: ToastrService,
    private paymentService: PaymentService,
    private butacasMultiService: ButacasMultiplesService
  ) {}

  ngAfterContentInit() {
    /*     this.GAService.callStepView('productCatalog');
     */
  }
  ngOnInit() {
    this.configurationSub = this.configurationService.configuration$.subscribe(
      (config) => {
        if (
          config &&
          config != undefined &&
          config != null &&
          config.datosProyecto
        ) {
          this.verAreaCliente =
            this.configurationService.getCaracteristicasAdicionalesProyecto()
              .MostrarAreaCliente || 0;
        }
      }
    );
    this.ventaService.setCompra(new Compra());
    this.productosService.setVentaEspectaculo(false);
    this.ventaService.setModificarValue(false);
    this.butacasMultiService.arrMarcadas = [];
    this.butacasMultiService.setCheckMultipleValue(false);
    this.butacasMultiService.setDisabledMultiple(false);
    this.butacasMultiService.setSiButacasValue(false);
    this.ventaService.setFechaSeleccionadaValue(moment());
    this.buscadorForm = this.formBuilder.group({
      buscador: [""],
      fechaBusqueda: [""],
    });

    this.userService.currentUser$.subscribe((user) => {
      if (user && user.DeBaja == "1") {
        this.productos$ = null;
      }
    });
    this.iPadre = null;
    if (this.route.snapshot.queryParams.iPadre) {
      this.iPadre = this.route.snapshot.queryParams.iPadre;
    }
    if (this.userService.currentUserValue) {
      if (JSON.parse(sessionStorage.getItem("cliente"))) {
      } else {
        this.ventaService.mapCliente(this.userService.currentUserValue);
      }
    }

    if (!this.initService.startedValue) {
      let datosUrl;
      if (this.route.snapshot.queryParams.ck) {
        datosUrl = this.route.snapshot.queryParams.ck;
        sessionStorage.setItem("token", datosUrl);
      }
      if (this.route.snapshot.queryParams.ck) {
        datosUrl = this.route.snapshot.queryParams.ck;
        sessionStorage.setItem("token", datosUrl);
      }

      if (this.route.snapshot.queryParams.iPadre) {
        this.iPadre = this.route.snapshot.queryParams.iPadre;
      }

      //this.espectaculosConfig = JSON.parse(this.configurationService.configurationValue.datosProyecto.CaracteristicasAdicionales).CaracteristicasAdicionales.Espectaculos;
      this.initService
        .getInitData()
        .pipe(
          mergeMap((res) =>
            this.productosService
              .getTodosProductos(
                this.iPadre && this.iPadre != 0
                  ? this.iPadre
                  : this.productosService.getEnlaceMenuId()
                  ? this.productosService.getEnlaceMenuId().iPadre &&
                    this.productosService.getEnlaceMenuId().iPadre != 0
                    ? this.productosService.getEnlaceMenuId().iPadre
                    : this.productosService.getEnlaceMenuId().pkid
                  : this.userService.currentUserValue
                  ? this.validateReserva()
                  : this.getEnlacesMenu()
              )
              .pipe(
                map((res) => {
                  if (res) {
                    this.productosService.setTodosProductosCat(res);
                    this.productosService.setProductosCat(
                      res.DictTarifasEntradas &&
                        Object.values(res.DictTarifasEntradas).length > 0
                        ? Object.values(res.DictTarifasEntradas)
                        : res.ListTarifasEntradas
                    );
                    this.productosService.setBackupProds(
                      this.productosService.productosCatValue
                    );

                    this.loading = false;
                    this.initService.setModalCookiesValue(true);
                  } else {
                    // this.toast.error('No hay productos disponibles para este cliente...', '', {
                    //   timeOut: 3000,
                    //   positionClass: 'toast-top-right',
                    // });
                    this.loading = false;
                  }
                  return res;
                })
              )
          )
        )
        .pipe(
          mergeMap((res) =>
            this.productosService
              .getTodosEspectaculos(
                this.iPadre && this.iPadre != 0
                  ? this.iPadre
                  : this.productosService.getEnlaceMenuId()
                  ? this.productosService.getEnlaceMenuId().iPadre
                    ? this.productosService.getEnlaceMenuId().iPadre
                    : this.productosService.getEnlaceMenuId().pkid
                  : this.userService.currentUserValue
                  ? this.validateReserva()
                  : this.getEnlacesMenu()
              )
              .pipe(
                map((result) => {
                  if (result && result.EspectaculosDisponible) {
                    this.productosService.setTodosEspectaculosCat(result);
                    this.productosService.setEspectaculosCat(
                      result.EspectaculosDisponible
                    );
                    this.productosService.setBackupEspectaculos(
                      this.productosService.espectaculosCatValue
                    );
                  } else {
                    // this.toast.error('No hay productos disponibles para este cliente...', '', {
                    //   timeOut: 3000,
                    //   positionClass: 'toast-top-right',
                    // });
                    this.loading = false;
                  }

                  if (!res) {
                    res = { res: null, result: null };
                  }

                  res.res = res;
                  res.result = result;
                  return res;
                })
              )
          )
        )
        .pipe(take(1))
        .subscribe((res) => {
          this.espectaculosConfig = JSON.parse(
            this.configurationService.configurationValue.datosProyecto
              .CaracteristicasAdicionales
          ).CaracteristicasAdicionales.Espectaculos;
          if (
            this.route.snapshot.queryParams.ck &&
            +this.productosService.getEnlaceMenuId().iLogueo >= 1
          ) {
            /** Only apply for tickamore_unitario products */
            this.productosService.setDataRegistro();
            this.modalService.open("LoginAndRegister");
          }
          if (this.ventaService.carritoValue.compras.length === 0) {
            this.ventaService.cargarCesta();
            this.ventaService.cargarFechaSeleccionadasSinSesion();
          }
        });
    } else {
      this.espectaculosConfig = JSON.parse(
        this.configurationService.configurationValue.datosProyecto
          .CaracteristicasAdicionales
      ).CaracteristicasAdicionales.Espectaculos;

      this.productosService
        .getTodosProductos(
          this.iPadre && this.iPadre != 0
            ? this.iPadre
            : this.productosService.getEnlaceMenuId()
            ? this.productosService.getEnlaceMenuId().pkid
            : this.getEnlacesMenu()
        )
        .pipe(
          mergeMap((res: any) =>
            this.productosService
              .getTodosEspectaculos(
                this.iPadre && this.iPadre != 0
                  ? this.iPadre
                  : this.productosService.getEnlaceMenuId()
                  ? this.productosService.getEnlaceMenuId().pkid
                  : this.getEnlacesMenu()
              )
              .pipe(
                map((result) => {
                  if (result && result.EspectaculosDisponible) {
                    this.productosService.setTodosEspectaculosCat(result);
                    this.productosService.setEspectaculosCat(
                      result.EspectaculosDisponible
                    );
                    this.productosService.setBackupEspectaculos(
                      this.productosService.espectaculosCatValue
                    );
                  }

                  if (!res) {
                    res = { res: null, result: null };
                  }

                  res.res = res;
                  res.result = result;
                  return res;
                })
              )
          )
        )
        .pipe(take(1))
        .subscribe((res: any) => {
          if (res.res) {
            this.productosService.setTodosProductosCat(res.res);
            this.productosService.setProductosCat(
              res.res.DictTarifasEntradas &&
                Object.values(res.res.DictTarifasEntradas).length > 0
                ? Object.values(res.res.DictTarifasEntradas)
                : res.res.ListTarifasEntradas
            );
            this.productosService.setBackupProds(
              this.productosService.productosCatValue
            );

            this.loading = false;

            this.initService.setModalCookiesValue(true);
          } else if (!res.result) {
            this.toast.error(
              "No hay productos disponibles para este cliente...",
              "",
              {
                timeOut: 3000,
                positionClass: "toast-top-right",
              }
            );
            this.loading = false;
          } else {
            this.loading = false;
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.productosService.setFiltroProductos(undefined);
    if (this.configurationSub) {
      this.configurationSub.unsubscribe();
    }
  }

  get d() {
    return this.buscadorForm.controls;
  }

  private validateReserva = (): string => {
    if (!sessionStorage.getItem("reservaOriginal")) {
      return "";
    }
    const reservaObj = JSON.parse(sessionStorage.getItem("reservaOriginal"));
    if (reservaObj && reservaObj.compras.length > 0) {
      return reservaObj.compras[0].producto.EnlaceMenu;
    } else {
      return "";
    }
  };

  getEnlacesMenu() {
    let iLogueo: number = 0;
    if (
      this.productosService.datosInitValue &&
      this.productosService.getEnlaceMenuId()
    ) {
      iLogueo = parseInt(this.productosService.getEnlaceMenuId().iLogueo);
    }
    if (!this.verAreaCliente) {
      const enlacesMenu = this.productosService.enlacesMenuValue.filter(
        (em) => {
          if (+em.iLogueo < 0) {
            return true;
          } else {
            return false;
          }
        }
      );
      let emIds = "";
      enlacesMenu.forEach((em) => {
        emIds = emIds + " " + em.pkid + ",";
      });
      emIds = emIds.slice(0, -1);
      return emIds;
    }
    if (this.verAreaCliente && !this.userService.currentUserValue) {
      const enlacesMenu = this.productosService.enlacesMenuValue.filter(
        (em) => {
          if (+em.iLogueo < 1 || +em.iLogueo === 3) {
            return true;
          } else {
            return false;
          }
        }
      );
      let emIds = "";
      enlacesMenu.forEach((em) => {
        emIds = emIds + " " + em.pkid + ",";
      });
      emIds = emIds.slice(0, -1);
      return emIds;
    }
  }

  buscar() {
    this.productosService.setFiltroProductos(
      this.buscadorForm.value.buscador.toLowerCase()
    );
  }

  limpiarFiltro() {
    this.buscadorForm.get("buscador").setValue("");
    this.productosService.setFiltroProductos("");
  }

  mostrarCerrar() {
    return this.buscadorForm.value.buscador.length > 0;
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.productosService.setFechaFiltroProductos(
      this.buscadorForm.get("fechaBusqueda").value
    );
    this.productosService
      .getTodosProductos(
        this.iPadre && this.iPadre != 0
          ? this.iPadre
          : this.productosService.getEnlaceMenuId()
          ? this.productosService.getEnlaceMenuId().pkid
          : this.getEnlacesMenu()
      )
      .pipe(take(1))
      .subscribe((res: any) => {
        if (res) {
          this.productosService.setTodosProductosCat(res);
          this.productosService.setProductosCat(
            res.DictTarifasEntradas &&
              Object.values(res.DictTarifasEntradas).length > 0
              ? Object.values(res.DictTarifasEntradas)
              : res.ListTarifasEntradas
          );
          this.productosService.setBackupProds(
            this.productosService.productosCatValue
          );

          this.loading = false;
          this.initService.setModalCookiesValue(true);
        } else {
          this.toast.error(
            "No hay productos disponibles para este cliente...",
            "",
            {
              timeOut: 3000,
              positionClass: "toast-top-right",
            }
          );
          this.loading = false;
        }
      });

    this.productosService
      .getTodosEspectaculos(
        this.iPadre && this.iPadre != 0
          ? this.iPadre
          : this.productosService.getEnlaceMenuId()
          ? this.productosService.getEnlaceMenuId().pkid
          : this.getEnlacesMenu()
      )
      .pipe(
        map((res) => {
          if (res && res.EspectaculosDisponible) {
            this.productosService.setTodosEspectaculosCat(res);
            this.productosService.setEspectaculosCat(
              res.EspectaculosDisponible
            );
            this.productosService.setBackupEspectaculos(
              this.productosService.espectaculosCatValue
            );
          }
        })
      )
      .pipe(take(1))
      .subscribe();
  }
}

import { Component, OnDestroy, OnInit } from "@angular/core";
import { ProductosService } from "../productos.service";
import { Router } from "@angular/router";
import { map, first, takeWhile, take, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import * as moment from "moment";
import { InitService } from "src/app/core/init/init.service";
import { ConfigurationService } from "src/app/core/config/configuration.service";
import { VentaService } from "../../core/services/venta.service";
import { CalendarService } from "../ticket-sales/pages/date-selector/calendar/calendar.service";
import { AppConfiguration } from "src/app/app.configuration";
import { ButacasService } from "../espectaculos-butacas/mapa/servicios/butacas.service";
import { Compra } from "src/app/models/compra.model";
import { ButacasMultiplesService } from "../espectaculos-butacas/mapa/servicios/butacas.multiples.service";
import { EspectaculosService } from "../espectaculos-butacas/espectaculos.service";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "src/app/shared/translate.service";

@Component({
  selector: "app-interest-products",
  templateUrl: "./interest-products.component.html",
  styleUrls: ["./interest-products.component.scss"],
})
export class InterestProductsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  proyecto = this.config.getProyecto();
  productos;
  productosRelacionados = [];
  prodRel;
  confirmado;
  mostrarFiltros = this.calendarService.filtrosValue;
  pantallasFiltros;
  em;
  mostrarRelacionados;
  cargado;
  public modificarReserva;
  public modificarReservaNoPagada;
  estaPagada: Boolean = false;

  constructor(
    private config: AppConfiguration,
    private calendarService: CalendarService,
    private productosService: ProductosService,
    private router: Router,
    private initService: InitService,
    private configurationService: ConfigurationService,
    private ventaService: VentaService,
    private butacasService: ButacasService,
    private butacasMultiService: ButacasMultiplesService,
    private espectaculoService: EspectaculosService,
    private toast: ToastrService,
    private translate: TranslateService
  ) {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.mostrarProductosRelacionados();

    /* if (this.ventaService.modificandoReservaValue) {
      this.modificarReserva = true;
      if (!JSON.parse(sessionStorage.getItem("estaPagada"))) {
        this.modificarReservaNoPagada = true;
        this.estaPagada = false;
      } else {
        this.estaPagada = true;
      }
    } */

    this.ventaService.isReservaPagada$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((reservaPagada) => {
      this.estaPagada = reservaPagada;
    });
    
    this.butacasMultiService.setlistDesmarcar([]);
    this.butacasMultiService.arrMarcadas = [];
    this.butacasMultiService.setCheckMultipleValue(false);
    this.butacasMultiService.setDisabledMultiple(false);
    this.butacasMultiService.setSiButacasValue(false);
  }

  mostrarProductosRelacionados() {
    let muestra = "0";
    let seMuestra;
    this.initService.cargado$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        takeWhile(() => !this.cargado)
      )
      .subscribe((cargado) => {
        this.cargado = cargado;
        if (this.cargado) {

          if (this.ventaService.modificandoReservaValue) {
            this.modificarReserva = true;
            if (!JSON.parse(sessionStorage.getItem("estaPagada"))) {
              this.modificarReservaNoPagada = true;
            }
          }

          if (
            !sessionStorage.getItem("token") &&
            sessionStorage.getItem("admin")
          ) {
            this.mostrarRelacionados = false;
          } else {
            this.ventaService.carritoValue.compras.forEach((compra) => {
              seMuestra = JSON.parse(
                this.productosService.getEnlaceMenuIdPorId(
                  compra.producto.enlaceMenu
                ).CaracteristicasAdicionales
              ).CaracteristicasAdicionales.VerProductosRelacionados;
              if (seMuestra != 0) {
                muestra = seMuestra;
              }
            });
            if (parseInt(muestra) === 1) {
              this.mostrarRelacionados = true;
              this.loadProducts();
            } else if (parseInt(muestra) === 0) {
              this.mostrarRelacionados = false;
            } else if (parseInt(muestra) === 2) {
              this.mostrarRelacionados = true;
              this.loadProductsGI();
            }
          }
        }
      });
  }

  canAddMoreProducts() {
    /* if (this.ventaService.carritoValue.compras.length === 0) {
      return false;
    } */

    if (this.estaPagada) {
      return false;
    }
    return true;
  }

  irRelacionado(producto) {
    this.ventaService.setModificarValue(false);
    this.productosService.setFiltroTipoValue("");
    this.productosService.setFiltroGrupoValue("");
    this.productosService.setFiltroNivelValue("");
    this.productosService.setCargandoValue(true);

    const enlaceMenu = this.productosService.enlacesMenuValue.find(
      (e) => e.pkid === producto.EnlaceMenuPSell
    );
    
    const calendario = JSON.parse(enlaceMenu.chCalendario);
    this.initService.setChCalendarioValue(calendario);

    if (!this.productosService.datosInitValue) {
      this.productosService.setDatosInitValue({
        empresa: this.config.getProyecto(),
        m: enlaceMenu.chNombre,
        gi: producto.GrupoInternetId,
      });
    }

    const datosUrl = JSON.parse(this.productosService.datosInitValue);
    const enlaceMenuNombre = this.productosService.getEnlaceMenuNombre(
      producto.EnlaceMenuPSell
    );
    if (
      parseInt(producto.GrupoInternetPsellId) === 7 &&
      enlaceMenuNombre === "SKI_SNOW"
    ) {
      this.productosService.setOtrosProductosValue("EQUIPO");
    } else {
      this.productosService.setOtrosProductosValue(enlaceMenuNombre);
    }
    this.productosService.setDatosInitValue({
      empresa: datosUrl.empresa,
      m: enlaceMenuNombre,
      gi: producto.GrupoInternetPsellId,
      userId: JSON.parse(this.productosService.datosInitValue).userId,
      lg: datosUrl.lg,
      u: JSON.parse(this.productosService.datosInitValue).u,
      p: JSON.parse(this.productosService.datosInitValue).p,
    });
    sessionStorage.setItem(
      "token",
      this.initService.funcionEncript(this.productosService.datosInitValue)
    );
    this.calendarService.setFiltros(false);
    this.productosService
      .getProductos()
      .pipe(
        map((res) => {
          if (res && res.DictTarifasEntradas) {
            this.productosService.setInfoGrupoValue(
              res.DictTarifasEntradas[
                this.productosService.getGrupoInternet().gi
              ]
            );

            this.productosService.setnombreGrupoValue(
              res.DictTarifasEntradas[
                this.productosService.getGrupoInternet().gi
              ].NombreGrupo
            );

            this.productosService.setProductosValue(
              res.DictTarifasEntradas[
                producto.GrupoInternetPsellId
              ].DictTarifasProductos,

              producto.EnlaceMenuPSell,

              parseInt(producto.GrupoInternetPsellId, 10),

              res.DictTarifasEntradas[
                producto.GrupoInternetPsellId
              ].TipoReservaId,

              res.DictTarifasEntradas[
                producto.GrupoInternetPsellId
              ].NombreGrupo
            );
          }
        })
      )
      .pipe(take(1))
      .subscribe(() => {
        this.productosRelacionados = [];
        this.productosService.setCargandoValue(false);
        this.router.navigate(["/tienda/producto/1"]);
      });
  }

  irRelacionadoV2(producto) {
    console.log({
      action: "irRelacionadoV2",
      producto,
    });
    try {
      this.ventaService.setModificarValue(false);
      this.productosService.setFiltroTipoValue("");
      this.productosService.setFiltroGrupoValue("");
      this.productosService.setFiltroNivelValue("");
      if (
        producto.hasOwnProperty("EspectaculosId") &&
        +producto.EspectaculosId !== 0
      ) {
        this.irEspectaculos(producto);
      } else {
        this.irProducto(producto);
      }
    } catch (e) {
      console.log('Error Ir Relacionado:: ', e);
      this.toast.info("Ocurrió un error al redireccionr, intente más tarde", "Info", {
        timeOut: 5000,
        positionClass: "toast-top-center",
      });
      this.productosService.setCargandoValue(false);
    }
  }

  irEspectaculos(producto) {
    this.productosService.setCargandoValue(true);
    const enlaceMenu = this.productosService.enlacesMenuValue.find(
      (e) => e.pkid === producto.EnlaceMenuPSell
    );
    const calendario = JSON.parse(enlaceMenu.chCalendario);
    this.initService.setChCalendarioValue(calendario);

    /* if (!this.productosService.datosInitValue) {
      this.productosService.setDatosInitValue({
        empresa: this.config.getProyecto(),
        m: enlaceMenu.chNombre,
        gi: producto.GrupoInternetPsellId,
        espectaculoId: producto.EspectaculosId,
      });
    } */
    const datosUrl = JSON.parse(this.productosService.datosInitValue);
    const enlaceMenuNombre = this.productosService.getEnlaceMenuNombre(
      enlaceMenu.pkid
    );

    this.productosService.setDatosInitValue({
      empresa: datosUrl.empresa,
      m: enlaceMenuNombre,
      gi: producto.GrupoInternetPsellId,
      userId: JSON.parse(this.productosService.datosInitValue).userId,
      lg: datosUrl.lg,
      u: JSON.parse(this.productosService.datosInitValue).u,
      p: JSON.parse(this.productosService.datosInitValue).p,
      espectaculoId: producto.EspectaculosId,
    });

    this.espectaculoService.setFechaAutomatica(true);
    this.calendarService.setFiltros(false);
    sessionStorage.setItem(
      "token",
      this.initService.funcionEncript(this.productosService.datosInitValue)
    );

    this.productosService
      .getEspectaculos(producto.EspectaculosId)
      .pipe(
        map((res) => {
          if (res) {
            const espectaculo = res.EspectaculosDisponible.find(
              (x) =>
                x.EspectaculosId == producto.EspectaculosId &&
                x.GrupoRecintoId == producto.GrupoRecintoId &&
                x.EnlaceMenuId == producto.EnlaceMenuPSell &&
                x.GrupoInternetId == producto.GrupoInternetPsellId
            );
            this.productosService.setnombreGrupoValue(espectaculo.NombreGrupo);
            this.productosService.setEspectaculoValue(espectaculo);
            this.productosService.setInfoGrupoValue(espectaculo);
          }
        })
      )
      .pipe(take(1))
      .subscribe(() => {
        this.butacasService.getEstadosButacasInicial();
        this.productosService.setVentaEspectaculo(true);
        this.productosService.setCargandoValue(false);
        this.router.navigate(["/tienda/espectaculo/1"]);
      });
  }


  irProducto(producto) {
    this.productosService.setCargandoValue(true);
    const enlaceMenu = this.productosService.enlacesMenuValue.find(
      (e) => e.pkid === producto.EnlaceMenuPSell
    );

    console.log({
      action: "irProducto",
      enlaceMenu,
    })

    const calendario = JSON.parse(enlaceMenu.chCalendario);
    this.initService.setChCalendarioValue(calendario);

    /* if (!this.productosService.datosInitValue) {
      this.productosService.setDatosInitValue({
        empresa: this.config.getProyecto(),
        m: enlaceMenu.chNombre,
        gi: producto.GrupoInternetPsellId,
      });
    } */

    const datosUrl = JSON.parse(this.productosService.datosInitValue);
    const enlaceMenuNombre = this.productosService.getEnlaceMenuNombre(
      enlaceMenu.pkid
    );
    this.productosService.setDatosInitValue({
      empresa: datosUrl.empresa,
      m: enlaceMenuNombre,
      gi: producto.GrupoInternetPsellId,
      userId: JSON.parse(this.productosService.datosInitValue).userId,
      lg: datosUrl.lg,
      u: JSON.parse(this.productosService.datosInitValue).u,
      p: JSON.parse(this.productosService.datosInitValue).p,
    });
    this.calendarService.setFiltros(false);
    sessionStorage.setItem(
      "token",
      this.initService.funcionEncript(this.productosService.datosInitValue)
    );
    this.productosService
      .getProductos()
      .pipe(
        map((res) => {
          if (res && res.DictTarifasEntradas) {
            this.productosService.setInfoGrupoValue(
              res.DictTarifasEntradas[
                producto.GrupoInternetPsellId
              ]
            );

            this.productosService.setnombreGrupoValue(
              res.DictTarifasEntradas[
                producto.GrupoInternetPsellId
              ].NombreGrupo
            );
            
            this.productosService.setProductosValue(
              res.DictTarifasEntradas[
                producto.GrupoInternetPsellId
              ].DictTarifasProductos,

              producto.EnlaceMenuPSell,

              parseInt(producto.GrupoInternetPsellId, 10),

              res.DictTarifasEntradas[
                producto.GrupoInternetPsellId
              ].TipoReservaId,

              res.DictTarifasEntradas[
                producto.GrupoInternetPsellId
              ].NombreGrupo
            );
          }
        })
      )
      .pipe(take(1))
      .subscribe(() => {
        this.productosService.setEspectaculoValue("");
        this.productosService.setCargandoValue(false);
        this.router.navigate(["/tienda/producto/1"]);
      });
  }

  private loadProducts() {
    if (this.ventaService.carritoValue.compras.length !== 0) {
      this.productosService
        .getInterestProducts()
        .pipe(
          map((productos) => {
            if (productos) {
              return Object.values(productos);
            } else {
              return [];
            }
          })
        )
        .pipe(
          map((productos) => {
            this.productos = productos;
            return this.productos;
          })
        )
        .pipe(take(1))
        .subscribe((prod: any) => {
          this.productosService.setProductosIntValue(prod);
          this.prodRel = this.productosService.productosInt$.pipe(
            map((productos) => Object.values(productos))
          );
          this.prodRel
            .pipe(first())
            .subscribe((producto) => this.productosRelacionados.push(producto));
        });
    }
  }

  private loadProductsGI() {
    if (this.ventaService.carritoValue.compras.length !== 0) {
      this.productosService
        .getInterestProducts()
        .pipe(
          map((productos) => {
            if (productos) {
              return Object.values(productos);
            } else {
              return [];
            }
          })
        )
        .pipe(
          map((productos) => {
            this.productos = productos.filter(
              (p: any, i, arr) =>
                arr.findIndex(
                  (t: any) => t.GrupoInternetPsellId === p.GrupoInternetPsellId
                ) === i
            );
            this.productos.map((p) => {
              p.NombreProducto = p.GrupoInternetPsellNombre;
            });
            return this.productos;
          })
        )
        .pipe(take(1))
        .subscribe((prod: any) => {
          this.productosService.setProductosIntValue(prod);
          this.prodRel = this.productosService.productosInt$.pipe(
            map((productos) => Object.values(productos))
          );
          this.prodRel
            .pipe(first())
            .subscribe((producto) => this.productosRelacionados.push(producto));
        });
    }
  }

  ir() {
    this.productosService.setTodosProductosCat(null);
    this.productosService.setProductosCat(null);
    this.productosService.setBackupProds(null);
    this.productosService.setTodosEspectaculosCat(null);
    this.productosService.setEspectaculosCat(null);
    this.productosService.setBackupEspectaculos(null);
    this.butacasService.addMore = true;
    this.ventaService.setCompra(new Compra());
    this.productosService.setVentaEspectaculo(false);
    this.productosService.setEspectaculoValue("");
    this.ventaService.setModificarValue(false);
    this.butacasService.setAsientosDeshablitados([]);
    this.ventaService.setNumCarrito();
    let enlaceMenu = this.productosService.getEnlaceMenuId();
    if (enlaceMenu && enlaceMenu.iPadre) {
      this.router.navigate(["/tienda/productos"], {
        queryParams: { iPadre: enlaceMenu.iPadre },
      });
      //this.router.navigate(['/tienda/productos']);
    } else {
      this.router.navigate(["/tienda/productos"]);
    }
  }

  onImgError(event) {
    event.target.src =
      "assets/img/" + this.proyecto + "/catalog/default_catalog.png";
    //Do other stuff with the event.target
  }

  getDivisasValue() {
    return this.configurationService.divisasValue;
  }
}

import { Injectable } from "@angular/core";
import { AppConfiguration } from "src/app/app.configuration";
import { HttpClient } from "@angular/common/http";
import { map, mergeMap, retry, take } from "rxjs/operators";
import { TokenManagerService } from "../token/token-manager.service";
import { ProductosService } from "src/app/business/productos.service";
import { VentaService } from "src/app/core/services/venta.service";
import { Router } from "@angular/router";
import { ConfigurationService } from "../config/configuration.service";
import { BehaviorSubject, Observable, of } from "rxjs";
import { PaymentService } from "../payment/payment.service";
import { TranslateService } from "src/app/shared/translate.service";
import { TokenInit } from "src/app/models/token-init.model";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { MatDialog } from "@angular/material/dialog";
import { DialogComponent } from "src/app/shared/dialogs/dialog/dialog.component";
import { ModalService } from "src/app/shared/modal.service";
import { ButacasService } from "src/app/business/espectaculos-butacas/mapa/servicios/butacas.service";
// import { ButacasService } from 'src/app/shared/components/selector-butacas/servicios/butacas.service';

@Injectable({
  providedIn: "root",
})
export class InitService {
  private chCalendarioSubject: BehaviorSubject<any>;
  public chCalendario$: Observable<any>;

  private grupoProductosSubject: BehaviorSubject<any>;
  public grupoProductos$: Observable<any>;

  private cargadoSubject: BehaviorSubject<any>;
  public cargado$: Observable<any>;

  private aceptaCookieGoogleSubject: BehaviorSubject<any>;
  public aceptaCookieGoolge$: Observable<any>;

  private idiomasSubject: BehaviorSubject<any>;
  public idiomas$: Observable<any>;

  private startedSubject: BehaviorSubject<any>;
  public started$: Observable<any>;

  private cookiesSubject: BehaviorSubject<any>;
  public cookies$: Observable<any>;

  private modalCookiesSubject: BehaviorSubject<any>;
  public modalCookies$: Observable<any>;

  private fromAdmonSubject: BehaviorSubject<any>;
  public fromAdmon$: Observable<any>;

  proyecto = this.config.getProyecto();

  lang: string;

  constructor(
    private config: AppConfiguration,
    private tokenManager: TokenManagerService,
    private http: HttpClient,
    private productosService: ProductosService,
    private ventaService: VentaService,
    private router: Router,
    private configurationService: ConfigurationService,
    private paymentService: PaymentService,
    private translateService: TranslateService,
    private toast: ToastrService,
    private dialog: MatDialog,
    private modalService: ModalService,
    private butacasService: ButacasService
  ) {

    this.proyecto = this.config.getProyecto();

    this.cookiesSubject = new BehaviorSubject<any>(undefined);
    this.cookies$ = this.cookiesSubject.asObservable();

    this.idiomasSubject = new BehaviorSubject<any>(undefined);
    this.idiomas$ = this.idiomasSubject.asObservable();

    this.chCalendarioSubject = new BehaviorSubject<any>(undefined);
    this.chCalendario$ = this.chCalendarioSubject.asObservable();

    this.grupoProductosSubject = new BehaviorSubject<any>(undefined);
    this.grupoProductos$ = this.grupoProductosSubject.asObservable();

    this.cargadoSubject = new BehaviorSubject<any>(undefined);
    this.cargado$ = this.cargadoSubject.asObservable();

    this.modalCookiesSubject = new BehaviorSubject<any>(false);
    this.modalCookies$ = this.modalCookiesSubject.asObservable();

    this.fromAdmonSubject = new BehaviorSubject<any>(false);
    this.fromAdmon$ = this.fromAdmonSubject.asObservable();

    this.aceptaCookieGoogleSubject = new BehaviorSubject<any>(false);
    this.aceptaCookieGoolge$ = this.aceptaCookieGoogleSubject.asObservable();

    this.startedSubject = new BehaviorSubject<any>(false);
    this.started$ = this.startedSubject.asObservable();
  }
  public get cookiesValue() {
    return this.cookiesSubject.value;
  }

  setCookies(cookies) {
    const mapCookies = [];
    cookies.forEach((cookie) => {
      const mapCookie = {
        ...cookie,
        aceptar: cookie.bNecesario === "1" ? true : false,
      };
      mapCookies.push(mapCookie);
    });
    this.cookiesSubject.next(mapCookies);
  }

  public get idiomasValue() {
    return this.idiomasSubject.value;
  }

  setStarted(isStarted: boolean) {
    this.startedSubject.next(isStarted);
  }

  public get startedValue() {
    return this.startedSubject.value;
  }

  setIdiomas(idiomas) {
    this.idiomasSubject.next(idiomas);
  }

  public get aceptaCookieGoolgeValue() {
    return this.aceptaCookieGoogleSubject.value;
  }

  setAceptaCookieGoolge(confirmado) {
    this.aceptaCookieGoogleSubject.next(confirmado);
  }

  public get modalCookiesValue() {
    return this.modalCookiesSubject.value;
  }

  setModalCookiesValue(show) {
    this.modalCookiesSubject.next(show);
  }

  public get FromAdmonValue() {
    return this.fromAdmonSubject.value;
  }

  setFromAdmonValue(fromadm) {
    this.fromAdmonSubject.next(fromadm);
  }

  public get cargadoValue() {
    return this.cargadoSubject.value;
  }

  setCargadoValue(confirmado) {
    this.cargadoSubject.next(confirmado);
  }

  public get chCalendarioValue() {
    return this.chCalendarioSubject.value;
  }

  setChCalendarioValue(nombre) {
    this.chCalendarioSubject.next(nombre);
  }

  public get grupoProductosValue() {
    return this.grupoProductosSubject.value;
  }

  setGrupoProductosValue(nombre) {
    this.grupoProductosSubject.next(nombre);
  }

  redireccionar(linkRetorno) {
    window.location.href = linkRetorno;
  }

  funcionEncript(json) {
    return this.tokenManager.funcionEncript(json);
  }

  funcionDesencript(token) {
    return this.tokenManager.funcionDesencript(token);
  }

  // funcionEncript(json) {
  //   const clavePrivada = '3ñdlfjdfksdffffs%i2343424kjfdsü&/@#$A·';
  //   let final = '';
  //   for (let index = 1; index <= json.length; index++) {
  //     const tokenMio = this.Mid(json, index, 1);
  //     const codigo = this.Mid(clavePrivada, ((index - 1) % clavePrivada.length) + 1, 1);
  //     // tslint:disable-next-line:no-bitwise
  //     const a = (this.Asc(codigo) ^ this.Asc(tokenMio)).toString(16);
  //     final = final + this.Right('0' + a, 2);
  //   }
  //   return final;
  // }

  // funcionDesencript(token) {
  //   const clavePrivada = '3ñdlfjdfksdffffs%i2343424kjfdsü&/@#$A·';
  //   let final = '';
  //   let j = 1;
  //   for (let index = 1; index <= token.length; index += 2) {
  //     const tokenMio = this.Mid(token, index, 2);
  //     const codigo = this.Mid(clavePrivada, ((j - 1) % clavePrivada.length) + 1, 1);
  //     // tslint:disable-next-line:no-bitwise
  //     final = final + this.Chr(this.Asc(codigo) ^ (parseInt('0x' + tokenMio, 16)));
  //     j = j + 1;
  //   }
  //   return final;
  // }

  // Right(str, n) {
  //   if (n <= 0) {
  //     return '';
  //   } else if (n > String(str).length) {
  //     return str;
  //   } else {
  //     const iLen = String(str).length;
  //     return String(str).substring(iLen, iLen - n);
  //   }
  // }

  // val(str) {
  //   if (!isNaN(parseInt(str, 10))) {
  //     str = parseInt(str, 10);
  //     return str;
  //   } else if (isNaN(parseInt(str, 10))) {
  //     str = parseInt(str, 10);
  //     if (isNaN(str)) {
  //       return 0;
  //     } else if (!isNaN(str)) {
  //       return str;
  //     }
  //   }
  // }

  // Mid(strMid, intBeg, intEnd) {
  //   if (strMid === null || strMid === '' || intBeg < 0) {
  //     return '';
  //   }
  //   intBeg -= 1;
  //   if (intEnd === null || intEnd === '') {
  //     return strMid.substr(intBeg);
  //   } else {
  //     return strMid.substr(intBeg, intEnd);
  //   }
  // }

  // Chr(num) {
  //   const res = String.fromCharCode(num);
  //   return res;
  // }

  // Asc(str) {
  //   return str.charCodeAt(0);
  // }

  traerDatosInicio(cancelarAforos: boolean = true) {
    this.getInitData()
      .pipe(
        retry(3),
        mergeMap(() =>
          this.productosService.getProductos().pipe(
            map((res) => {
              if (!res) {
                return of(null);
              }
              if (
                res.DictTarifasEntradas == null ||
                res.DictTarifasEntradas == undefined ||
                Object.keys(res.DictTarifasEntradas).length == 0
              ) {
                /* this.toast.error(this.translateService.data.ERRORRESERVAENTRADAS, 'Error', {
                timeOut: 5000,
                positionClass: 'toast-top-right',
              }); */
                //this.modalService.open("dialog");
                return res;
              } else {
                this.productosService.setnombreGrupoValue(
                  res.DictTarifasEntradas[
                    this.productosService.getGrupoInternet().gi
                  ].NombreGrupo
                );
                this.productosService.setProductosValue(
                  res.DictTarifasEntradas[
                    this.productosService.getGrupoInternet().gi
                  ].DictTarifasProductos,
                  res.DictTarifasEntradas[
                    this.productosService.getGrupoInternet().gi
                  ].EnlaceMenuId,
                  this.productosService.getGrupoInternet().gi,
                  res.DictTarifasEntradas[
                    this.productosService.getGrupoInternet().gi
                  ].TipoReservaId,
                  res.DictTarifasEntradas[
                    this.productosService.getGrupoInternet().gi
                  ].NombreGrupo
                );
                this.productosService.setInfoGrupoValue(
                  res.DictTarifasEntradas[
                    this.productosService.getGrupoInternet().gi
                  ]
                );
                return res;
              }
            })
          )
        )
      )
      .pipe(
        mergeMap((res) => {
          return this.productosService.getEspectaculos("TODOS").pipe(
            map((espectaculo) => {
              if (espectaculo) {
                res.espectaculo = espectaculo;
                if (this.productosService.datosInitValue) {
                  let datos = JSON.parse(this.productosService.datosInitValue);
                  const valor = espectaculo.EspectaculosDisponible.find(
                    (x) =>
                      x.EspectaculosId == datos.espectaculoId &&
                      x.EnlaceMenuId ==
                        this.productosService.getEnlaceMenuPorNombre(datos.m) &&
                      x.GrupoInternetId == datos.gi
                  );

                  if (valor) {
                    this.productosService.setnombreGrupoValue(
                      valor.NombreGrupo
                    );
                    this.productosService.setEspectaculoValue(valor);
                    this.productosService.setVentaEspectaculo(true);
                    // this.productosService.setEspectaculosValue(
                    //     res.EspectaculosDisponible,
                    //     espectaculo.EnlaceMenuId,
                    //     espectaculo.grupoInternetId,
                    //     espectaculo.TipoReservaId,
                    //     espectaculo.NombreGrupo);
                    this.productosService.setInfoGrupoValue(valor);
                  }
                }
              }
              return res;
            })
          );
        })
      )
      .pipe(take(1))
      .subscribe((result) => {
        if (!result) {
          this.modalService.open("dialog");
        }
        this.setModalCookiesValue(true);
        const enlaceMenu = this.productosService.getEnlaceMenuId();
        this.setOpcionesSnowzone(enlaceMenu);
        if (enlaceMenu && enlaceMenu.chCalendario) {
          const calendario = JSON.parse(enlaceMenu.chCalendario);
          this.setChCalendarioValue(calendario);
        }
        if (enlaceMenu) {
          const botones = enlaceMenu.chBotones.split(",")[0];
          this.paymentService.setAdmin(botones);
        }

        if (this.ventaService.carritoValue.compras.length === 0) {
          this.ventaService.cargarCesta();
          this.ventaService.cargarFechaSeleccionadasSinSesion();
        }
        this.butacasService.getEstadosButacasInicial(this.lang);
        let datos = JSON.parse(this.productosService.datosInitValue);

        if (
          (this.productosService.ventaEspectaculoValue &&
            this.router.url.split("/")[2] === "espectaculo") ||
          (this.productosService.ventaEspectaculoValue &&
            this.router.url.split("/")[1].startsWith("reservarEntradas?"))
        ) {
          this.router.navigate(["/tienda/espectaculo/1"]);
        } else if (
          this.router.url.split("/")[2] === "producto" ||
          this.router.url.split("/")[1].startsWith("reservarEntradas?")
        ) {
          this.router.navigate(["/tienda/producto/1"]);
        } else if (
          this.router.url.split("/")[1].startsWith("reservarEntradas")
        ) {
          this.router.navigate(["/tienda/productos"]);
        }
        if (cancelarAforos) {
          this.productosService.cancelarAforoInit().pipe(take(1)).subscribe();
          this.butacasService.DesmarcarTodoCarrito().pipe(take(1)).subscribe();
        }
      });
  }

  getInitData() {
    this.setStarted(true);
    if (
      sessionStorage.getItem("token") &&
      !this.productosService.datosInitValue
    ) {
      const token: TokenInit = JSON.parse(
        this.funcionDesencript(sessionStorage.getItem("token"))
      );
      this.productosService.setDatosInitValue(token);
    }
    const traerConexionPrivadaBody = {
      chClaveEmpresa: this.config.getConfig("chClaveEmpresa"),
      chNombreProyecto: this.config.getConfig("chNombreProyecto"),
      chEntornoConexion: this.config.getConfig("chEntornoConexion"),
      chOrigenConexion: this.config.getConfig("chOrigenConexion"),
    };
    const traerProyectoBody = {
      chClaveEmpresa: this.config.getConfig("chClaveEmpresa"),
      chNombreProyecto: this.config.getConfig("chNombreProyecto"),
    };
    return this.http
      .post(
        `${this.config.getConfig("API_URL")}api/TraerConexionPrivada`,
        traerConexionPrivadaBody
      )
      .pipe(
        mergeMap((res: any) => {
          this.tokenManager.setCurrentConexionIdValue(res.pkid);
          if (this.tokenManager.currentTokenValue) {
            return this.tokenManager.currentToken$.pipe(
              map((currentSession) => {
                res.currentSession = currentSession;
                return res;
              })
            );
          } else {
            return this.tokenManager
              .login(this.config.getConfig("numSerie"))
              .pipe(
                map((currentSession) => {
                  res.currentSession = currentSession;
                  this.configurationService.setConfigurationValue(res);
                  return res;
                })
              );
          }
        })
      )
      .pipe(
        mergeMap((res: any) =>
          this.http
            .post(
              `${this.config.getConfig("API_URL")}api/TraerProyectoPrivada`,
              traerProyectoBody
            )
            .pipe(
              map((proyecto: any) => {
                res.datosProyecto = proyecto;
                this.translateService.setLangDefault(proyecto.chIdioma);
                const caracteristicasAdicionales = JSON.parse(proyecto.CaracteristicasAdicionales);
                const caracteristicas = caracteristicasAdicionales.CaracteristicasAdicionales;
                this.modalService.setCookiesExterno(caracteristicas.CookiesExterno);
                
                return res;
            })
            )
        )
      )
      .pipe(
        mergeMap((res: any) =>
          this.http
            .post(
              `${this.config.getConfig(
                "API_URL"
              )}api/TraerEnlacesMenuPorConexionId`,
              { iConexionId: res.pkid }
            )
            .pipe(
              map((enlacesMenu: any) => {
                this.productosService.setEnlacesMenuValue(
                  enlacesMenu.LstEnlacesMenu
                );
                return res;
              })
            )
        )
      )
      .pipe(
        mergeMap((res: any) =>
          this.http
            .post(
              `${this.config.getConfig(
                "API_URL"
              )}api/Configuracion/TraerDatosInicioInternet`,
              { IdSesion: "0" }
            )
            .pipe(
              map((datosInicio: any) => {
                if (datosInicio.DatosResult) {
                  res.datosInicio = datosInicio.DatosResult;
                  this.configurationService.setDivisasISOValue(
                    datosInicio.DatosResult
                  );
                  if (this.productosService.datosInitValue) {
                    if (JSON.parse(this.productosService.datosInitValue).lg) {
                      const lang = JSON.parse(
                        this.productosService.datosInitValue
                      ).lg;
                      res.datosInicio.Idioma0 = lang;
                      this.translateService.setLocale(lang);
                      this.translateService.use(
                        lang,
                        this.config.getProyecto()
                      );
                    }
                  } else {
                    if (sessionStorage.getItem("admin")) {
                      if (JSON.parse(sessionStorage.getItem("admin")).lg) {
                        const lang = JSON.parse(
                          sessionStorage.getItem("admin")
                        ).lg;
                        this.translateService.setLocale(lang);
                      }
                    }
                    res.datosInicio.Idioma0 = this.translateService.getLocale();

                    // let lang = this.translateService.getLocale();
                    // if (lang === 'en') {
                    //   lang = 'en-GB';
                    // } else if (lang === 'es') {
                    //   lang = 'es-ES';
                    // } else if (lang === 'ca') {
                    //   lang = 'ca';
                    // } else {
                    //   lang = 'es-ES';
                    // }
                    // res.datosInicio.Idioma0 = lang;
                    this.translateService.use(
                      this.translateService.getLocale(),
                      this.config.getProyecto()
                    );
                  }
                  this.configurationService.setConfigurationValue(res);
                  if (this.proyecto === "MAPFRE") {
                    this.translateService.changeLanguageCookieOneTrust(
                      this.translateService.getLocale()
                    )
                  }
                  this.setCargadoValue(true);
                  return res;
                } else {
                  this.tokenManager.logout();
                  this.redireccionar("/");
                }
              })
            )
        )
      )
      .pipe(mergeMap(() => this.getCookies()))
      .pipe(mergeMap(() => this.configurationService.getDivisas()))
      .pipe(mergeMap(() => this.ventaService.getClienteReserva()))
      .pipe(mergeMap(() => this.getIdiomas()));
  }

  getCookies() {
    const body = {
      Idioma: this.configurationService.configurationValue.datosInicio.Idioma0,
    };
    return this.http
      .post(
        `${this.config.getConfig("API_URL")}/api/Configuracion/GetCookies`,
        body
      )
      .pipe(
        map((res: any) => {
          if (res.DatosResult) {
            this.setCookies(res.DatosResult.ListadoCookies);
          }
          return res;
        })
      );
  }

  getIdiomas() {
    const body = {
      Nombre: "",
      Codigo: "",
      Activo: "1",
      Usuario: "IACPOS",
      Password: "",
    };
    return this.http
      .post(
        `${this.config.getConfig("API_URL")}/api/Customan/ObtenerIdiomas`,
        body
      )
      .pipe(
        map((res: any) => {
          if (res.DatosResult.ListaIdiomas.length >= 1) {
            res.DatosResult.ListaIdiomas.reverse();
            this.setIdiomas(res.DatosResult.ListaIdiomas);
          }
          return res;
        })
      );
  }

  setOpcionesSnowzone(enlaceMenu) {
    if (this.config.getProyecto() === "SNOWZONE") {
      const emHeader = this.getEmHeader(enlaceMenu);
      this.productosService.setFiltroTipoValue("");
      this.productosService.setFiltroGrupoValue("");
      this.productosService.setFiltroNivelValue("");
      this.setOtrosProductos(enlaceMenu);
      this.productosService
        .getTodosProductos(emHeader)
        .pipe(take(1))
        .subscribe((res) =>
          this.productosService.setProductosHeaderValue(
            res.DictTarifasEntradas &&
              Object.values(res.DictTarifasEntradas).length > 0
              ? Object.values(res.DictTarifasEntradas)
              : res.ListTarifasEntradas
          )
        );
    }
  }

  getEmHeader(enlaceMenu) {
    let emHeader;
    if (
      enlaceMenu.pkid === "498" ||
      enlaceMenu.pkid === "487" ||
      enlaceMenu.pkid === "1402" ||
      enlaceMenu.pkid === "383" ||
      enlaceMenu.pkid === "384" ||
      enlaceMenu.pkid === "385" ||
      enlaceMenu.pkid === "394"
    ) {
      emHeader = "383, 384, 385, 394, 1402, 487, 498";
      this.setGrupoProductosValue("ONLINE");
    } else if (enlaceMenu.pkid === "393" || enlaceMenu.pkid === "396") {
      emHeader = "393, 396";
      this.setGrupoProductosValue("COLEGIOS");
    } else if (enlaceMenu.pkid === "390" || enlaceMenu.pkid === "389") {
      emHeader = "390, 389";
      this.setGrupoProductosValue("EMPRESAS");
    } else if (enlaceMenu.pkid === "398" || enlaceMenu.pkid === "399") {
      emHeader = "398, 399";
      this.setGrupoProductosValue("CLUBES");
    } else if (
      enlaceMenu.pkid === "499" ||
      enlaceMenu.pkid === "488" ||
      enlaceMenu.pkid === "430" ||
      enlaceMenu.pkid === "431" ||
      enlaceMenu.pkid === "432" ||
      enlaceMenu.pkid === "433" ||
      enlaceMenu.pkid === "395"
    ) {
      emHeader = "430, 431, 432, 433, 395, 488, 499";
      this.setGrupoProductosValue("ADMIN");
    }
    return emHeader;
  }

  setOtrosProductos(enlaceMenu) {
    if (
      this.productosService.getGrupoInternet().gi === "7" &&
      enlaceMenu.chNombre === "SKI_SNOW"
    ) {
      this.productosService.setOtrosProductosValue("EQUIPO");
    } else if (
      this.productosService.getGrupoInternet().gi === "70" &&
      enlaceMenu.chNombre === "COLEGIOS ACTIVIDADES"
    ) {
      this.productosService.setOtrosProductosValue("EQUIPO");
    } else if (
      this.productosService.getGrupoInternet().gi === "56" &&
      enlaceMenu.chNombre === "EMPRESAS ACTIVIDADES"
    ) {
      this.productosService.setOtrosProductosValue("EQUIPO");
    } else if (
      (this.productosService.getGrupoInternet().gi === "75" &&
        enlaceMenu.chNombre === "CLUBES ACTIVIDADES") ||
      (this.productosService.getGrupoInternet().gi === "81" &&
        enlaceMenu.chNombre === "CLUBES ACTIVIDADES")
    ) {
      this.productosService.setOtrosProductosValue("EQUIPO");
    } else if (
      this.productosService.getGrupoInternet().gi === "7" &&
      enlaceMenu.chNombre === "PARTICULARES ACTIVIDADES"
    ) {
      this.productosService.setOtrosProductosValue("EQUIPO");
    } else {
      this.productosService.setOtrosProductosValue(enlaceMenu.chNombre);
    }
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Butaca } from '../business/espectaculos-butacas/modelos/butaca.model';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private modals: any[] = [];
  butacas: Butaca[];
  info: any;

	public _cookiesExterno: string;

	setCookiesExterno(value: string) {
		this._cookiesExterno = value;
	}

  getCookiesExterno(): string {
		return this._cookiesExterno;
	}

  private confirmadoSubject: BehaviorSubject<any>;
  public confirmado$: Observable<any>;

  constructor() {
    this.confirmadoSubject = new BehaviorSubject<any>(undefined);
    this.confirmado$ = this.confirmadoSubject.asObservable();
  }

  public get confirmadoValue() {
    return this.confirmadoSubject.value;
  }
  setConfirmadoValue(confirmado) {
    this.confirmadoSubject.next(confirmado);
  }

  add(modal: any) {
    this.modals.push(modal);
  }

  remove(id: string) {
    this.modals = this.modals.filter(x => x.id !== id);
  }

  open(id: string, flag?: boolean,info?: any, butacas?: Butaca[]) {
    const modal: any = this.modals.filter(x => x.id === id)[0];
    if (info) {
      this.info = info;
    }
    if (butacas) {
      this.butacas = butacas;
    }
    if(flag){
      modal.open(flag);
    }else{
      modal.open();
    }
  }

  moreInfo(id:string, pedido){
    const modal: any = this.modals.filter(x => x.id === id)[0];
    modal.moreInfo(pedido);
  }

  close(id: string) {
    const modal: any = this.modals.filter(x => x.id === id)[0];
    modal.close();
  }

  forgetPassword(id: string,flag) {
    const modal: any = this.modals.filter(x => x.id === id)[0];
    modal.forgetPassword(flag);
  }

  exists(id: string) {
    return this.modals.filter(x => x.id === id)[0];
  }

  isOpen(id: string) {
    const modal = this.modals.filter(x => x.id === id)[0];
    return modal.alive;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ErrorComponent } from './error/error.component';
import { SharedModule } from '../shared/shared.module';

import { TimerDirective } from './timer/timer.directive';

@NgModule({
  declarations: [
    ErrorPageComponent, 
    ErrorComponent,    
    TimerDirective, 
  ],
  imports: [
    CommonModule,
    SharedModule
  
  ],
  providers: [
    TimerDirective
  ],
  exports: [ErrorPageComponent]
})
export class CoreModule { }

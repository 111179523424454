import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppConfiguration } from "src/app/app.configuration";
import { ModalService } from "src/app/shared/modal.service";
import { CarritoComponent } from "../carrito/carrito.component";
import { VentaService } from "src/app/core/services/venta.service";
import { TimerService } from "src/app/core/services/timer.service";
import { ButacasService } from "src/app/business/espectaculos-butacas/mapa/servicios/butacas.service";
import { take } from "rxjs/operators";
import { ProductosService } from "src/app/business/productos.service";

@Component({
  selector: "app-modal-cancelar",
  templateUrl: "./modal-cancelar.component.html",
  styleUrls: ["./modal-cancelar.component.scss"],
})
export class ModalCancelarComponent implements OnInit {
  @Input() id: string;
  private element: any;

  butaca: any;
  posicionVenta: any;
  proyecto = this.config.getProyecto();

  constructor(
    public router: Router,
    private modalService: ModalService,
    private el: ElementRef,
    private config: AppConfiguration,
    private carrito: CarritoComponent,
    private ventaService: VentaService,
    private timerService: TimerService,
    private butacasService: ButacasService,
    private productosService: ProductosService
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    const modal = this;

    if (!this.id) {
      return;
    }

    document.body.appendChild(this.element);

    this.element.addEventListener("click", function (e: any) {
      if (e.target.className === "modalCarrito") {
        modal.close();
      }
    });
    this.modalService.add(this);
  }

  open(): void {
    this.element.style.display = "block";
    // document.body.classList.add("selectorNONumerado");
    // this.butaca = this.modalService.info;
  }

  // remove self from modal service when directive is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }
  close(): void {
    this.element.style.display = "none";
    // document.body.classList.remove("modalCarrito");
    // this.modalService.info=null
    // this.modalService.butacas=null
  }

  /**
   * @description Confirm drop all cart current
   * @return void
   */
  confirmDeleteReservation() {
    this.productosService.cancelarAforoCarrito().pipe(take(1)).subscribe();
    this.ventaService.setModificarValue(false);
    this.ventaService.setTraerReservaLoadedValue(false);
    this.ventaService.setIsReservaPagadaValue(false);
    this.ventaService.setFechaSeleccionadaSinSessionValue([]);
    sessionStorage.removeItem("estaPagada");
    this.butacasService
      .DesmarcarTodoCarrito()
      .pipe(take(1))
      .subscribe((res) => {
        this.butacasService.MarcarTodoCarrito().pipe(take(1)).subscribe();
      });
    this.timerService.reset();
    this.close();
  }
}

import { ChangeDetectorRef, EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import  * as data from 'src/app/shared/jsons/tables/tables.json';
import { Filter } from '../../classes/filters/filter';
import { table } from '../../classes/tables/table';
/* import { ConectarApiService } from 'src/app/shared/services/api/connection/conectar-api.service'; */
import { MatTableDataSource } from '@angular/material/table';
import { AlertService } from '../alerts/alert/alert.service';
import { LowerCasePipe } from '@angular/common';
import * as moment from 'moment';
import { GlobalService } from '../global/global.service';

import { Select_option } from '../../classes/tables/select_option';

import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
// import { MatPaginatorIntlEses } from '@otrosProveedoresV6/ES-es.paginator';
import { HttpErrorsService } from '../alerts/errors/http-errors.service';
import { SnackbarInfoService } from '../alerts/snackbar-info/snackbar-info.service';
import { Paginator } from '../../classes/paginator/paginator';
import { TranslateService } from '../../translate.service';
import { UserService } from 'src/app/user/user.service';





@Injectable({
  providedIn: 'root'
})



export class FilterService {
  respuestaAPI: any;
  table: BehaviorSubject<table>;
  dataSource: MatTableDataSource<any>;

  dataTable: any;
  dataTableFilter: BehaviorSubject<any> = new BehaviorSubject('');

  private ngUnsubscribe = new Subject();

  paginator: Paginator;
  filters: Filter[] = [];
  translation: any;

  grupos: any[] = [];

  private dataTableFilterSubject: BehaviorSubject<any>;
  public dataTableFilter$: Observable<any>;

  private tableSubject: BehaviorSubject<any>;
  public table$: Observable<any>;

  tableLoaded: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public servicioAPI: UserService,
    public alertaServicio: AlertService,
    public global: GlobalService,
    public translator: TranslateService,
    public httpErrorService: HttpErrorsService,
    public snackvar_service: SnackbarInfoService
  ) {

    this.inicitializeSubjectsAndObservable();

    this.translation = this.translator.data;
  }

  inicitializeSubjectsAndObservable() {

    this.dataTableFilterSubject = new BehaviorSubject<any>('');
    this.dataTableFilter$ = this.dataTableFilterSubject.asObservable();


    let l_table: table = { 'tableName': null, 'filterByApi': true, 'maxItems': 10, 'columns': null };

    this.tableSubject = new BehaviorSubject<table>(l_table);
    this.table$ = this.tableSubject.asObservable();


  }

  get dataTableFilterValue() {
    return this.dataTableFilterSubject.value;
  }

  public setdataTableFilter(dataTable) {
    this.dataTableFilterSubject.next(dataTable);
  }


  get tableValue() {
    return this.tableSubject.value;
  }

  public setTable(table) {
    this.tableSubject.next(table);
  }


  findTable(table) {
    this.filters = [];
    this.setTable(data.tables.find(data => data.tableName == table));

  }

  loadSelect(columnName, data, columnData, columnValue) {
    const col = this.tableValue.columns.find(find => find.name == columnName);
    const array = [];
    data.map(dataColumn => {
      const option = new Select_option();
      option.name = dataColumn[columnData];
      option.value = dataColumn[columnValue];
      array.push(option);

    });
    if (col.filterOptions) col.filterOptions.selectOptions = array;
  }



  changeTable(table: table) {
    this.setTable(table);
  }

  checkType(data: Filter) {
    return this.tableValue.columns.find(find => find.name == data.name).type;
  }

  filterInRquest() {
    const filterFind = this.filters.find(find => find.name == 'keyValue');
    if (filterFind != undefined) {
      this.filterByAll(filterFind);

    }
  }
  filterByString(data, elem: Filter) {
    if (data[elem.name] != undefined) {

      return data[elem.name].toLowerCase().includes(elem.value.toLowerCase());
    } else {
      return false;
    }
  }

  filterByNumber(data, elem: Filter) {
    if (data[elem.name] != undefined) {

      if (data[elem.name] == elem.value) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  filterBySelect(data, elem: Filter) {
    if (data[elem.name] != undefined) {

      if (data[elem.name] == elem.value) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  filterByRangeDate(data, elem: Filter) {
    if (data[elem.name] != undefined) {

      const dates = elem.value.split('-');
      const dateStart = moment(dates[0], 'DD/MM/YYYY');
      const dateEnd = moment(dates[1], 'DD/MM/YYYY');
      const dataToCompare = moment(data[elem.name], 'DD/MM/YYYY');


      if (dataToCompare.isBetween(dateStart, dateEnd)) {
        return true;
      } else {
        return false;

      }
    } else {
      return false;
    }
  }

  filterByDate(data, elem: Filter) {
    if (data[elem.name] != undefined) {

      const date = moment(elem.value, 'DD/MM/YYYY');
      const dataToCompare = moment(data[elem.name]);
      if (date == dataToCompare) {
        return true;
      } else {
        return false;

      }
    } else {
      return false;
    }
  }

  selectFunctionByType(type, data, elem) {
    let flag = false;
    switch (type) {
      case 'string':
        flag = this.filterByString(data, elem);
        break;
      case 'number':
        flag = this.filterByNumber(data, elem);
        break;

      case 'select':
        flag = this.filterBySelect(data, elem);
        break;
      case 'rangeDate':
        flag = this.filterByRangeDate(data, elem);
        break;
      case 'date':
        flag = this.filterByDate(data, elem);
        break;
      default:
        break;
    }
    return flag;
  }

  filterByAll(filter: Filter) {
    const filterIndex = this.filters.findIndex(find => find.name == 'keyValue');
    if (filterIndex != -1) {
      this.filters[filterIndex].value = filter.value;
    } else {
      this.filters.push(filter);
    }
    const filterdb = this.dataTableFilterValue.filter = filter.value;

  }
  filterByPkId(param){
    let filter=[{db: "iCodigoCliente",filterApi: "Codigo",name: "Codigo",show: param,translation: "CodigoCliente",value: param}]
    this.filter(this.tableValue.tableName,filter)
  }

  filter(tablename, filters: Filter[]) {

    if (this.tableValue.filterByApi) {
      this.filters = [];
      this.filters = [...filters];
      this.paginator.page = 1
      this.manageGetDataDB(tablename, this.paginator);
    } else {
      // filters  table
      let booleanArray = [];
      const data = this.dataTable.data.filter(data => {
        booleanArray = [];
        for (let i = 0; i < filters.length; i++) {
          let flag = false;

          const elem = filters[i];
          if (elem.name != 'keyValue') {
            const type = this.checkType(elem);
            flag = this.selectFunctionByType(type, data, elem);
            // this.filterByString(data,elem)
            booleanArray.push(flag);
          }
        }

        if (booleanArray.find(map => map == false) == false) {
          return false;
        } else {
          return true;
        }

      });
      this.setdataTableFilter(this.convertToMatTable(data));
    }
  }

  convertToMatTable(data) {
    return new MatTableDataSource(data);
  }

  manageFilterGetDataDB(tableName, parent_pkId?) {
    this.tableLoaded.emit(false);
    switch (tableName) {
      case 'operaciones':
        // this.cargarDatoTipoEntrada();
        break;
      default:
        break;
    }
  }


  loadData(data) {
    data["page"] = this.paginator.page
    this.dataTable = data;
   
    this.setdataTableFilter(this.dataTable);

  }

  assignDataByObj(obj, objData, columnName, columnNameData) {
    obj[columnName] = objData[columnNameData];


  }

  assignDataByValue(obj, columnName, data) {
    obj[columnName] = data;


  }


  manageGetDataDB(tableName, paginator: Paginator, parent_pkId?, downloadcsv?) {
    this.manageFilterGetDataDB(tableName);
   
    this.tableLoaded.emit(false);
    this.alertaServicio.limpiar();
    this.paginator = paginator;
    const filters = [...this.filters];
    filters.map(filter => {
      filter.name = filter.filterApi;
    });
    const filterIndex = filters.findIndex(find => find.name == 'keyValue');
    if (filterIndex != -1) {
      filters.splice(filterIndex, 1);
    }
    switch (tableName) {
      case 'operaciones':
        if (downloadcsv) {
          this.descargaDatosOperaciones(paginator, filters);
        } else {
          this.cargarDatosOperaciones(paginator, filters);
        }
        break;


      default:
        break;
    }
  }

  getPDF(tableName,row) {

    switch (tableName) {
      case 'operaciones':
         this.getPDFOperaciones(row);
        break;


      default:
        break;
    }
  }

  sendEmail(tableName,row) {

    switch (tableName) {
      case 'operaciones':
         this.emailOperaciones(row);
        break;


      default:
        break;
    }
  }

  anular(tableName,row) {

    switch (tableName) {
      case 'operaciones':
         this.anularOperaciones(row);
        break;


      default:
        break;
    }
  }


  getPDFOperaciones(row) {
    let currentuser=JSON.parse(sessionStorage.getItem('currentUser'));
    this.tableLoaded.emit(false);
    this.servicioAPI.getPDFOperacion(row.Localizador,0, currentuser.NombreCliente,currentuser.Contrasena).subscribe(gpdf => {
      this.tableLoaded.emit(true);
      if (gpdf.DatosResult) {
      const linkSource = 'data:application/pdf;base64,' + gpdf.DatosResult.PDF + '\n';
      const downloadLink = document.createElement('a');
      let fileName="operacion_"+row.Localizador+".pdf";
  
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      } else {
        this.snackvar_service.openSnackBar("Problema al generar PDF", "","red-snackbar");
      }
    });
  }


  emailOperaciones(row) {
    let currentuser=JSON.parse(sessionStorage.getItem('currentUser'));
    this.tableLoaded.emit(false);
    this.servicioAPI.getPDFOperacion(row.Localizador,1, currentuser.NombreCliente,currentuser.Contrasena ).subscribe(gpdf => {
      this.tableLoaded.emit(true);
    });
  }

  anularOperaciones(row) {
    this.tableLoaded.emit(false);
    let currentuser=JSON.parse(sessionStorage.getItem('currentUser'));
    this.servicioAPI.anularOperacion(row.Localizador, currentuser.NombreCliente,currentuser.Contrasena).subscribe(gpdf => {
      this.tableLoaded.emit(true);
    });
  }

  descargaDatosOperaciones(paginator, filters) {
    this.servicioAPI.getOperaciones(paginator, filters).subscribe(
      async data => {
        this.respuestaAPI = data;
        if (this.respuestaAPI.DatosResult != null) {
          //let OperacionesList: any;
          //OperacionesList = this.respuestaAPI.DatosResult.Operaciones;
          let TotalResultados = this.respuestaAPI.DatosResult.TotalResultados;
          const paginatortotal = {page: 1, pagelements: TotalResultados, orderby:"", ordertype:"ASC"};

          this.servicioAPI.getOperaciones(paginatortotal, filters).subscribe(
            async data => {
              this.respuestaAPI = data;
              if (this.respuestaAPI.DatosResult != null) {
                let OperacionesList: any;
                OperacionesList = this.respuestaAPI.DatosResult.Operaciones;
                this.irAlExcel(this.convertToCSV(OperacionesList),"descarga_operaciones.csv");
              }
              else {
      
              }
              this.tableLoaded.emit(true);
            },
            error => {
              this.httpErrorService.identificarErrores(error);
            }
          ); 
        }
        else {

        }
        //this.tableLoaded.emit(true);
      },
      error => {
        this.httpErrorService.identificarErrores(error);
      }
    ); 
  }


  convertToCSV(arr) {
    const array = [Object.keys(arr[0])].concat(arr)
  
    return array.map(it => {
      return Object.values(it).toString()
    }).join('\n')
  }

  
  irAlExcel(datosExcel,fileName) {
    const linkSource = 'data:text/csv;charset=utf-8,' + datosExcel + '\n';
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.target = '_blank';
    downloadLink.click();
  }

  cargarDatosOperaciones(paginator, filters) {
    this.servicioAPI.getOperaciones(paginator, filters).subscribe(
      async data => {
        this.respuestaAPI = data;
        if (this.respuestaAPI.DatosResult != null) {
          let OperacionesList: any;
          OperacionesList = this.respuestaAPI.DatosResult.Operaciones;

          await this.transFormDataOperacionesToShow(OperacionesList).then(data => {
            OperacionesList = data
          })

          this.dataSource = new MatTableDataSource(OperacionesList);
          this.dataSource["totalResultados"] = data.DatosResult.TotalResultados
          this.dataSource["pagelements"] = paginator.pagelements
          this.loadData(this.dataSource);
          this.filterInRquest()
        }
        else {
          if (this.respuestaAPI.Mensajes[0].DescripcionMensaje!="No se han encontrado datos")
            this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar',this.respuestaAPI.Mensajes[0].CodigoMensaje);
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
        }
        this.tableLoaded.emit(true);
      },
      error => {
        this.httpErrorService.identificarErrores(error);
      }
    ); 
  }

  async transFormDataOperacionesToShow(Operacion: any[]) {
    let results = []

    const listOperaciones: any[] = [];

    Operacion.map(value => {

      console.log({
        valueOperacion: value
      })

      let NewOperacion: any = {};
      this.assignDataByObj(NewOperacion, value, 'Localizador', 'Localizador');
      this.assignDataByObj(NewOperacion, value, 'NombreReserva', 'NombreReserva');
      this.assignDataByObj(NewOperacion, value, 'NombreCliente', 'NombreCliente');
      this.assignDataByObj(NewOperacion, value, 'NumEntradas', 'NumEntradas');
      this.assignDataByObj(NewOperacion, value, 'ImporteTotal', 'ImporteTotal');
      this.assignDataByObj(NewOperacion, value, 'Botones', 'Botones');
      this.assignDataByObj(NewOperacion, value, 'VentaAdmon', 'VentaAdmon');
      this.assignDataByObj(NewOperacion, value, 'Notas', 'Notas');

      const serieFactura = value?.SerieFactura || "";
      const numFactura = value?.NumFacturaId || "";
      this.assignDataByValue(
        NewOperacion,
        "Factura",
        `${serieFactura} - ${numFactura}`
      )

      this.assignDataByValue(NewOperacion, 'FechaVisita', moment(value.FechaVisita, 'DD/MM/YYYY').format('DD-MM-YYYY'));
      this.assignDataByValue(NewOperacion, 'FechaCreacion', moment(value.FechaCreacion, 'DD/MM/YYYY').format('DD-MM-YYYY'));
      this.assignDataByValue(NewOperacion, "HoraVisita", value.FechaVisita.split(" ")[1]);
      

      switch (value.Pagada) {
        case 'NO':
          this.assignDataByValue(NewOperacion, "Pagada", "No")
          break;
        case 'SI':
          this.assignDataByValue(NewOperacion, "Pagada", "Si")
          break;
      }

      switch (value.Anulada) {
        case 'NO':
          this.assignDataByValue(NewOperacion, "Anulada", "No")
          break;
        case 'SI':
          this.assignDataByValue(NewOperacion, "Anulada", "Si")
          break;
      }

      switch (value.Vendida) {
        case 'NO':
          this.assignDataByValue(NewOperacion, "Vendida", "No")
          break;
        case 'SI':
          this.assignDataByValue(NewOperacion, "Vendida", "Si")
          break;
      }

      listOperaciones.push(NewOperacion);
    });

    return listOperaciones;
  }



}




import { Pipe, PipeTransform } from '@angular/core';

export interface IDIOMASI {
  pkId: string;
  CodIdioma: string;
  Nombre: string;
  Activo: string;
  CentrosIds: string;
  CentrosNombres: string;
  CodIdiomaTrad: string
}

export type PROYECTO = 'FCATEDRALSANTIAGO' 
                        | 'BIELSA' 
                        | 'CMREBEI' 
                        | 'ESTEPONAEULEN' 
                        | 'FMCMP_Salamanca'
                        | 'FMCMP_Madrid'
                        | 'GRANADACARD'
                        | 'MAPFRE'
                        | 'MNDELTA'
                        | 'MNPIRINEUS'
                        | 'museocr'
                        | 'PEDRERA'
                        | 'REALESCUELA'
                        | 'SNOWZONE'
                        | 'TICKAMORE_UNITARIO'
                        | 'AYTO_ZGZ_FORO'
                        | 'AYTO_ZGZ_GARG'
                        | 'AYTO_ZGZ_GARG'
                        | 'AYTO_ZGZ_GARG'
                        | 'AYTO_ZGZ_GARG'

@Pipe({
  name: 'idiomasDelProyecto'
})
export class IdiomasDelProyectoPipe implements PipeTransform {

  transform(idiomas: IDIOMASI[], proyecto: string ): IDIOMASI[] {
    if (!idiomas?.length) return []

    return idiomas.filter((idioma) => {
      if (proyecto === 'FCATEDRALSANTIAGO') {
        const idiomasDelProyectoCatedralSantiago = ['en-GB', 'es-ES', 'gl-ES']
        return idiomasDelProyectoCatedralSantiago.includes(idioma['CodIdioma'])
      }

      // Here for other projects we could add some conditions
      return true;
    });
  }

}

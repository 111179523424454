import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from 'src/app/shared/translate.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  constructor(
    private translate: TranslateService,
    private router: Router) { }

  ngOnInit(): void {
  }

  tienepatrocinador() {

    // solo para la pagina inicial
    if (this.router.url !== '/tienda/productos')
      return false;

    return (this.translate.data.BANNER_PATROCINADOR !== undefined && this.translate.data.BANNER_PATROCINADOR !== "" );
  }
  
}

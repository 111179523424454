import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ElementRef,
	ChangeDetectorRef,
	AfterViewInit,
	Inject,
	LOCALE_ID,
	ViewChild,
	OnDestroy,
	ContentChild,
} from "@angular/core";
import * as moment from "moment";
import "moment/locale/es";
import "moment/locale/ca";
import "moment/min/locales";
import * as _ from "lodash";
import { CalendarService } from "./calendar.service";
import { VentaService } from "../../../../../core/services/venta.service";
import { ProductosService } from "src/app/business/productos.service";
import { map, take, takeUntil } from "rxjs/operators";
import {
	SesionService,
	Disponibilidad,
} from "src/app/core/services/sesion.service";
import { TranslateService } from "src/app/shared/translate.service";
import { FiltrosComponent } from "../filtros/filtros.component";
import { forkJoin, Subject } from "rxjs";
import { CalendarModalService } from "../../hour-selector/calendar-modal/calendar-modal.service";
import { Compra } from "src/app/models/compra.model";
import { ButacasService } from "src/app/business/espectaculos-butacas/mapa/servicios/butacas.service";

interface CalendarDate {
	mDate: moment.Moment;
	selected?: boolean;
	today?: boolean;
	enabled?: boolean;
	complete?: boolean;
	ultimas?: boolean;
	selectable?: boolean;
}

@Component({
	selector: "app-calendar",
	templateUrl: "./calendar.component.html",
	styleUrls: ["./calendar.component.scss"],
})
export class CalendarComponent
	implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
	private ngUnsubscribe = new Subject();
	@Input() id: string;
	private element: any;
	moment = moment();
	monthsFirstColumn = moment.months().splice(0, 6);
	monthsSecondColumn = moment.months().splice(6, 11);
	dayNames;
	monthNames;
	weeks;
	sortedDates: CalendarDate[] = [];
	productos$;
	sesiones;
	fechasDisponibilidad;
	productosDisponibles;
	loading;
	sinProducto;
	confirmado;
	enlaceMenu;
	grupoInternet;
	tipoReserva;
	oldFecha;
	dates;
	diasDisponibles;
	fechaSesionModificar;
	productos = [];
	sesionesCancelar = [];
	primeraSeleccionDia = 0;
	@Input() selectedDates: CalendarDate[] = [];
	// tslint:disable-next-line:no-output-on-prefix
	@Output() onSelectDate = new EventEmitter<CalendarDate>();
	giID: any;
	@ViewChild(FiltrosComponent, { read: FiltrosComponent })
	childFiltros: FiltrosComponent;

	constructor(
		@Inject(LOCALE_ID) protected localeId,
		private el: ElementRef,
		private ventaService: VentaService,
		private cd: ChangeDetectorRef,
		private calendarService: CalendarService,
		private productosService: ProductosService,
		private sesionService: SesionService,
		private translateService: TranslateService,
		private calendarModalService: CalendarModalService,
		private butacasService: ButacasService
	) {
		this.moment.locale(this.localeId);
		this.dayNames = moment.weekdays(true);
		this.monthNames = moment
			.months()
			.map((month) => month[0].toUpperCase() + month.substr(1));
		this.element = el.nativeElement;
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	ngOnInit() {
		this.productosService.cargandoComponente$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((carga) => (this.loading = carga));
		this.giID = this.productosService.getGrupoInternet().gi;
		let fecha;

    console.log({
      compraValue: this.ventaService.compraValue,
      modificarValue: this.ventaService.modificarValue,
      fechaFiltroProductosValue: this.productosService.fechaFiltroProductosValue,
    })
		if (this.ventaService.modificarValue) {
			if (
				this.ventaService.compraValue.productos &&
				this.ventaService.compraValue.productos[0] &&
				this.ventaService.compraValue.productos[0].producto.butacas &&
				this.ventaService.compraValue.productos[0].producto.butacas.length > 0
			) {
				fecha = moment(
					this.ventaService.compraValue.productos[0].producto.butacas[0]
						.FechaSesion,
					"YYYY/MM/DD"
				).format("DD/MM/YYYY");
			}
			if (
				this.ventaService.compraValue.productos &&
				this.ventaService.compraValue.productos[0] &&
				this.ventaService.compraValue.productos[0].sesionesSeleccionadas &&
				this.ventaService.compraValue.productos[0].sesionesSeleccionadas[0] &&
				this.ventaService.compraValue.productos[0].sesionesSeleccionadas[0]
					.Fecha
			) {
				fecha =
					this.ventaService.compraValue.productos[0].sesionesSeleccionadas[0]
						.Fecha;
			}
			if (
				this.ventaService.compraValue.productos &&
				this.ventaService.compraValue.productos[0] &&
				!this.ventaService.compraValue.productos[0].sesionesSeleccionadas &&
				this.ventaService.compraValue.productos[0].producto
					.ListTarifasfechasVigencia &&
				this.ventaService.compraValue.productos[0].producto
					.ListTarifasfechasVigencia[0] &&
				this.ventaService.compraValue.productos[0].producto
					.ListTarifasfechasVigencia[0].FechaInicioTarifa
			) {
				fecha = this.formatDate(
					this.ventaService.compraValue.productos[0].producto
						.ListTarifasfechasVigencia[0].FechaInicioTarifa
				);
			}
		} else if (this.productosService.fechaFiltroProductosValue) {
			fecha =
				this.productosService.fechaFiltroProductosValue.format("DD/MM/YYYY");
		} else {
			this.calendarService.currentDate = moment();
			this.calendarService.year = moment().year();
		}
		this.generateCalendar(0, fecha);
	}
	formatDate(date) {
		let dateFormat = moment(date, "DD/MM/YYYY h:mm:ss");
		return dateFormat.format("DD/MM/YYYY");
	}
	ngOnChanges(changes: SimpleChanges): void {
		if (
			changes.selectedDates &&
			changes.selectedDates.currentValue &&
			changes.selectedDates.currentValue.length > 1
		) {
			this.sortedDates = _.sortBy(
				changes.selectedDates.currentValue,
				(m: CalendarDate) => m.mDate.valueOf()
			);
			this.generateCalendar(1);
		}
	}

	ngAfterViewInit() {
		this.cd.detectChanges();
	}

	prevMonth(): void {
		if (
			moment(this.calendarService.currentDate)
				.subtract(1, "months")
				.isBefore(moment(), "month")
		) {
			return;
		}
		this.calendarService.currentDate = moment(
			this.calendarService.currentDate
		).subtract(1, "months");
		this.calendarService.selectedMonth = this.monthToString(
			this.calendarService.currentDate.month()
		);
		this.calendarService.selectedYear = this.calendarService.currentDate
			.year()
			.toString();
		if (this.monthToNumber(this.calendarService.selectedMonth) === "12") {
			this.calendarService.year--;
		}
		this.generateCalendar(1);
	}

	nextMonth(): void {
		if (
			this.sesionService.maxDiasVerVentaCalendario(
				this.productosService.getEnlaceMenuId(),
				this.calendarService.today,
				this.calendarService.currentDate
			)
		) {
			// if ((this.calendarService.today.month() + 4 > this.calendarService.currentDate.month())) {
			this.calendarService.currentDate = moment(
				this.calendarService.currentDate
			).add(1, "months");
			this.calendarService.selectedMonth = this.monthToString(
				this.calendarService.currentDate.month()
			);
			this.calendarService.selectedYear = this.calendarService.currentDate
				.year()
				.toString();
			if (this.monthToNumber(this.calendarService.selectedMonth) === "1") {
				this.calendarService.year++;
			}
			this.generateCalendar(1);
		}
	}

	generateCalendar(inicio, fecha?) {
		console.log({
			inicio,
			fecha,
		});

		this.productosService.setCargandoComponenteValue(true);
		let firstDayOfMonth = this.calendarService.currentDate
			.startOf("month")
			.format("YYYY/MM/DD");
		let lastDayOfMonth = this.calendarService.currentDate
			.endOf("month")
			.format("YYYY/MM/DD");

		if (fecha) {
			this.calendarService.currentDate = moment(fecha, "DD/MM/YYYY");
			firstDayOfMonth = this.calendarService.currentDate
				.startOf("month")
				.format("YYYY/MM/DD");
			lastDayOfMonth = this.calendarService.currentDate
				.endOf("month")
				.format("YYYY/MM/DD");

			this.pintarCalendario(fecha, firstDayOfMonth, lastDayOfMonth);
		} else if (inicio == 0) {
			const firstDay = moment(moment(), "DD/MM/YYYY").format("YYYY/MM/DD");
			let tiempo;
			switch (
				(
					this.productosService.getEnlaceMenuId().chTiempoVentaMaxTipo || ""
				).toLowerCase()
			) {
				case "meses":
					tiempo = "month";
					break;
				case "dias":
					tiempo = "days";
					break;
				default:
					tiempo = "month";
					break;
			}
			const lastDay = moment(moment(), "DD/MM/YYYY")
				.add(
					this.productosService.getEnlaceMenuId().iTiempoVentaMax || 12,
					tiempo
				)
				.format("YYYY/MM/DD");
			//manieva
			//si es la primera vez que carga, usar el mismo endpoint de visitas
			//para obtener la primera fecha disponible
			if (
				this.productosService.espectaculoValue &&
				this.productosService.mostrarProductosValue == true &&
				false
			) {
				return this.productosService
					.getButacasDisponibleFechas(
						this.productosService.espectaculoValue.EspectaculosId,
						this.productosService.espectaculoValue.GrupoRecintoId,
						firstDay,
						lastDay,
						"DIAS"
					)

					.subscribe((res: any) => {
						let estadoSesionesMensual;
						let estadoSesionesMensualPrimerDiaDisponible;
						if (res.DatosResult === null) {
							estadoSesionesMensual = [];
						} else {
							estadoSesionesMensual = res.DatosResult.ButacasDisponibleFecha;
							estadoSesionesMensualPrimerDiaDisponible =
								res.DatosResult.ButacasDisponibleFecha.filter(
									(es) => !(es.Estado === 3)
								);
							if (estadoSesionesMensual && estadoSesionesMensual.length > 0) {
								this.calendarService.currentDate = moment(
									estadoSesionesMensualPrimerDiaDisponible[0].DiaDeSesion,
									"DD/MM/YYYY HH:mm:ss"
								);
								firstDayOfMonth = this.calendarService.currentDate
									.startOf("month")
									.format("YYYY/MM/DD");
								lastDayOfMonth = this.calendarService.currentDate
									.endOf("month")
									.format("YYYY/MM/DD");
								fecha = moment(
									estadoSesionesMensualPrimerDiaDisponible[0].DiaDeSesion,
									"DD/MM/YYYY HH:mm:ss"
								).format("DD/MM/YYYY");
							}
						}
						this.pintarCalendario(fecha, firstDayOfMonth, lastDayOfMonth);
					});
			} else {
				this.productosService
					.getPrimerDiaDisponible(firstDay, lastDay)
					.pipe(take(1))
					.subscribe((res) => {
						if (res) {
							this.calendarService.currentDate = moment(res, "DD/MM/YYYY");
							firstDayOfMonth = this.calendarService.currentDate
								.startOf("month")
								.format("YYYY/MM/DD");
							lastDayOfMonth = this.calendarService.currentDate
								.endOf("month")
								.format("YYYY/MM/DD");
							this.pintarCalendario(res, firstDayOfMonth, lastDayOfMonth);
						} else {
							this.pintarCalendario(fecha, firstDayOfMonth, lastDayOfMonth);
						}
					});
			}
		} else {
			this.pintarCalendario(fecha, firstDayOfMonth, lastDayOfMonth);
		}
	}

	pintarCalendario(fecha, firstDayOfMonth, lastDayOfMonth) {
    console.log({
      pintarCalendario: true,
      fecha,
      firstDayOfMonth,
      lastDayOfMonth,
    })
		return this.productosService
			.getPintarCalendario(firstDayOfMonth, lastDayOfMonth)
			.pipe(take(1))
			.subscribe((res) => {
        console.log({
          res,
        })
				const sesionesConMin = this.sesionService.minVentaControlCalendario(
					res,
					this.productosService.getEnlaceMenuId()
				);
				const sesionesConMinMax = this.sesionService.maxVentaControlCalendario(
					sesionesConMin,
					this.productosService.getEnlaceMenuId()
				);

				// FORMZAMOS A METER LAS SESIONES QUE SE ESTAN MODIFICANDO
				if (
					this.ventaService.modificandoReservaValue &&
					this.ventaService.modificarValue
				) {
					const posicionVenta =
						this.ventaService.compraValue.productos[0].posicionVenta;
					const fechas = [];
					const compraCarrito = this.ventaService.carritoValue.compras.find(
						(c) => c.posicionVenta === posicionVenta
					);
					if (compraCarrito.sesionesSeleccionadas) {
						compraCarrito.sesionesSeleccionadas.forEach((s) => {
							if (!fechas.find((f) => f.fecha === s.Fecha)) {
								// && s.Estado !== '1'
								const fecha = { fecha: s.Fecha, disponibilidad: "1" };
								fechas.push(fecha);
							}
						});
					}
					fechas.forEach((f) => {
						sesionesConMinMax.map((s) => {
							if (f.fecha === s.fecha) {
								s.disponibilidad = "1";
							}
							return s;
						});
					});
				}

				this.fechasDisponibilidad = sesionesConMinMax;
				this.buildCalendar();
				if (fecha) {
					const dia = this.dates.find(
						(date) => date.mDate.format("DD/MM/YYYY") === fecha
					);
					this.selectDate(dia);
				} else if (this.diasDisponibles.length) {
					this.selectDate(this.diasDisponibles[0]);
				}
				if (this.calendarService.filtrosValue) {
					this.filtrosEntradas();
				}
				this.productosService.setCargandoComponenteValue(false);
			});
	}

	buildCalendar() {
		this.calendarService.selectedMonth = this.monthToString(
			this.calendarService.currentDate.month()
		);
		this.calendarService.selectedYear = this.calendarService.currentDate
			.year()
			.toString();
		this.calendarService.meses$.pipe(take(1)).subscribe(() => {
			this.monthNames = moment
				.months()
				.map((month) => month[0].toUpperCase() + month.substr(1));
		});
		this.dates = this.fillDates(this.calendarService.currentDate);
		const copyDates = this.dates.slice(0);
		const weeks: CalendarDate[][] = [];
		while (copyDates.length > 0) {
			weeks.push(copyDates.splice(0, 7));
		}
		this.sesionService.setWeeks(weeks);
		this.sesionService.weeks
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((w) => (this.weeks = w));
		this.diasDisponibles = [];
		this.weeks.forEach((week) => {
			week.forEach((day) => {
				if (
					(day.enabled && day.selectable) ||
					(day.ultimas && day.selectable)
				) {
					this.diasDisponibles.push(day);
				}
			});
		});
		this.diasDisponibles.forEach((dia) => {
			if (dia.today) {
				this.diasDisponibles = [];
				this.diasDisponibles.push(dia);
			}
		});
	}

	fillDates(currentMoment: moment.Moment): CalendarDate[] {
		const firstOfMonth = moment(currentMoment).startOf("month").day();
		const firstDayOfGrid = moment(currentMoment)
			.startOf("month")
			.subtract(firstOfMonth - 1, "days");
		let start = firstDayOfGrid.date();
		start = start === 2 ? start - 7 : start;
		return _.range(start, start + 42).map((date: number): CalendarDate => {
			const d = moment(firstDayOfGrid).date(date);
			return {
				today: this.isToday(d),
				selected: this.isSelected(d),
				enabled: this.isEnabled(d),
				complete: this.isDisabled(d),
				ultimas: this.isUltEntradasDia(d),
				selectable: this.isSelectable(d),
				mDate: d,
			};
		});
	}

	isDisabled(date: moment.Moment) {
		if (!this.isSelectedMonth(date)) {
			return false;
		}
		const disponiblidad = this.fechasDisponibilidad.find(
			(disponibilidad) => date.format("DD/MM/YYYY") === disponibilidad.fecha
		);
		if (!disponiblidad) {
			return false;
		}
		if (parseInt(disponiblidad.disponibilidad) === Disponibilidad.AGOTADAS) {
			return true;
		} else {
			return false;
		}
	}

	isUltEntradasDia(date: moment.Moment) {
		if (!this.isSelectedMonth(date)) {
			return false;
		}
		const disponiblidad = this.fechasDisponibilidad.find(
			(disponibilidad) => date.format("DD/MM/YYYY") === disponibilidad.fecha
		);
		if (!disponiblidad) {
			return false;
		}
		if (parseInt(disponiblidad.disponibilidad) === Disponibilidad.ULTIMAS) {
			return true;
		} else {
			return false;
		}
	}

	isEnabled(date: moment.Moment) {
		if (!this.isSelectedMonth(date)) {
			return false;
		}

		const disponiblidad = this.fechasDisponibilidad.find(
			(disponibilidad) => date.format("DD/MM/YYYY") === disponibilidad.fecha
		);
		if (!disponiblidad) {
			return false;
		}
		if (parseInt(disponiblidad.disponibilidad) === Disponibilidad.BUENA) {
			return true;
		} else {
			return false;
		}
	}

	isSelectable(date) {
		return !(date.isBefore() && !this.isToday(date));
	}

	isToday(date: moment.Moment): boolean {
		return moment().isSame(moment(date), "day");
	}

	isSelected(date: moment.Moment): boolean {
		return date.isSame(this.ventaService.fechaSeleccionadaValue, "day");
	}

	isSelectedMonth(date: moment.Moment): boolean {
		return moment(date).isSame(this.calendarService.currentDate, "month");
	}

	monthToString(num: number) {
		return this.monthNames[num];
	}

	monthToNumber(name) {
		return moment().month(name).format("M");
	}

	dayClass(day) {
		if (!day.selectable) {
			return;
		}
		if (!day.enabled && !day.ultimas && !day.complete) {
			return;
		}
		let cssClass = "";
		if (day.today) {
			cssClass = "today ";
		}
		if (this.isSelected(day.mDate)) {
			return (cssClass += "selected");
		} else if (day.ultimas) {
			return (cssClass += "dayMonthUltPlazas");
		} else if (day.enabled) {
			return (cssClass += "dayMonthAvaliable");
		} else if (day.complete) {
			return (cssClass += "dayMonthAgotadas");
		} else {
			return cssClass;
		}
	}

	//SELECCIONA DIA AL GENERAR CALENDARIO
	selectDate(date): void {
		if (this.calendarService.mostrarFiltroDiasValue) {
			const fecha = this.ventaService.sesionesSeleccionadasValue.find(
				(sesion) => sesion.mostrar === true
			);
			let dia;
			if (fecha.sesion.Fecha) {
				this.dates = this.fillDates(this.calendarService.currentDate);
				dia = this.dates.find(
					(date) => date.mDate.format("DD/MM/YYYY") === fecha.sesion.Fecha
				);
			} else {
				dia = date;
			}
			this.selectDatePaso2VariosDias(dia);
		} else {
			if (this.calendarService.calendarioPaso2Value) {
				this.selectDatePaso2(date);
			} else {
				this.selectDatePaso1(date);
			}
		}
	}

	//SELECCIONA DIA HACIENDO CLICK
	selectDateClick(date): void {
		this.calendarModalService.setRecinto(undefined);
		if (this.ventaService.modificarValue) {
			if (
				this.ventaService.compraValue.productos &&
				this.ventaService.compraValue.productos[0] &&
				!this.ventaService.compraValue.productos[0].sesionesSeleccionadas &&
				this.ventaService.compraValue.productos[0].producto
					.ListTarifasfechasVigencia &&
				this.ventaService.compraValue.productos[0].producto
					.ListTarifasfechasVigencia[0] &&
				this.ventaService.compraValue.productos[0].producto
					.ListTarifasfechasVigencia[0].FechaInicioTarifa
			) {
				this.ventaService.compraValue.productos[0].producto.ListTarifasfechasVigencia[0].FechaInicioTarifa =
					date.mDate.format("DD/MM/YYYY");
				this.ventaService.compraValue.productos[0].producto.ListTarifasfechasVigencia[0].FechaFinTarifa =
					date.mDate.format("DD/MM/YYYY");
			}
		}

		if (this.calendarService.mostrarFiltroDiasValue) {
			this.selectDatePaso2VariosDias(date);
		} else {
			if (this.calendarService.calendarioPaso2Value) {
				this.selectDatePaso2(date);
			} else {
				this.selectDatePaso1(date);
			}
		}
	}

	selectDatePaso2(date): void {
		this.sesionService.setNoAforo(false);
		this.productosService.setCargandoComponente2Value(true);
		if (!date.selectable) {
			this.productosService.setCargandoComponente2Value(false);
			return;
		} else if (!date.enabled && !date.ultimas) {
			this.productosService.setCargandoComponente2Value(false);
			return;
		}
		this.ventaService.setFechaSeleccionadaValue(date.mDate);
		if (date.mDate.isBefore() && !date.today) {
			return;
		}
		if (date.ultimas || date.enabled) {
			this.calendarService.setDiaDisponible(true);
		} else {
			this.calendarService.setDiaDisponible(false);
		}
		this.ventaService.diaSeleccionado = true;

		//Yaribel 20230316  quitar butacas al cambiar de dia
		if (
			this.productosService.espectaculoValue &&
			this.productosService.mostrarProductosValue == true
		) {
			this.comprobarButacasSeleccionadas(date.mDate);
		}
		// QUITAR SESIONES AL CAMBIAR EL DIA
		if (
			this.ventaService.compraValue.productos &&
			this.ventaService.compraValue.productos[0].sesionesSeleccionadas
		) {
			if (this.primeraSeleccionDia > 0) {
				//this.ventaService.fechaSeleccionadaValue.format('DD/MM/YYYY') !== this.ventaService.compraValue.productos[0].sesionesSeleccionadas[0].Fecha
				if (!this.ventaService.modificandoReservaValue) {
					this.ventaService.compraValue.productos[0].sesionesSeleccionadas.forEach(
						(s) => {
							this.productosService.cancelarAforo(s).pipe(take(1)).subscribe();
						}
					);
				} else if (
					this.ventaService.modificandoReservaValue &&
					!this.comprobarSesionEstaEnReservaOriginal()
				) {
					this.sesionesCancelar.forEach((s) => {
						this.productosService.cancelarAforo(s).pipe(take(1)).subscribe();
					});
				} else {
					this.ventaService.compraValue.productos[0].sesionesSeleccionadas.forEach(
						(s) => {
							this.productosService.cancelarAforo(s).pipe(take(1)).subscribe();
						}
					);
				}
				this.ventaService.compraValue.productos.forEach((producto) => {
					this.ventaService.compraValue.quitarSesionAProducto(null, producto);
				});
			}
		}
		this.primeraSeleccionDia++;
		this.traerSesiones();
	}

	agregarProductoCarritoModificar(producto) {
		this.productos.push({
			posicionVenta: producto.posicionVenta,
			producto: producto.producto,
			datos: producto.producto.datos,
			sesionesSeleccionadas: producto.sesionesSeleccionadas,
			cantidad: producto.cantidad,
		});
	}

	comprobarSesionEstaEnReservaOriginal() {
		this.sesionesCancelar = [];
		let existeSesion = true;
		const posicionVenta =
			this.ventaService.compraValue.productos[0].posicionVenta;
		const reservaOriginal = JSON.parse(
			sessionStorage.getItem("reservaOriginal")
		);
		reservaOriginal.compras.forEach((producto) => {
			if (producto.posicionVenta === posicionVenta) {
				this.agregarProductoCarritoModificar(producto);
			}
		});
		this.ventaService.compraValue.productos.forEach((prodComprando) => {
			const prodCarrito = this.productos.find(
				(prod) => prod.producto.ProductoId === prodComprando.producto.ProductoId
			);
			if (prodCarrito) {
				if (
					prodCarrito.sesionesSeleccionadas &&
					prodComprando.sesionesSeleccionadas
				) {
					prodComprando.sesionesSeleccionadas.forEach((s) => {
						const sesion = prodCarrito.sesionesSeleccionadas.find(
							(se) => se.Fecha === s.Fecha && se.HoraInicio === s.HoraInicio
						);
						if (sesion) {
						} else {
							if (
								!this.sesionesCancelar.find(
									(ses) =>
										ses.IdRecinto === s.IdRecinto &&
										ses.HoraInicio === s.HoraInicio &&
										ses.Fecha === s.Fecha
								)
							) {
								this.sesionesCancelar.push(s);
								existeSesion = false;
							}
						}
					});
				}
			}
		});

		return existeSesion;
	}

	selectDatePaso2VariosDias(date): void {
		this.productosService.setCargandoComponente2Value(true);
		if (!date.selectable) {
			this.productosService.setCargandoComponente2Value(false);
			return;
		} else if (!date.enabled && !date.ultimas) {
			this.productosService.setCargandoComponente2Value(false);
			return;
		}
		this.ventaService.setFechaSeleccionadaValue(date.mDate);
		if (date.mDate.isBefore() && !date.today) {
			return;
		}
		if (date.ultimas || date.enabled) {
			this.calendarService.setDiaDisponible(true);
		} else {
			this.calendarService.setDiaDisponible(false);
		}
		this.ventaService.diaSeleccionado = true;
		/* this.ventaService.sesionesSeleccionadasValue.forEach(sesion => {
       if (sesion.mostrar === true) {
         sesion.fecha = date.mDate.format('DD/MM/YYYY');
       }
     });*/
		/*if (this.ventaService.compraValue.productos[0].sesionesSeleccionadas) {
      const sesion = this.ventaService.sesionesSeleccionadasValue.find(s => s.mostrar === true);
      if (this.ventaService.fechaSeleccionadaValue.format('DD/MM/YYYY') !== sesion.sesion.Fecha) {
        this.ventaService.compraValue.productos[0].sesionesSeleccionadas.forEach(s => {
          this.productosService.cancelarAforo(s).pipe(take(1)).subscribe();
          this.ventaService.compraValue.productos.forEach(producto => {
            this.ventaService.compraValue.quitarSesionAProducto(null, producto);
          });
        });
      }
    }*/
		this.traerSesiones();
	}

	traerSesiones() {
		const listadoProductos = [];
		if (this.calendarService.filtrosValue) {
			this.ventaService.compraValue.productos.forEach((producto) => {
				const prod =
					producto.producto.TipoProducto +
					"," +
					producto.producto.ProductoId +
					"," +
					producto.cantidad +
					",,,," +
					this.productosService.filtroTipoValue +
					"," +
					this.productosService.filtroGrupoValue +
					"," +
					this.productosService.filtroNivelValue;
				listadoProductos.push(prod);
			});
		} else {
			this.ventaService.compraValue.productos.forEach((producto) => {
				const prod =
					producto.producto.TipoProducto +
					"," +
					producto.producto.ProductoId +
					"," +
					producto.cantidad;
				listadoProductos.push(prod);
			});
		}
		const fechas = [];
		if (
			this.ventaService.modificarValue &&
			this.calendarService.tieneCalendarioPorRecinto
		) {
			if (this.ventaService.compraValue.productos[0].sesionesSeleccionadas) {
				this.ventaService.compraValue.productos[0].sesionesSeleccionadas.forEach(
					(s) => {
						if (!fechas.find((fecha) => fecha === s.Fecha)) {
							fechas.push(s.Fecha);
						}
					}
				);
			}
		}
		if (fechas.length > 1) {
			const peticionesTraerSesiones = fechas.map((fecha) =>
				this.productosService.traerSesiones(
					listadoProductos,
					moment(fecha, "DD/MM/YYYY")
				)
			);
			forkJoin(peticionesTraerSesiones).subscribe((sesiones) => {
				const sesionesFlat = sesiones.reduce((acc, val) => acc.concat(val), []);
				const sesionesMerge = [];
				this.ventaService.compraValue.productos[0].sesionesSeleccionadas.forEach(
					(s) => {
						sesionesMerge.push(
							sesionesFlat.filter(
								(ses) => s.Fecha === ses.Fecha && s.IdRecinto === ses.IdRecinto
							)
						);
					}
				);
				const sesionesFlatMerge = sesionesMerge.reduce(
					(acc, val) => acc.concat(val),
					[]
				);
				const sesionesConMin = this.sesionService.minVentaControl(
					sesionesFlatMerge,
					this.productosService.getEnlaceMenuId()
				);
				const sesionesConMinMax = this.sesionService.maxVentaControl(
					sesionesConMin,
					this.productosService.getEnlaceMenuId()
				);
				this.sesionService.setSesiones(sesionesConMinMax);
				this.calendarService.setSesionesCargadas(true);
				this.productosService.setCargandoComponente2Value(false);
			});
		} else {
			this.productosService
				.traerSesiones(listadoProductos)
				.pipe(take(1))
				.subscribe((sesiones) => {
					const sesionesConMin = this.sesionService.minVentaControl(
						sesiones,
						this.productosService.getEnlaceMenuId()
					);
					const sesionesConMinMax = this.sesionService.maxVentaControl(
						sesionesConMin,
						this.productosService.getEnlaceMenuId()
					);
					this.sesionService.setSesiones(sesionesConMinMax);
					this.calendarService.setSesionesCargadas(true);
					this.productosService.setCargandoComponente2Value(false);
				});
		}
	}

	selectDatePaso1(date): void {
		if (!date.selectable) {
			//this.ventaService.setFechaSeleccionadaSinSessionValue(date.mDate);

			return;
		} else if (!date.enabled && !date.ultimas) {
			return;
		}
		if (
			this.productosService.espectaculoValue &&
			this.productosService.mostrarProductosValue == true
		) {
			this.ventaService.setFechaSeleccionadaValue(date.mDate);
			if (date.mDate.isBefore() && !date.today) {
				return;
			}
			if (date.ultimas || date.enabled) {
				this.calendarService.setDiaDisponible(true);
			} else {
				this.calendarService.setDiaDisponible(false);
			}
			this.ventaService.diaSeleccionado = true;
			//Yaribel 20230316  quitar butacas al cambiar de dia
			if (
				this.productosService.espectaculoValue &&
				this.productosService.mostrarProductosValue == true
			) {
				this.comprobarButacasSeleccionadas(date.mDate);
			}
			this.productosService.getHours(date.mDate.format("YYYY/MM/DD"));
			// this.ventaService.setFechaSeleccionadaValue(date.mDate);
			// if (date.mDate.isBefore() && !date.today) {
			//   return;
			// }
			// if (date.ultimas || date.enabled) {
			//   this.calendarService.setDiaDisponible(true);
			// } else {
			//   this.calendarService.setDiaDisponible(false);
			// }
			// this.ventaService.diaSeleccionado = true;
		} else {
			const productosBorrar = [];
			this.productosService
				.getProductosDia(date.mDate.format("YYYY/MM/DD"))
				.pipe(take(1))
				.subscribe((producto) => {
					this.productosDisponibles = [];
					this.productosService.setProductosValue(
						producto.DictTarifasEntradas[
							this.productosService.getGrupoInternet().gi
						].DictTarifasProductos,
						producto.DictTarifasEntradas[
							this.productosService.getGrupoInternet().gi
						].EnlaceMenuId,
						this.productosService.getGrupoInternet().gi,
						producto.DictTarifasEntradas[
							this.productosService.getGrupoInternet().gi
						].TipoReservaId,
						producto.DictTarifasEntradas[
							this.productosService.getGrupoInternet().gi
						].NombreGrupo
					);
					if (this.calendarService.filtrosValue) {
						this.filtrosEntradas();
					}
					this.productosService.productosList =
						this.productosService.productos$.pipe(
							map((productos) => Object.values(productos))
						);
					const productos: any = Object.values(producto.DictTarifasEntradas);
					Object.values(productos[0].DictTarifasProductos).forEach((p: any) => {
						this.productosDisponibles.push(p.ProductoId);
					});
					const newProductosDisponibles = [];
					Object.keys(this.productosService.productosValue).forEach(
						(product) => {
							if (
								this.productosDisponibles.includes(
									this.productosService.productosValue[product].ProductoId
								)
							) {
								newProductosDisponibles.push(
									this.productosService.productosValue[product]
								);
							}
						}
					);
					if (this.ventaService.compraValue.productos.length !== 0) {
						this.ventaService.compraValue.productos.forEach((producto) => {
							if (
								newProductosDisponibles.every(
									(p) =>
										p.ProductoId !== producto.producto.ProductoId ||
										p.NombreProducto !== producto.producto.NombreProducto
								)
							) {
								productosBorrar.push(producto);
							}
						});
						productosBorrar.forEach((producto) => {
							this.ventaService.removeProductoModal(producto.producto);
						});
					}
					this.ventaService.actualizarProductosEnLaCesta(
						Object.values(this.productosService.productosValue)
					); // Yaribel 20220616 actualizamos la tarifa de los productos
					this.ventaService.setFechaSeleccionadaValue(date.mDate);
					if (date.mDate.isBefore() && !date.today) {
						return;
					}
					if (date.ultimas || date.enabled) {
						this.calendarService.setDiaDisponible(true);
					} else {
						this.calendarService.setDiaDisponible(false);
					}
					this.ventaService.diaSeleccionado = true;
				});
		}
	}

	filtrosEntradas() {
		//Controla que se oculten los productos si no tiene filtros  seleccionados y  this.calendarService.filtrosValue = true

		if (
			!this.productosService.filtroGrupoValue &&
			!this.productosService.filtroTipoValue &&
			!this.productosService.filtroNivelValue &&
			!this.productosService.filtroIdiomaVisitaValue
		) {
			this.productosService.setMostrarProductosValue(false);
		} else {
			this.productosService.setCargandoComponente2Value(true);
			this.productosService.setMostrarProductosValue(true);
			this.productosService.setCargandoComponente2Value(false);
		}
	}
	getCalendarService() {
		return this.calendarService;
	}

	selectedMonth() {
		return this.monthToString(this.calendarService.currentDate.month());
	}
	selectedYear() {
		return this.calendarService.currentDate.year().toString();
	}

	//Comprueba si las butacas en el carrito coinciden con el dia seleccionado
	comprobarButacasSeleccionadas(date) {
		let existe = false;
		let productos;
		if (this.ventaService.modificarValue) {
			productos = this.ventaService.compraValue.productos.filter(
				(producto) =>
					producto.producto.butacas && producto.producto.butacas.length > 0
			);
			productos.forEach((producto) => {
				if (
					producto.producto.butacas.find(
						(b) => b.FechaSesion === date.format("YYYY/MM/DD")
					)
				) {
					existe = true;
				}
			});
		}

		if (productos && !existe) {
			this.ventaService.setCompra(new Compra());
			//borrar el carrito
		}
	}
}

import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, Inject, Input, LOCALE_ID, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil, take, map } from 'rxjs/operators';
import { ProductosService } from 'src/app/business/productos.service';
import { SesionService, Disponibilidad } from 'src/app/core/services/sesion.service';
import { VentaService } from 'src/app/core/services/venta.service';
import { TranslateService } from 'src/app/shared/translate.service';
import { CalendarService } from '../../date-selector/calendar/calendar.service';
import { FiltrosComponent } from '../../date-selector/filtros/filtros.component';
import * as _ from 'lodash';
import { ModalService } from 'src/app/shared/modal.service';
import { CalendarModalService } from './calendar-modal.service';

interface CalendarDate {
  mDate: moment.Moment;
  selected?: boolean;
  today?: boolean;
  enabled?: boolean;
  complete?: boolean;
  ultimas?: boolean;
  selectable?: boolean;
}

@Component({
  selector: 'app-calendar-modal',
  templateUrl: './calendar-modal.component.html',
  styleUrls: ['./calendar-modal.component.scss']
})
export class CalendarModalComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  @Input() id: string;
  private element: any;
  moment = moment();
  monthsFirstColumn = moment.months().splice(0, 6);
  monthsSecondColumn = moment.months().splice(6, 11);
  dayNames;
  monthNames;
  weeks;
  sortedDates: CalendarDate[] = [];
  productos$;
  sesiones;
  fechasDisponibilidad;
  productosDisponibles;
  loading;
  sinProducto;
  confirmado;
  enlaceMenu;
  grupoInternet;
  tipoReserva;
  oldFecha;
  dates;
  diasDisponibles;
  fechaSesionModificar;
  productos = [];
  sesionesCancelar = [];
  recinto;
  recintoId;

  @Input() selectedDates: CalendarDate[] = [];
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onSelectDate = new EventEmitter<CalendarDate>();
  giID: any;
  @ViewChild(FiltrosComponent, { read: FiltrosComponent }) childFiltros: FiltrosComponent;

  constructor(
    private modalService: ModalService,
    @Inject(LOCALE_ID) protected localeId,
    private el: ElementRef,
    private ventaService: VentaService,
    private cd: ChangeDetectorRef,
    private calendarService: CalendarService,
    private productosService: ProductosService,
    private sesionService: SesionService,
    private translateService: TranslateService,
    private calendarModalService: CalendarModalService
  ) {
    this.moment.locale(this.localeId);
    this.dayNames = moment.weekdays(true);
    this.monthNames = moment.months().map(month => month[0].toUpperCase() + month.substr(1));
    this.element = el.nativeElement;
  }

  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    const modal = this;
    if (!this.id) {
      return;
    }
    document.body.appendChild(this.element);
    // tslint:disable-next-line:only-arrow-functions
    this.element.addEventListener('click', function (e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });
    this.modalService.add(this);
    //this.productosService.cargandoComponente$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(carga => this.loading = carga);
    this.giID = this.productosService.getGrupoInternet().gi;
    //let fecha;
    /*if (this.ventaService.modificarValue) {
      if (this.ventaService.compraValue.productos[0].sesionesSeleccionadas[0].Fecha) {
        fecha = this.ventaService.compraValue.productos[0].sesionesSeleccionadas[0].Fecha;
      }
    }*/
    this.calendarModalService.recinto$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((recinto:any) => {
      if (recinto) {
        this.recinto = recinto.NombreRecinto;
        this.recintoId = recinto.IdRecinto;
        this.generateCalendar(0);
      }
    });

  }

  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');
  }

  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedDates &&
      changes.selectedDates.currentValue &&
      changes.selectedDates.currentValue.length > 1) {
      this.sortedDates = _.sortBy(changes.selectedDates.currentValue, (m: CalendarDate) => m.mDate.valueOf());
      this.generateCalendar(1);
    }
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  prevMonth(): void {
    if (moment(this.calendarModalService.currentDateModal).subtract(1, 'months').isBefore(moment(), 'month')) {
      return;
    }
    this.calendarModalService.currentDateModal = moment(this.calendarModalService.currentDateModal).subtract(1, 'months');
    this.calendarModalService.selectedMonthModal = this.monthToString(this.calendarModalService.currentDateModal.month());
    this.calendarModalService.selectedYearModal = (this.calendarModalService.currentDateModal.year()).toString();

    if (this.monthToNumber(this.calendarModalService.selectedMonthModal) === '12') {
      this.calendarModalService.yearModal--;
    }
    this.generateCalendar(1);
  }

  nextMonth(): void {
    if ((this.calendarModalService.todayModal.month() + 4 > this.calendarModalService.currentDateModal.month())) {
      this.calendarModalService.currentDateModal = moment(this.calendarModalService.currentDateModal).add(1, 'months');
      this.calendarModalService.selectedMonthModal = this.monthToString(this.calendarModalService.currentDateModal.month());

      this.calendarModalService.selectedYearModal = (this.calendarModalService.currentDateModal.year()).toString();
      if (this.monthToNumber(this.calendarModalService.selectedMonthModal) === '1') {
        this.calendarModalService.yearModal++;
      }
      this.generateCalendar(1);
    }
  }

  generateCalendar(inicio,fecha?) {

    let firstDayOfMonth = this.calendarModalService.currentDateModal.startOf('month').format('YYYY/MM/DD');
    let lastDayOfMonth = this.calendarModalService.currentDateModal.endOf('month').format('YYYY/MM/DD');
    if (fecha) {
      this.calendarModalService.currentDateModal = moment(fecha, 'DD/MM/YYYY');
      firstDayOfMonth = this.calendarModalService.currentDateModal.startOf('month').format('YYYY/MM/DD');
      lastDayOfMonth = this.calendarModalService.currentDateModal.endOf('month').format('YYYY/MM/DD');
      this.pintarCalendario(fecha,firstDayOfMonth,lastDayOfMonth);
    } else if (inicio == 0){
      const firstDay = moment(moment(), 'DD/MM/YYYY').format('YYYY/MM/DD');
      let tiempo;
      switch ((this.productosService.getEnlaceMenuId().chTiempoVentaMaxTipo || '').toLowerCase()) {
        case 'meses':
          tiempo = 'month'; 
          break;
        case 'dias':
          tiempo = 'days';
          break;
        default:
          tiempo = 'month';
          break;
      }
      const lastDay = moment(moment(), 'DD/MM/YYYY').add(this.productosService.getEnlaceMenuId().iTiempoVentaMax || 12, tiempo).format('YYYY/MM/DD');
      this.productosService.getPrimerDiaDisponible(firstDay, lastDay).pipe(take(1)).subscribe(res => {
        if(res){
          this.calendarService.currentDate = moment(res, 'DD/MM/YYYY');
          firstDayOfMonth = this.calendarService.currentDate.startOf('month').format('YYYY/MM/DD');
          lastDayOfMonth = this.calendarService.currentDate.endOf('month').format('YYYY/MM/DD');
          this.pintarCalendario(res,firstDayOfMonth,lastDayOfMonth);
        } else{
          this.pintarCalendario(fecha,firstDayOfMonth,lastDayOfMonth);
        }
      });
    } else{
      this.pintarCalendario(fecha,firstDayOfMonth,lastDayOfMonth);
    }
    //this.productosService.setCargandoComponenteValue(true);



  }

  pintarCalendario(fecha: string, firstDayOfMonth: string, lastDayOfMonth: string) {
    // Llama al servicio para obtener las sesiones entre las fechas de inicio y fin del mes
    this.productosService.getPintarCalendario(firstDayOfMonth, lastDayOfMonth).pipe(take(1)).subscribe(res => {
      
      // Filtra las sesiones mínimas usando un ID específico del menú
      const sesionesConMin = this.sesionService.minVentaControlCalendario(res, this.productosService.getEnlaceMenuId());
      console.log(sesionesConMin);
  
      // Calcula las sesiones con valores máximos para disponibilidad
      const sesionesConMinMax = this.sesionService.maxVentaControlCalendario(sesionesConMin, this.productosService.getEnlaceMenuId());
  
      // Condicional para añadir sesiones que están siendo modificadas en una reserva
      if (this.ventaService.modificandoReservaValue && this.ventaService.modificarValue) {
        const posicionVenta = this.ventaService.compraValue.productos[0].posicionVenta;
        const fechas: { fecha: string, disponibilidad: string }[] = [];
  
        // Busca las sesiones seleccionadas en el carrito que coinciden con la posición de venta actual
        const compraCarrito = this.ventaService.carritoValue.compras.find(c => c.posicionVenta === posicionVenta);
        
        if (compraCarrito?.sesionesSeleccionadas) {
          compraCarrito.sesionesSeleccionadas.forEach(s => {
            if (!fechas.find(f => f.fecha === s.Fecha)) { // Verifica que no se dupliquen fechas
              const fecha = { fecha: s.Fecha, disponibilidad: '1' }; // Marcamos la disponibilidad como '1' (disponible)
              fechas.push(fecha);
            }
          });
        }
  
        // Actualiza la disponibilidad en las fechas seleccionadas dentro de sesionesConMinMax
        fechas.forEach(f => {
          sesionesConMinMax.map(s => {
            if (f.fecha === s.fecha) {
              s.disponibilidad = '1'; // Forzamos la disponibilidad en '1' si coincide la fecha
            }
            return s;
          });
        });
      }
  
      // Asigna las fechas de disponibilidad a la propiedad fechasDisponibilidad
      this.fechasDisponibilidad = sesionesConMinMax;
  
      // Construye el calendario basado en las fechas
      this.buildCalendar();
  
      let dia;
      if (fecha) {
        // Si se proporciona una fecha, intenta seleccionar el día correspondiente en el calendario
        dia = this.dates.find(date => date.mDate.format('DD/MM/YYYY') === fecha);
        this.selectDate(dia);
      } else {
        // Si no hay fecha proporcionada, selecciona la fecha predeterminada o seleccionada
        fecha = this.ventaService.fechaSeleccionadaValue.format('DD/MM/YYYY');
        dia = this.dates.find(date => date.mDate.format('DD/MM/YYYY') === fecha);
        this.selectDate(dia);
      }
      
      // Opción para ocultar un indicador de carga al finalizar la ejecución (comentada en el código original)
      // this.productosService.setCargandoComponenteValue(false);
    });
  }
  

  buildCalendar() {
    this.calendarModalService.selectedMonthModal = this.monthToString(this.calendarModalService.currentDateModal.month());
    this.calendarModalService.selectedYearModal = (this.calendarModalService.currentDateModal.year()).toString();

    this.calendarModalService.meses$
      .pipe(take(1)).subscribe(() => {
        this.monthNames = moment.months().map(month => month[0].toUpperCase() + month.substr(1));
      });
    this.dates = this.fillDates(this.calendarModalService.currentDateModal);
    const copyDates = this.dates.slice(0);
    const weeks: CalendarDate[][] = [];
    while (copyDates.length > 0) {
      weeks.push(copyDates.splice(0, 7));
    }
    this.calendarModalService.setWeeksModal(weeks);
    this.calendarModalService.weeksModal$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(w => this.weeks = w);
    this.diasDisponibles = [];
    this.weeks.forEach(week => {
      week.forEach(day => {
        if (day.enabled && day.selectable || day.ultimas && day.selectable) {
          this.diasDisponibles.push(day);
        }
      });
    });
    this.diasDisponibles.forEach(dia => {
      if (dia.today) {
        this.diasDisponibles = [];
        this.diasDisponibles.push(dia);
      }
    });
  }

  fillDates(currentMoment: moment.Moment): CalendarDate[] {
    const firstOfMonth = moment(currentMoment).startOf('month').day();
    const firstDayOfGrid = moment(currentMoment).startOf('month').subtract(firstOfMonth - 1, 'days');
    let start = firstDayOfGrid.date();
    start = start === 2 ? start - 7 : start;
    return _.range(start, start + 42)
      .map((date: number): CalendarDate => {
        const d = moment(firstDayOfGrid).date(date);
        return {
          today: this.isToday(d),
          selected: this.isSelected(d),
          enabled: this.isEnabled(d),
          complete: this.isDisabled(d),
          ultimas: this.isUltEntradasDia(d),
          selectable: this.isSelectable(d),
          mDate: d,
        };
      });
  }

  isDisabled(date: moment.Moment) {
    if (!this.isSelectedMonth(date)) {
      return false;
    }
    const disponiblidad = this.fechasDisponibilidad.find(disponibilidad => date.format('DD/MM/YYYY') === disponibilidad.fecha);
    if (!disponiblidad) {
      return false;
    }
    if (parseInt(disponiblidad.disponibilidad) === Disponibilidad.AGOTADAS) {
      return true;
    } else {
      return false;
    }
  }

  isUltEntradasDia(date: moment.Moment) {
    if (!this.isSelectedMonth(date)) {
      return false;
    }
    const disponiblidad = this.fechasDisponibilidad.find(disponibilidad => date.format('DD/MM/YYYY') === disponibilidad.fecha);
    if (!disponiblidad) {
      return false;
    }
    if (parseInt(disponiblidad.disponibilidad) === Disponibilidad.ULTIMAS) {
      return true;
    } else {
      return false;
    }
  }

  isEnabled(date: moment.Moment) {
    if (!this.isSelectedMonth(date)) {
      return false;
    }

    const disponiblidad = this.fechasDisponibilidad.find(disponibilidad => date.format('DD/MM/YYYY') === disponibilidad.fecha);
    if (!disponiblidad) {
      return false;
    }
    if (parseInt(disponiblidad.disponibilidad) === Disponibilidad.BUENA) {
      return true;
    } else {
      return false;
    }
  }

  isSelectable(date) {
    return !(date.isBefore() && !this.isToday(date));
  }

  isToday(date: moment.Moment): boolean {
    return moment().isSame(moment(date), 'day');
  }

  isSelected(date: moment.Moment): boolean {
    return date.isSame(this.calendarModalService.fechaSeleccionadaModalValue, 'day');
  }

  isSelectedMonth(date: moment.Moment): boolean {
    return moment(date).isSame(this.calendarModalService.currentDateModal, 'month');
  }

  monthToString(num: number) {
    return this.monthNames[num];
  }

  monthToNumber(name) {
    return moment().month(name).format('M');
  }

  dayClass(day) {
    if (!day.selectable) {
      return;
    }
    if (!day.enabled && !day.ultimas && !day.complete) {
      return;
    }
    let cssClass = '';
    if (day.today) {
      cssClass = 'today ';
    }
    if (this.isSelected(day.mDate)) {
      return cssClass += 'selected';
    } else if (day.ultimas) {
      return cssClass += 'dayMonthUltPlazas';
    } else if (day.enabled) {
      return cssClass += 'dayMonthAvaliable';
    } else if (day.complete) {
      return cssClass += 'dayMonthAgotadas';
    } else {
      return cssClass;
    }
  }

  selectDate(date): void {
    //this.sesionService.setNoAforo(false);

    if (!date.selectable) {
      //this.productosService.setCargandoComponente2Value(false);
      return;
    } else if (!date.enabled && !date.ultimas) {
      //this.productosService.setCargandoComponente2Value(false);
      return;
    }
    this.calendarModalService.setFechaSeleccionadaModalValue(date.mDate);
    if (date.mDate.isBefore() && !date.today) {
      return;
    }
    if (date.ultimas || date.enabled) {
      this.calendarModalService.setDiaDisponible(true);
    } else {
      this.calendarModalService.setDiaDisponible(false);
    }
    this.calendarModalService.diaSeleccionado = true;
    // QUITAR SESIONES AL CAMBIAR EL DIA
    /*if (this.ventaService.compraValue.productos[0].sesionesSeleccionadas) {
      if (this.ventaService.fechaSeleccionadaValue.format('DD/MM/YYYY') !== this.ventaService.compraValue.productos[0].sesionesSeleccionadas[0].Fecha) {
        if (!this.ventaService.modificandoReservaValue) {
          this.ventaService.compraValue.productos[0].sesionesSeleccionadas.forEach(s => {
            this.productosService.cancelarAforo(s).pipe(take(1)).subscribe();
          });
        } else if (this.ventaService.modificandoReservaValue && !this.comprobarSesionEstaEnReservaOriginal()) {
          this.sesionesCancelar.forEach(s => {
            this.productosService.cancelarAforo(s).pipe(take(1)).subscribe();
          });
        } else {
          this.ventaService.compraValue.productos[0].sesionesSeleccionadas.forEach(s => {
            this.productosService.cancelarAforo(s).pipe(take(1)).subscribe();
          });
        }
        this.ventaService.compraValue.productos.forEach(producto => {
          this.ventaService.compraValue.quitarSesionAProducto(null, producto);
        });
      }
    }*/
    if (this.ventaService.fechaSeleccionadaValue.format('YYYY/MM/DD') !== date.mDate.format('YYYY/MM/DD')) {
      this.productosService.setCargandoComponente2Value(true);
      this.traerSesiones();
      this.close();
    }
  }

  agregarProductoCarritoModificar(producto) {
    this.productos.push(
      {
        posicionVenta: producto.posicionVenta,
        producto: producto.producto,
        datos: producto.producto.datos,
        sesionesSeleccionadas: producto.sesionesSeleccionadas,
        cantidad: producto.cantidad
      });
  }

  comprobarSesionEstaEnReservaOriginal() {
    this.sesionesCancelar = [];
    let existeSesion = true;
    const posicionVenta = this.ventaService.compraValue.productos[0].posicionVenta;
    const reservaOriginal = JSON.parse(sessionStorage.getItem('reservaOriginal'));
    reservaOriginal.compras.forEach(producto => {
      if (producto.posicionVenta === posicionVenta) {
        this.agregarProductoCarritoModificar(producto);
      }
    });
    this.ventaService.compraValue.productos.forEach(prodComprando => {
      const prodCarrito = this.productos.find(prod => prod.producto.ProductoId === prodComprando.producto.ProductoId);
      if (prodCarrito) {
        if (prodCarrito.sesionesSeleccionadas && prodComprando.sesionesSeleccionadas) {
          prodComprando.sesionesSeleccionadas.forEach(s => {
            const sesion = prodCarrito.sesionesSeleccionadas.find(se => se.Fecha === s.Fecha && se.HoraInicio === s.HoraInicio);
            if (sesion) {
            } else {
              if (!this.sesionesCancelar.find(ses => ses.IdRecinto === s.IdRecinto &&
                ses.HoraInicio === s.HoraInicio && ses.Fecha === s.Fecha)) {
                this.sesionesCancelar.push(s);
                existeSesion = false;
              }
            }
          });
        }
      }
    });

    return existeSesion;
  }

  traerSesiones() {
    const listadoProductos = [];
    if (this.calendarService.filtrosValue) {
      this.ventaService.compraValue.productos.forEach(producto => {
        const prod = producto.producto.TipoProducto + ',' + producto.producto.ProductoId + ',' + producto.cantidad + ',,,,' +
          this.productosService.filtroTipoValue + ',' + this.productosService.filtroGrupoValue + ',' + this.productosService.filtroNivelValue;
        listadoProductos.push(prod);
      });
    } else {
      this.ventaService.compraValue.productos.forEach(producto => {
        const prod = producto.producto.TipoProducto + ',' + producto.producto.ProductoId + ',' + producto.cantidad;
        listadoProductos.push(prod);
      });
    }
    this.productosService.traerSesiones(listadoProductos, this.calendarModalService.fechaSeleccionadaModalValue).pipe(take(1)).subscribe(sesiones => {
      const sesionesConMin = this.sesionService.minVentaControl(sesiones, this.productosService.getEnlaceMenuId());
      const sesionesConMinMax = this.sesionService.maxVentaControl(sesionesConMin, this.productosService.getEnlaceMenuId());
      const sesionesRecintoSeleccionado = sesionesConMinMax.filter(sesion => sesion.IdRecinto === this.calendarModalService.recintoValue.IdRecinto);
      const sesionesGuardadasSinRecintoSelec = this.sesionService.sesiones.filter((sesion: any) => sesion.IdRecinto !== this.calendarModalService.recintoValue.IdRecinto);
      const sesionesNuevas = sesionesGuardadasSinRecintoSelec.concat(sesionesRecintoSeleccionado);
      this.sesionService.setSesiones(sesionesNuevas);
      this.calendarService.setSesionesCargadas(true);
      this.productosService.setCargandoComponente2Value(false);
    });
  }
  selectMothModal()
  {
    return this.calendarModalService.selectedMonthModal;
  }

  selectYearModal(){
    return this.calendarModalService.currentDateModal.year();
    //return this.calendarModalService.yearModal;
  }
}

import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { map, take } from "rxjs/operators";
import { AppConfiguration } from "src/app/app.configuration";
import { ConfigurationService } from "src/app/core/config/configuration.service";
import { InitService } from "src/app/core/init/init.service";
import { ProductosService } from "../../productos.service";
import { CalendarService } from "../../ticket-sales/pages/date-selector/calendar/calendar.service";

@Component({
  selector: "app-visitas",
  templateUrl: "./visitas.component.html",
  styleUrls: ["./visitas.component.scss"],
})
export class VisitasComponent implements OnInit {
  productos;
  todosProductos;
  totalProductos;
  backupProds;

  productosSub: Subscription;
  todosProductosSub: Subscription;
  backupProdsSub: Subscription;
  filtrarSub: Subscription;
  proyecto = this.config.getProyecto(); //Yaribel 20210713 añadimos proyecto para obtener los assets genericamente
  espectaculosConfig;

  constructor(
    private productosService: ProductosService,
    private initService: InitService,
    private config: AppConfiguration,
    private configurationService: ConfigurationService,
    private router: Router,
    private calendarService: CalendarService
  ) {}

  ngOnInit(): void {
    this.productosSub = this.productosService.productosCat$.subscribe(
      (productos) => {
        this.productos = this.productosService.productosCatValue;
      }
    );
    this.backupProdsSub = this.productosService.backupProds$.subscribe(
      (productos) => {
        this.backupProds = this.productosService.backupProdsValue;
      }
    );
    this.todosProductosSub = this.productosService.todosProductosCat$.subscribe(
      (productos) => {
        this.todosProductos = this.productosService.todosProductosCatValue;
      }
    );
    this.espectaculosConfig = JSON.parse(
      this.configurationService.configurationValue.datosProyecto
        .CaracteristicasAdicionales
    ).CaracteristicasAdicionales.Espectaculos;
    this.filtrarSub = this.productosService.filtroProductos$.subscribe(
      (filtro) => {
        if (filtro && filtro.length > 0) {
          this.productos = this.backupProds;
          this.productos = this.productos.filter((p) =>
            p.NombreGrupo.toLowerCase().includes(filtro)
          );
        } else {
          this.productos = this.backupProds;
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.productosSub) this.productosSub.unsubscribe();
    if (this.todosProductosSub) this.todosProductosSub.unsubscribe();
    if (this.backupProdsSub) this.backupProdsSub.unsubscribe();
    if (this.filtrarSub) this.filtrarSub.unsubscribe();
  }

  checkVisible(id) {
    let visible = this.productosService.enlacesMenuValue.find(
      (e) => e.pkid === id
    ).iVisible;
    return visible;
  }

  irProducto(producto) {
    this.productosService.setCargandoValue(true);
    const enlaceMenu = this.productosService.enlacesMenuValue.find(
      (e) => e.pkid === producto.EnlaceMenuId
    );
    const calendario = JSON.parse(enlaceMenu.chCalendario);
    this.initService.setChCalendarioValue(calendario);

    if (!this.productosService.datosInitValue) {
      this.productosService.setDatosInitValue({
        empresa: this.config.getProyecto(),
        m: enlaceMenu.chNombre,
        gi: producto.GrupoInternetId,
      });
    }

    const datosUrl = JSON.parse(this.productosService.datosInitValue);
    const enlaceMenuNombre = this.productosService.getEnlaceMenuNombre(
      enlaceMenu.pkid
    );
    this.productosService.setDatosInitValue({
      empresa: datosUrl.empresa,
      m: enlaceMenuNombre,
      gi: producto.GrupoInternetId,
      userId: JSON.parse(this.productosService.datosInitValue).userId,
      lg: datosUrl.lg,
      u: JSON.parse(this.productosService.datosInitValue).u,
      p: JSON.parse(this.productosService.datosInitValue).p,
    });
    this.calendarService.setFiltros(false);
    sessionStorage.setItem(
      "token",
      this.initService.funcionEncript(this.productosService.datosInitValue)
    );
    this.productosService
      .getProductos()
      .pipe(
        map((res) => {
          if (res && res.DictTarifasEntradas) {
            this.productosService.setInfoGrupoValue(
              res.DictTarifasEntradas[
                this.productosService.getGrupoInternet().gi
              ]
            );

            this.productosService.setnombreGrupoValue(
              res.DictTarifasEntradas[
                this.productosService.getGrupoInternet().gi
              ].NombreGrupo
            );
            
            this.productosService.setProductosValue(
              res.DictTarifasEntradas[
                this.productosService.getGrupoInternet().gi
              ].DictTarifasProductos,

              res.DictTarifasEntradas[
                this.productosService.getGrupoInternet().gi
              ].EnlaceMenuId,

              this.productosService.getGrupoInternet().gi,

              res.DictTarifasEntradas[
                this.productosService.getGrupoInternet().gi
              ].TipoReservaId,

              res.DictTarifasEntradas[
                this.productosService.getGrupoInternet().gi
              ].NombreGrupo
            );

            
          }
        })
      )
      .pipe(take(1))
      .subscribe(() => {
        this.productosService.setCargandoValue(false);
        this.router.navigate(["/tienda/producto/1"]);
      });
  }

  onImgError(event) {
    event.target.src =
      "assets/img/" + this.proyecto + "/catalog/default_catalog.png";
    //Do other stuff with the event.target
  }
}
